import userService from '@/app/login/login.service.js';
import InfoPopup from '@/app/shared/infopopup/InfoPopup.vue';
import { showToast, toast } from '@/app/shared/toast/toast.controller';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
export default {
    name: 'resetPassword',
        components: {
            ToastComponent,
            InfoPopup
        },
        setup() {
            return {
                toastState: toast
            }
        }, 
    data() {
        return {
            UserList: [],
            ResetPasswordModel: {},
            emailId: "",
            errors: {
                emailId: "",
                newPassword: "",
                confirmPassword:" "
            },
            popUpModuleStyles:{},
            showforgotPassword: true,
            validateEmail: false,
            validatePassword: false,
            showPassworkLink:false
        };
    },
    created(){
        const url = window.location.href;
        if (url.includes("forgotPassword")) {
            this.showforgotPassword = true;
        }
        if (url.includes("changePassword")) {
            this.showforgotPassword = false;
            this.ResetPasswordModel.payload = this.$route.params.token;
        }
    },
    methods: {
        validatingEmail(emailId) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.com$/;
            if (!regex.test(emailId)) {
                this.errors.emailId = "Please enter valid Email ID";
                event.preventDefault();
            } else {
                this.emailId=this.emailId.toLowerCase();
                this.errors.emailId = "";
                this.validateEmail = true;
            }
            event.preventDefault();
            return this.validateEmail
        },

        validatingPasswords() {
            /* const regexp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/; */
            const regexp = /(?=.[a-z])(?=.{5,})/;

            if (!regexp.test(this.ResetPasswordModel.newPassword)) {
                this.errors.newPassword = "Password length should be atleast 6 characters";
                event.preventDefault();
            } else {
                this.validatePassword = true;
                this.errors.newPassword = "";
            }
            event.preventDefault();
            return this.validatePassword
        },

        clearEmail() {
            this.errors.emailId = " ";
            this.ResetPasswordModel.emailId = '';
        },

        clearPassword(fieldName) {
            if(fieldName=='newPassword')
                {
                    this.errors.newPassword = "";
                    this.ResetPasswordModel.newPassword = "";
                }
                else {
                    this.errors.confirmPassword = "";
                    this.ResetPasswordModel.confirmPassword = "";
                }
        },
        signIn() {
            this.$router.push('/');
        },
        checkConfirmPassword() {
            if (this.ResetPasswordModel.newPassword != this.ResetPasswordModel.confirmPassword) {
                this.errors.confirmPassword = "Password does not match";
            }
            else
            {
                this.errors.confirmPassword = "";
            }
            event.preventDefault();
        },
        forgotPassword(event)
        {
            if(this.validateEmail)
            {
                this.forgotPasswordAPI();
            }
            else{
                showToast("Enter registered email Id", 'error', 'validationerror');
            }
            event.preventDefault();
        },
        changePassword(event) {
            if(this.validatePassword && !this.errors.password && !this.errors.confirmPassword){
                this.changePasswordAPI();
            }
            else
            {
                showToast("Enter valid password", 'error', 'validationerror');
            }
            event.preventDefault();   
        },
        
        closePopUp() {
            this.showPassworkLink = false;
            this.popUpModuleStyles = {
                opacity: "1",
            };
        },
        async forgotPasswordAPI()
        {
            try{
                const response=await userService.forgotPassword(this.emailId);
                if(response.data.header.responseMessage === "SUCCESS"){
                    console.log(response.data);
                    showToast("Reset Password link generated", 'success', 'validationsuccess');
                    this.showPassworkLink=true;
                    this.popUpModuleStyles = {
                        opacity: "0.3",
                        headerMessage:"Reset Password Link",
                        message:"Click the below link to reset your password", 
                        link:response.data.data.resetPasswordUrl
                    };
                }
            }catch(error){
                showToast(error.response.data.message, 'error', 'validationerror');
            }  
        },
        async changePasswordAPI()
        {
            try{
                this.ResetPasswordModel.newPassword=userService.encryptPassword(this.ResetPasswordModel.newPassword);
                const response=await userService.changePassword(this.ResetPasswordModel);
                if(response.data){
                    showToast("Password changed successfully", 'success', 'validationsuccess');
                    this.$router.push('/');
                }
            }catch(error){
                showToast(error.response.data.message, 'error', 'validationerror');
            }  
        }
    }
}
