import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import clientListService from '../client.service.js';
import sharedService from '@/app/services/shared.service.js';
// import sharedService from '@/app/services/sort.service.js';
import { showToast } from '@/app/shared/toast/toast.controller.js';
import deleteModule from '@/app/shared/custompopup/PopUpModule.vue';
import clientService from '../client.service.js';
import sortingService from '@/app/services/sort.service.js';
import { paginationService } from '@/app/services/pagination.service.js';
import statusService from '@/app/services/status.service.js';


export default {
    name: "clientList",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        SideNav,
        deleteModule

    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },


    created() {
        this.fetchclientList();
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))');
        const largeScreenMedia = window.matchMedia('((min-width:1871px) and (max-width:1920px))')
        if (mediaQuery.matches) {
            this.breadcrumbStyles = {
                width: "78.5%",
            }
        } 
        else if(largeScreenMedia.matches){
            this.breadcrumbStyles = {
                width: "81.5%",
            }
        }
        else {
            this.breadcrumbStyles = {
                width: "78.5%",
            };
        }

        this.$store.commit("addToHistory", this.$route);
        this.showBreadcrumbs = true;

    },
    data() {
        return {
            page: 10,
            showDisplayContent: true,
            showDefaultContent: true,
            showFirstSearchBox: true,
            showSecondSearchBox: false,
            clientList: [],
            assessmentListforAssessor: [],
            userAssignedArtifactList: [],
            clientName: "",
            ClientModel: {},
            showCreateFrameworkButton: true,
            validateClientName: false,
            navigationClick: "Home",
            breadcrumbStyles: {},
            showBreadcrumbs: false,
            deleteModuleStyles: {},
            deleteClientId: '',
            showEmptyTableContent: false,
            showclientListTableContent: true,
            showToolTipIndex: null,
            activeTooltipType: null,
            showInfoPopUpModule: false,
            sortOrder: {
                clientName: 'common',
                industry: 'common',
                status: 'common'

            },
            clientListResponse: [],
            searchText: '',
            filteredClients: [],
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            currentPage: 1,
            selectedPage: 1,
            rowsPerPage: 10,
            displayedClientList: [],
            totalRecords: 0,
            totalPages: 0,
            paginationData: {},
        }
    },

    computed: {
        filteredClientList() {
            if (!this.searchText) {
                return this.displayedClientList;
            }
            const enteredText = this.searchText.toLowerCase();
            return this.displayedClientList.filter(client =>
                client.clientName.toLowerCase().includes(enteredText)

            );
        }
    },

    methods: {

        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            console.log(index);
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },

        createNewClient() {
            this.$router.push("/createclientList");
        },
        addNewClientClick() {
            this.$router.push("/createClient");
        },
        editClient(client) {
            sharedService.setData("client.model", client);
            this.$router.push({
                name: "editClient",
                params: { clientId: client.id },
            });

        },
        deleteClient(clientId) {
            this.showDeleteModule = !this.showDeleteModule;
            this.deleteModuleStyles = {
                opacity: "0.3",
                id: clientId

            };
        },
        closePopUp() {
            this.showDeleteModule = false
            this.deleteModuleStyles = {
                opacity: "1",
            };
            this.fetchclientList(this.clientId);
        },
        ProjectListClick(client) {
            sharedService.setData("client.model", client);
            this.$router.push({ name: "projectList", params: { clientId: client.id } });
        },
        async fetchclientList() {
            try {
                const response = await clientListService.fetchclientList();
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.clientListResponse = response.data.data;
                    this.clientList = [...this.clientListResponse];
                    if (this.clientList.length <= 0) {
                        this.showclientListTableContent = false
                        this.showDefaultContent = true
                        this.showEmptyTableContent = true;
                    } else {
                        this.showclientListTableContent = true
                        this.showDefaultContent = false
                        this.showEmptyTableContent = false;
                    }
                    this.updateDisplayedRecords();

                } else {
                    showToast("Failed to fetch clients", "error", 'validationerror');
                }
            } catch (err) {
                console.error('error in controller--', err);

            }
        },

        filterClients() {
            if (this.searchText) {
                this.filteredClients = this.clientList.filter(client =>
                    client.clientName.toLowerCase().includes(this.searchText.toLowerCase())
                );
            } else {
                this.filteredClients = this.clientList;
            }
        },

        getStatus(status) {
            return statusService.getStatus(status)

        },


        async deleteSelectedClient() {
            this.serverRequestOn = true;
            const response = await clientService.deleteClient(this.deleteModuleStyles.id)
            this.serverRequestOn = false;
            if (response.data.header.responseMessage === "SUCCESS")
                if (response.data.data === "SUCCESS") {
                    showToast("Client Deleted Successfully", 'success', 'validationsuccess');
                    this.closePopUp();
                } else {
                    showToast("Failed to Delete Client", 'error', 'validationerror');
                    this.closePopUp();
                }
        },

        sortClient(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedClientList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedClientList, currentPage, rowsPerPage);
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
            if (this.isSearchVisible) {
                this.searchText = '';
            }
        },

        updateDisplayedRecords(column) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            // use paginationservice to handle pagination logic
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.clientList,
            );

            // set pagination data from the service
            this.displayedClientList = this.paginationData.displayedRecords;

        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.clientList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },

    },
}







