import { paginationService } from '@/app/services/pagination.service.js';
import sharedService from '@/app/services/shared.service.js';
import sortingService from '@/app/services/sort.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import deleteModule from '@/app/shared/custompopup/PopUpModule.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import { showToast } from '@/app/shared/toast/toast.controller.js';
import clientService from '../../client/client.service.js';
import projectService from '../project.service';
import statusService from '@/app/services/status.service.js';



export default {
    name: "projectList",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        SideNav,
        deleteModule



    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },




    data() {
        return {
            page: 10,
            showDisplayContent: true,
            showDefaultContent: true,
            showFirstSearchBox: true,
            showSecondSearchBox: false,
            projectList: [],
            navigationClick: "Home",
            breadcrumbStyles: {},
            showBreadcrumbs: false,
            deleteModuleStyles: {},
            deleteProjectId: '',
            showEmptyTableContent: false,
            showToolTipIndex: null,
            activeTooltipType: null,
            clientId: '',
            ClientModel: {},
            sortOrder: {
                projectName: "common",
                sbu: "common",
                status: "common"

            },
            projectListResponse: [],
            searchText: '',
            filteredProjects: [],
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            currentPage: 1,
            selectedPage: 1,
            rowsPerPage: 10,
            showDeleteModule: false,
            displayedProjectList: [],
            totalRecords: 0,
            totalPages: 0,
            paginationData: {},

        };
    },

    computed: {
        filteredprojectList() {
            if (!this.searchText) {
                return this.displayedProjectList;
            }
            const enteredText = this.searchText.toLowerCase();
            return this.displayedProjectList.filter(project =>
                project.projectName.toLowerCase().includes(enteredText)

            );
        }
    },
    created() {
        this.clientId = this.$route.params.clientId;
        this.fetchProjectList(this.clientId);
        this.ClientModel = sharedService.getData("client.model");
        if (!this.ClientModel) {
            this.fetchClientDetails(this.clientId);
        }
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))');
        if (mediaQuery.matches) {
            this.breadcrumbStyles = {
                width: "78.5%",
            }
        } else {
            this.breadcrumbStyles = {
                width: "80.5%",
            };
        }

        this.$store.commit("addToHistory", this.$route);
        this.showBreadcrumbs = true;

    },
    methods: {

        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            console.log(index);
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },

        addProjectClick() {
            //   this.$router.push("/createProject");
            this.$router.push({ name: "createProject", params: { clientId: this.clientId } });
        },
        editProject(project) {
            sharedService.setData("project.model", project);
            this.$router.push({
                name: "editProject",
                params: { projectId: project.id },
            });
        },
        deleteProject(projectId) {
            this.showDeleteModule = true;
            this.deleteModuleStyles = {
                opacity: "0.3",
                id: projectId

            };
            // this.deleteSelectedUser(userId);

        },
        closePopup() {
            this.showDeleteModule = false;
            this.deleteModuleStyles = {
                opacity: "1",
            };
        },

        async deleteProjectAPI() {
            try {
                const response = await projectService.deleteProject(this.deleteModuleStyles.id);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.closePopup();
                    this.fetchProjectList(this.clientId);
                    showToast('Project Deleted Successfully', 'success', 'success');
                } else {
                    showToast('Failed to Delete Project', 'error', 'validationerror');
                }
            } catch (err) {
                console.error('error in controller--', err);

            }
        },

        async fetchProjectList(clientId) {
            try {
                const response = await projectService.projectListByClientId(clientId)
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.projectListResponse = response.data.data;
                    this.projectList = [...this.projectListResponse];
                    if (this.projectList.length <= 0) {
                        this.showprojectListTableContent = false
                        this.showDefaultContent = true
                        this.showEmptyTableContent = true;
                    } else {
                        this.showprojectListTableContent = true
                        this.showDefaultContent = false
                        this.showEmptyTableContent = false;
                    }
                    this.updateDisplayedRecords();
                } else {
                    showToast('Failed to fetch ProjectList For this Client', 'error', 'validationerror');
                }
            } catch (err) {
                console.error('error in controller--', err);

            }
        },

        filterProjects() {
            if (this.searchText) {
                this.filteredProjects = this.projectList.filter(project =>
                    project.projectName.toLowerCase().includes(this.searchText.toLowerCase())
                );
            } else {
                this.filteredProjects = this.projectList;
            }
        },

        getStatus(status) {
            return statusService.getStatus(status)

        },

        async fetchClientDetails(clientId) {
            const response = await clientService.fetchClientById(clientId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.ClientModel = response.data.data;
                sharedService.setData("client.model", this.ClientModel);
            } else {
                showToast(
                    "Failed to fetch Client Details", "error", "validationerror");
            }
        },

        sortProject(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = "common";
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedProjectList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedProjectList, currentPage, rowsPerPage);
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
            if (this.isSearchVisible) {
                this.searchText = '';

            }
        },
        updateDisplayedRecords(column) {
            // use paginationservice to handle pagination logic
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.projectList
            );

            // set pagination data from the service
            this.displayedProjectList = this.paginationData.displayedRecords;

        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.projectList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },

    },
};






