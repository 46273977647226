<template>
  <!-- <div class="login-section"> -->
  <!-- <div>
    {{ JSON.stringify(User,null,2) }}
  </div>
    <h2>RQD SIGN IN</h2>
    <form @submit="login">
      <div>
        <label for="emailId">User Name</label>
        <input type="text" id="emailId" v-model="User.emailId" />
      </div>
      <div>
        <label for="password">Password</label>
        <input type="text" id="password" v-model="User.password" />
      </div>
      <div><button>SIGN IN</button></div>
      <div></div>
    </form> -->
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent>
  <!-- <ToastComponent :type="toastType" :message="toastMessage" :visible="toastVisible"></ToastComponent> -->
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="leftContainer col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
        <div class="flexContainer mt-5 ml-5">
          <div class="logo"></div>
          <div class="nttLogo"></div>
        </div>
        <div class="graphicImage ml-5"></div>
        <div class="copyRights ml-5">
          <span>©2023 NTT DATA, Inc. All Rights Reserved.</span>
        </div>
      </div>
      <div class="rightContainer d-flex justify-content-end col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0 m-0">
        <div class="signIn d-flex justify-content-center align-items-center">
          <div class="container p-0 m-0 d-flex justify-content-center align-items-center loginContainer">
            <div class="col-sm-6 col-md-10 col-lg-8 col-xl-6 offset-4 offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 loginFormDetails">
              <form @submit="login" novalidate class="userLoginForm">
                <div class="d-flex justify-content-center align-items-center signInHeader">
                  <span>Welcome to RQD</span>
                </div>
                <div class="d-flex userDetails">
                  <div class="userIcon"></div>
                  <div class="form-outline d-flex flex-column justify-content-start align-items-start marginBottom150" style="width:85% ;margin-bottom: 5em;">
                    <input type="email" id="emailId" v-model="User.emailId" class="form-control form-control-lg"
                      placeholder=" " @blur="validatingEmail(User.emailId)" @focus="clearEmail()" />
                    <label class="form-label m-0 userName" for="emailId" required>User Name</label>
                    <div v-if="errors.emailId" class="validationMessage">{{ errors.emailId }}</div>
                  </div>                  
                </div>
                <div class="d-flex userDetails">
                  <div class="passwordIcon">

                  </div>
                  <div class="form-outline d-flex flex-column justify-content-start align-items-start mb-5" style="width:85% ;">
                    <input type="password" id="password" v-model="User.password" class="form-control form-control-lg"
                      placeholder=" " @blur="validatingPassword(User.password)" @focus="clearPassword()" />
                    <label class="form-label m-0 password" for="password" required>Password</label>
                    <div v-if="errors.password" class="validationMessage">{{ errors.password }}</div>
                  </div>
                </div>
                <div class="mt-5 d-flex justify-content-start loginButtonClass">
                  <!-- <button type="submit" class="btn btn-primary btn-lg btn-block signInButton">Sign in</button> -->
                  <button type="submit" class="btn btn-primary btn-lg btn-block signInButton">Sign in</button>
                </div>
                <div class="d-flex justify-content-between align-items-center referenceLink">
                  <a href="#!">Terms of Use</a>
                  <a href="" @click="forgotPassword" disabled>Forgot Password?</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
  <!--  </div> -->
</template>
<script src="./login.controller.js"></script>
<style scoped
src="./login.style.css"></style>