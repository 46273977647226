
export const paginationService = {

    updateDisplayRecords(currentPage,rowsPerPage,list){
        const startIndex = (currentPage - 1)*rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage,list.length);
        return{
            displayedRecords : list.slice(startIndex,endIndex),
            totalRecords : list.length,
            startRecordIndex : startIndex + 1,
            endRecordIndex : endIndex,
            totalPages : Math.ceil(list.length/rowsPerPage),
        };
    },

    viewTo(rowsPerPage,list){
        const totalPages = Math.ceil(list.length/rowsPerPage);
        return totalPages;
    },
}