<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="showModuleStyles">
        <div class="signIn"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center searchAssessmentBox" :style="searchAssessmentList">
          <div class="row p-0 d-flex justify-content-start align-items-center mb-3 breadcrumbsResponsive" :style="breadcrumbStyles">
                <BreadcrumbComponent />
          </div>
          <div class="row mx-auto d-flex justify-content-center align-items-center ms-5 me-1 mb-5 searchAssessmentResponsive">
            <div class="headingTextBoxOne d-flex align-items-center ms-5">Basic Framework Details</div>
            <div class="whiteBoxOne ms-5" v-if="showFirstSearchBox">
              <!-- <div class="subHeadingText mt-3 mb-3 row g-3 p-0">Search Filter</div> -->
              <div class="d-flex justify-content-end align-items-right mandatoryField"><span class="mandatoryFieldforForm">* &nbsp;</span> - Mandatory Fields</div>
              <form class="row g-3 p-0">
                <div class="d-flex flex-column justify-content-between p-0 ">                   
                    <div class="d-flex align-items-center">
                      <div class=" col-4 d-flex flex-column " >
                          <span class="mandatoryFieldInput"> <label for="frameworkName" class="form-label createAssessmentLabel">Framework Name</label> * </span>
                          <input type="text" v-model="FrameworkModel.name" name="frameworkName" @change="frameworkNameValidation" class="uploadArtifactFields"/>
                          <div v-if="errors.frameworkName" class="validationMessage">{{ errors.frameworkName }}</div>
                      </div>
                      <div class="col-4 d-flex flex-column">
                        <span class="mandatoryFieldInput" ><label for="frameworkType" class="form-label createAssessmentLabel">Framework Type</label> * </span>
                          <div class="dropdownSelect" style="width:100%">
                              <select v-model="FrameworkModel.type" class="dynamicDropdown" @change="frameworkTypeValidation">
                              <option disabled selected :value="undefined" class="dropdownOption">Select the type of framework</option>
                              <option v-for="framework in frameworkTypes" :key="framework.id" :value="framework" selected>
                                  {{ framework }}
                              </option>
                              </select>
                              <div v-if="errors.frameworkType" class="validationMessage">{{ errors.frameworkType }}</div>
                          </div>
                      </div>
                      <div class="col-4 d-flex flex-column">
                          <label for="status" class="form-label createAssessmentLabel">Framework Status</label>
                          <input v-model="FrameworkModel.status" type="text" autocomplete="off"  name="frameworkStatus" class="uploadArtifactFields" readonly>
                      </div> 
                    </div>
                    <div class="d-flex justify-content-between align-items-end mt-5 formSecondRow">
                      <div class="col-4 d-flex flex-column">
                          <label for="description" class="form-label createAssessmentLabel">Description</label>
                          <textarea v-model="FrameworkModel.description" type="text" id="description" name="description" class="descriptionField" placeholder="A brief description of the Framework" maxlength="100" @keypress="checkTextareaLength"></textarea>
                          <div v-if="errors.frameworkDescription" class="validationMessage">{{ errors.frameworkDescription }}</div>
                      </div>     
                      <div class="col-4 d-flex flex-column"> 
                          <label for="clientName" class="form-label createAssessmentLabel">Client Name</label>
                        <div class="dropdown dropdownResponsive" @click.stop>
                            <button type="button" class="dropdownbutton" @click="toggleDropdown" :title="getSelectedClientNames()">
                              {{getTruncatedClientsNames()}}
                            </button>
                            <ul v-if="showDropdown" class="dropdownMenu">
                              <li v-for="client in clientList" :key="client.id" class="dropdownOption" >
                                <input type="checkbox" style="width:10%" :value="client.id" v-model="FrameworkModel.clientId" @change="frameworkClientValidation"/>
                                  {{client.clientName}}
                              </li>
                            </ul>
                          <div v-if="errors.clientId" class="validationMessage">{{ errors.clientId }}</div>                    
                    </div>
                    </div>
                      <div class="col-4 d-flex justify-content-end">                    
                        <button v-if="showSaveButton" type="search" class="btn btn-primary saveButton" v-on:click="createFramework()">
                            Save
                        </button>
                        <button v-if="!showSaveButton" type="search" class="btn btn-primary saveButton" v-on:click="updateFramework()">
                            Update
                        </button>
                      </div>                   
                    </div>
                </div>
              </form> 
            </div> 
            <div class="whiteBoxOne ms-5" v-if="showSecondSearchBox">
            <form class="row g-3 p-0">
              <div class="d-flex flex-column justify-content-between mt-3 p-0 ">
                <div class="d-flex justify-content-between align-items-center" style="width: 78.5%;">
                  <div class="col-4 d-flex flex-column">
                        <label for="clientName" class="form-label frameworkLabel">Framework Name</label>
                        <div class="frameworkPlaceHolder">
                          <input v-model="FrameworkModel.name" name="frameworkName" type="text" readonly class="fontSize150"/>
                        </div>
                  </div>
                  <div class="col-4 d-flex flex-column">
                        <label for="frameworkType" class="form-label frameworkLabel">Framework Type</label>
                        <div class="frameworkPlaceHolder">
                         <input v-model="FrameworkModel.type" name="frameworkType" type="text" readonly class="fontSize150"/>
                        </div>
                  </div>
                  <div class="col-4 d-flex flex-column">
                        <label for="statusInput" class="form-label frameworkLabel">Framework Status</label>
                        <div class="frameworkPlaceHolder">
                          <input v-model="FrameworkModel.status" name="statusInput" type="text" placeholder="Framework Status" readonly class="fontSize150"/>
                        </div>
                  </div>
                  <div class="col-4 d-flex flex-column">
                        <label for="clientName" class="form-label frameworkLabel">Framework Description</label>
                        <textarea v-model="FrameworkModel.description" type="text" id="description" name="description" class="descriptionField frameworkDescriptionPlaceHolder mt-2" :title="textarea ? '': FrameworkModel.description" readonly></textarea>
<!--                         <div class="frameworkPlaceHolder">
                          <input v-model="FrameworkModel.description" name="description" type="text" readonly class="fontSize150"/>
                        </div> -->
                  </div>
                </div>
                <div class="d-flex justify-content-end align-items-center mt-4 editButtonAlignment">
                  <button type="reset" class="btn btn-primary editButton" v-on:click="reFilterClick()">
                    Edit
                  </button>
                </div>
              </div>
            </form>
            </div> 
            <div class="d-flex justify-content-start align-items-center ms-3 mt-5 headingTextBox" v-if="showFrameworkBox" >
              <div class="d-flex justify-content-between align-items-center" style="width:100%">
                <span>Framework Configuration</span>
              <button type="search" style="z-index:9;margin-right:-1rem" class="btn btn-primary d-flex justify-content-center align-items-center saveLaterButton mb-1" @click="toggleClick()">
                <div class="fontSize150" >{{ this.toggleLabel }}</div>
              </button>
              </div>
            </div>
            <div class="whiteBox ms-5 mb-5" id="whiteBoxTwo"  v-if="showFrameworkBox">
              <div class="d-flex justify-content-start align-items-start fontSize150">
                <ul class="maincategoryList" style="width: 100%;">
                  <div class="d-flex flex-column">
                    <li class="subCategoryListItems"  v-if="enableAddCategory">
                      <div class="d-flex align-items-center" >
                        <div class="d-flex justify-content-between align-items-center" @click=addCategory(category.length,null,type[0],FrameworkModel._id)>
                          <div class="ms-2 addIcon">

                          </div>
                        </div> <!-- Div for plus icon to add category -->
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center" @click=addCategory(category.length,null,type[0],FrameworkModel._id)>
                            <span class="mainCategoryQuestionContent ms-1" id="activeCategory">
                              Add Category
                            </span>
                          </div>   
                          <div class="validationMessage" :style="validationMessageStyle">
                          {{category.catErrorMsg}}
                        </div>                        
                        </div> <!-- Div for Add category Text -->
                      </div>
                      <div class="d-flex flex-column" >
                        <li v-for="(cat,catIndex) in category" :key="cat.catId" style="width:99%;padding-left: 1rem;" ref="categoryHeight">                            
                          <div class="d-flex justify-content-between align-items-top addNewDiv">
                            <div @click="toggleCategory(cat,catIndex,!isCatActive)" :class="{'categoryMaximiseIcon mt-4':!cat.active,'categoryMinimiseIcon mt-4':cat.active}">
                              <div  class="categoryLinkLine" :style="{ height:categoryLineHeight[catIndex] + 'px'}">
                              </div>
                            </div>
                            <div class="col-12 d-flex justify-content-between align-items-top mt-4">                            
                                <div class="col-3 d-flex flex-column ms-3" >
                                  <span class="categoryContent">
                                    Category Name
                                  </span>         
                                  <input v-model="cat.name" type="text" class="inputTextName" @input="clearError(cat,'name')">                           
                                  <span v-if="cat.errorMsg" class="error-message">{{cat.errorMsg}}</span>
                                </div>
                                <div class="col-5 d-flex flex-column ms-1">
                                  <span class="categoryContent">
                                    Category Description
                                  </span>         
                                  <textarea v-model="cat.description" type="text" id="description" name="description" class="descriptionField" placeholder="A brief description of the Category" maxlength="100"></textarea>                         
                                </div>
                                <div class="col-3 d-flex flex-column">
                                  <span class="categoryContent">
                                    Category Weight
                                  </span>         
                                  <input v-model="cat.weight" type="number" class="inputTextName" @input="clearError(cat,'weight')">
                                  <span v-if="cat.weightErrorMsg" class="error-message">{{cat.weightErrorMsg}}</span>
            
                                  
                                </div>
                                <div class="d-flex flex-column">
                                  <span class="btn deleteIcon p-0" id="categoryDeleteIcon" v-on:click="deleteSelectedFrameworkRecord(cat._id,type[0],catIndex,subCatIndex)">
                                   <div v-if="showToolTipDelete" class="toolTip">
                                        Delete
                                    </div>
                                  </span>
                                </div>
                            </div>
                            </div>
                                <!--  Div for Addding Category First row -->
                                <ul class="maincategoryList mt-3 ms-2" style="width:100%;padding-left: 1rem;" v-if="4">
                                    <div class="d-flex flex-column">
                                      <li class="subCategoryListItems" v-if="cat.expanded">
                                        <div class="d-flex align-items-center">
                                          <div class="d-flex justify-content-between align-items-center" @click=addSubCategory(catIndex,category[catIndex].subCategory.length,type[1],category[catIndex]._id)>
                                            <div class="ms-2 addIcon">

                                            </div>
                                          </div> <!-- Div for Subcategory add icon -->
                                          <div class="d-flex justify-content-between align-items-center" >
                                            <div class="d-flex align-items-center" @click=addSubCategory(catIndex,category[catIndex].subCategory.length,type[1],category[catIndex]._id)>
                                              <span class="mainCategoryQuestionContent ms-1" id="activeCategory">
                                                Add Subcategory
                                              </span>
                                            </div>   
                                            <div class="validationMessage" :style="validationMessageStyle">
                                              {{cat.subCatErrorMsg}}
                                            </div>                        
                                          </div> <!-- Div for adding Sub category Text -->                         
                                        </div>                                                                     
                                        <div class="d-flex flex-column">
                                          <li v-for="(subCat,subCatIndex) in category[catIndex].subCategory" :key="subCat.subCatId" style="width: 99.5%;padding-left: 1rem;" ref="subCategoryHeight">
                                            <div class="d-flex justify-content-between align-items-top addNewDiv">
                                              <div @click="toggleSubCategory(subCat,subCatIndex,!isSubCategoryActive)" :class="{'subCategoryMaximiseIcon mt-4':!subCat.active,'subCategoryMinimiseIcon mt-4':subCat.active}">
                                                <div class="subCatLinkLine" :style="{ height:subCategoryLineHeight[subCatIndex] + 'px'}">
                                                </div>
                                              </div>
                                              <div class="col-12 d-flex justify-content-between align-items-top mt-4">                                
                                                <div class="col-3 d-flex flex-column ms-2" >
                                                  <span class="categoryContent">
                                                    Subcategory Name
                                                  </span>         
                                                  <input v-model="subCat.name" type="text" class="inputTextName" @input="clearError(subCat,'name')">                           
                                                  <span v-if="subCat.errorMsg" class="error-message">{{subCat.errorMsg}}</span>
                                                </div>
                                                <div class="col-5 d-flex flex-column" style="margin-left: -1.4em;">
                                                  <span class="categoryContent">
                                                    Subcategory Description
                                                  </span>         
                                                  <textarea v-model="subCat.description" type="text" id="description" name="description" class="descriptionField" placeholder="A brief description of the Sub Category" maxlength="100" style="width:87%"></textarea>                         
                                                  <div class="validationMessage" :style="validationMessageStyle">
                                                        
                                                  </div> 
                                                </div>
                                                <div class="col-3 d-flex flex-column ms-2">
                                                  <span class="categoryContent">
                                                    Subcategory Weight
                                                  </span>         
                                                  <input v-model="subCat.weight" type="number" class="inputTextName" @input="clearError(subCat,'weight')">                           
                                                  <span v-if="subCat.weightErrorMsg" class="error-message">{{subCat.weightErrorMsg}}</span> 
                                                </div>                                                  
                                                <div class="d-flex flex-column ms-1">
                                                      <span class="btn deleteIcon p-0" id="subCategoryDeleteIcon" v-on:click="deleteSelectedFrameworkRecord(subCat._id,type[1],catIndex,subCatIndex)">
                                                      <div v-if="showToolTipDelete" class="toolTip">
                                                            Delete
                                                        </div>
                                                      </span>
                                                </div>
                                              </div>
                                            </div>  
                                            
                                  <ul class="maincategoryList mt-3 ms-2" style="width:100%;padding-left: 1rem;">
                                    <div class="d-flex flex-column">
                                      <li class="subCategoryListItems" v-if="subCat.expanded">
                                        <div class="d-flex align-items-center" >
                                          <div class="d-flex justify-content-between align-items-center" @click=addQc(catIndex,subCatIndex,type[2],category[catIndex].subCategory[subCatIndex]._id)>
                                            <div class="ms-2 addIcon ">

                                            </div>
                                          </div><!--  Div for add icon -->  
                                          <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex align-items-center" @click=addQc(catIndex,subCatIndex,type[2],category[catIndex].subCategory[subCatIndex]._id)>
                                              <span class="mainCategoryQuestionContent ms-1" id="activeCategoryg" >
                                                Add Quality Criteria
                                              </span>
                                            </div>   
                                            <div class="validationMessage" :style="validationMessageStyle">
                                              {{ subCat.qcErrorMsg }}
                                            </div>                        
                                          </div> <!-- Div for adding Sub category Text -->                                                     
                                        </div> 
                                        <div class="d-flex flex-column">
                                          <li v-for="(question,index) in category[catIndex].subCategory[subCatIndex].qc" :key="index" style="width:99.4%;padding-left: 1rem;" ref="qcHeight">
                                            <div class="d-flex justify-content-between align-items-center addNewDiv">
                                              <div class="questionListIcon">
                                                <div  class="qcLinkLine" :style="{height:'100px'}">
                                                </div>
                                              </div>
                                              <div class="col-12 mt-4 d-flex justify-content-between align-items-top">
                                                <div class="col-3 d-flex flex-column">
                                                    <span class="categoryContent">
                                                      Quality Criteria                                                    
                                                    </span>         
                                                    <!-- <input type="text" class="inputTextName"> --> 
                                                    <textarea v-model="question.name" type="text" id="qualityCriteriaDescToolTip" name="description" class="descriptionField" placeholder="Quality Criteria" maxlength="100" :title="textareaContent ? '': question.name" @input="clearError(question,'name')"></textarea> 
                                                    <!-- <span v-if="question.name" class="toolTipQualityCriteria" id="toolTipQcName">{{ question.name }}</span>  -->                        
                                                    <span v-if="question.errorMsg" class="error-message">{{question.errorMsg}}</span> 
                                                </div>
                                                <div class="col-3 d-flex flex-column">
                                                    <span class="categoryContent">
                                                      How to Evaluate Quality
                                                    </span>         
                                                    <textarea v-model="question.evaluate" type="text" id="qualityCriteriaDescToolTip" name="guideline" class="descriptionField" placeholder="How to Evaluate Quality" maxlength="250" :title="textareaContent ? '': question.evaluate" @input="clearError(question,'evaluate')"></textarea>  
                                                    <!-- <span v-if="question.evaluate" class="toolTipQualityCriteria">{{ question.evaluate }}</span> -->                         
                                                    <span v-if="question.evaluateErrorMsg" class="error-message">{{question.evaluateErrorMsg}}</span>
                                                </div>
                                                <div class="col-3 d-flex flex-column">
                                                    <span class="categoryContent">
                                                      Scoring Guidelines
                                                    </span>         
                                                    <textarea v-model="question.guideline" type="text" id="qualityCriteriaDescToolTip" name="guideline" class="descriptionField" placeholder="Scoring Guidelines" maxlength="100" :title="textareaContent ? '': question.guideline" @input="clearError(question,'guideline')"></textarea>  
                                                   <!--  <span class="toolTipQualityCriteria">{{ question.guideline }}</span> -->                       
                                                    <span v-if="question.guidelineErrorMsg" class="error-message">{{question.guidelineErrorMsg}}</span> 
                                                </div>
                                                <div class="d-flex flex-column col-2">
                                                    <span  class="categoryContent">
                                                      Question Weight
                                                    </span>         
                                                    <input v-model="question.weight" type="number" class="inputTextName" @input="clearError(question,'weight')">  
                                                    <span v-if="question.weightErrorMsg" class="error-message">{{question.weightErrorMsg}}</span>                         
                                                     
                                                </div>
                                                <div class="d-flex flex-column ms-4">
                                                    <span class="btn deleteIcon p-0" v-on:click="deleteSelectedFrameworkRecord(question._id,type[2],catIndex,subCatIndex)">
                                                    <div v-if="showToolTipDelete" class="toolTip">
                                                          Delete
                                                      </div>
                                                    </span>
                                                </div>
                                               </div>
                                            </div>                                           
                                          </li>

                                        </div>
                                        <div v-if="category[catIndex].subCategory[subCatIndex].qc.length>0" class="linkLineEnd"></div>   
                                      </li>
                                    </div>
                                  </ul>
                                          </li>

                                        </div>
                                        <div v-if="category[catIndex].subCategory.length>0" class="linkLineEnd">  </div>
                                      </li>
                                    </div>
                                  
                                </ul>

                    </li>
              
                  </div>   
                  <div v-if="category.length>0" class="linkLineEnd"></div>
                    </li>
                  </div>
                </ul> 
              </div>
              <div class="d-flex justify-content-end align-items-end mt-5">
                <button type="reset" class="btn btn-primary saveLaterButton me-4" v-on:click="closeFrameworkScreen()">
                      Close
                  </button>
                <button type="reset" class="btn btn-primary saveLaterButton me-4" v-on:click="saveForLater(true)">
                      Save For Later
                </button>
                <button type="reset" class="btn btn-primary submitButton" v-on:click="submitFrameworkValidation(false)" >
                      Submit
                </button>
              </div>  
            </div>     
          </div>
        </div> 
      </div>
      <PopUpModule v-if="showPopUpModule" :headerMessage=showModuleStyles.headerMessage :message="showModuleStyles.dynamicMessage" @confirm="popupConfirm(showModuleStyles.popupAction)" @cancel="closePopUp"></PopUpModule>

    </div>
  </div>
</template>
<script src="./createFramework.controller.js"></script>
<style scoped src="./createFramework.style.css"></style>