import apiEndpoint from '@/app/services/uri-constants.js';
import axios from '../shared/interceptor.js';
import { showToast } from "../shared/toast/toast.controller.js";

export default {

    async fetchClient() {
        const response = await axios.get(`${apiEndpoint.clientList}`)
        return response;

    },

    async fetchClientById(clientId) {
        return axios.get(`${apiEndpoint.fetchClient}` + clientId)
        .catch(function(error)
        {
            console.log('client error',error);
        })
    },

    async fetchProjectByClientId(id) {
        const response = await axios.get(`${apiEndpoint.projectListByClientId}` +
            id)
        return response;
    },

    async fetchAssessmentByProjectId(projectId) {
        const response = await axios.get(`${apiEndpoint.assessmentListByProjectId}` +
            projectId)
        return response;
    },

    async fetchProjectById(projectId) {
        return axios.get(`${apiEndpoint.fetchProject}` + projectId)
        .catch(function(error)
        {
            console.log('error--',error);
        })

    },


    //create Assessment
    async createAssessmentAPI(assessment) {
        const response = await axios.post(`${apiEndpoint.createAssessment}`, assessment)
        return response;
    },

    //fetchAssessment details by assessmentId
    async fetchAssessmentById(assessmentId) {
        const response = await axios.get(`${apiEndpoint.fetchAssessment}` +
            assessmentId)
        return response;
    },


    async fetchAssessmentByUserId(userId, role) {

        return axios.get(`${apiEndpoint.findAllAssessmentByUser}` + userId + '&role=' + role)
            .catch(function(error) {
                if (error.code == 'ERR_BAD_RESPONSE') {
                    showToast('Failed to fetch the User Assigned Assessment', 'error', 'validationerror');
                } else {
                    showToast('Failed to fetch the score ', 'error', 'validationerror');
                }
            });
    },
    async findArtifactByAssessmentIdAndUserId(id, userId) {
        const response = await axios.get(`${apiEndpoint.findArtifactByAssessmentIdAndUserId}` +
            id + '&userId=' + userId)
        return response;
    },

    // async fetchArtifactScoring(artifactId,assessorId) {
    async fetchArtifactScoring(artifactId,assessorId) {
        return axios.get(`${apiEndpoint.fetchArtifactScoringById}` +
             artifactId + '&assessorId=' + assessorId)
        // return axios.get(`${apiEndpoint.fetchArtifactScoringById}` +
        //     currentId + '&artifactId=' + artifactId)
            .catch(function(error) {
                if (error.code == 'ERR_BAD_RESPONSE') {
                    showToast('Failed to fetch the score Please contact administrator', 'error', 'validationerror');
                } else {
                    showToast('Failed to fetch the score ', 'error', 'validationerror');
                }
            });

    },

    async saveArtifactScore(saveScoreObj) {
        const response = await axios.post(`${apiEndpoint.saveArtifactScore}`,saveScoreObj)

        return response;
    },
    async deleteAssessment(assessmentId) {
        const response = await axios.delete(`${apiEndpoint.deleteAssessment}` + assessmentId)
        return response;
    }
};