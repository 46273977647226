import { ref } from 'vue';

const toast = ref({
    visible: false,
    message: '',
    type: 'success',
    imageType:''
});

const showToast = (message, type, imageType) => {
    toast.value = { visible: true, message, type, imageType};
    setTimeout(() => {
        toast.value.visible = false;
    }, 5000);
};

export { toast, showToast };

export default {

    name: 'SuccessComponent',
    props: {
        visible: Boolean,
        message: String,
        type: String,
        imageType:String,
    },
}