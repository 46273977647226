import artifactService from '@/app/ArtifactDetails/artifact.service.js';
import ArtifactList from "@/app/ArtifactDetails/ArtifactList.vue";
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import sortingService from '@/app/services/sort.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from "@/app/shared/footer/FooterComponent.vue";
import HeaderComponent from "@/app/shared/header/HeaderComponent.vue";
import SideNav from "@/app/shared/side-navigation/SideNav.vue";
import ToastComponent from "@/app/shared/toast/ToastComponent.vue";
import userService from '@/app/UserDetails/user.service.js';
import { SweetModal, SweetModalTab } from "sweet-modal-vue-3";
import { reactive, toRaw } from 'vue';
import { paginationService } from '../services/pagination.service.js';
import sharedService from "../services/shared.service.js";
import statusService from '../services/status.service.js';
import deleteModule from "../shared/custompopup/PopUpModule.vue";
import { showToast, toast } from '../shared/toast/toast.controller.js';

export default {
    name: "artifactList",
    components: {
        FooterComponent,
        HeaderComponent,
        ArtifactList,
        SideNav,
        SweetModal,
        SweetModalTab,
        BreadcrumbComponent,
        deleteModule,
        ToastComponent
    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");

        return {
            toastState: toast,
            loggedInRole,
        };
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            frameworkList: [],
            assessmentId: '',
            assessentName: '',
            assessmentArtifactList: {},
            AssessmentModel: {},
            ArtifactModel: {},
            frameworkTypes: [],
            frameworkListByType: [],
            saveArtifactResponse: {},
            errors: {
                artifactName: '',
                artifactTypr: '',
                artifactDescription: ''
            },
            page: 10,
            deleteArtifactId: '',
            breadcrumbStyles: {},
            showDisplayMessage: false,
            showDeleteModule: false,
            emptyArtifact: false,
            deleteModuleStyles: {},
            viewButtonVisible: {},
            showToolTipIndex: null,
            activeTooltipType: null,
            userList: [],
            frameworkEditFlag: false,
            editFrameworkName: ' ',
            readOnlyText: {},
            sortOrder: {
                artifactname: "common",
                artifacttype: "common",
                attachmentExtn: "common",
                version: "common",
                artifactStatus: "common"

            },
            serverRequestOn: false,
            searchText: '',
            filteredArtifacts: [],
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            selectedPage: 1,
            displayedArtifactList: [],
            currentPage: 1,
            rowsPerPage: 10,
            totalPages: 0,
            paginationData: {}
        };
    },
    computed: {
        filteredassessmentArtifactList() {
            if (!this.searchText) {
                return this.displayedArtifactList;
            }
            const enteredText = this.searchText.toLowerCase();
            return this.displayedArtifactList.filter(artifactDetails =>
                artifactDetails.artifactName.toLowerCase().includes(enteredText)

            );
        },
        isBrowseEnabled() {
            return this.ArtifactModel.artifactStatus === "draft";
        }
    },

    created() {
        // this.$store.commit('clearHistory');
        this.breadcrumbStyles = {
            width: '90%',
        }
        this.assessmentId = this.$route.params.assessmentId;
        this.AssessmentModel = sharedService.getData('assessment.model');
        if (!this.AssessmentModel) {
            this.fetchAssessment(this.assessmentId);
        }
        this.fetchArtifactUserList(this.assessmentId);
        this.fetchArtifactList(this.assessmentId);
        this.fetchFrameworkList();
        this.readOnlyText = {
            color: ' #767676; !important',
        }
    },
    methods: {

        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            console.log(index);
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },
        validateSaveArtifact() {
            if (this.ArtifactModel.artifactName == undefined) {
                this.artifactNameErrorCheck();
            } else if (this.ArtifactModel.artifactType == undefined) {
                this.artifactTypeErrorCheck();
            } else if (this.ArtifactModel.frameworkId == undefined) {
                this.frameworkNameErrorCheck();
            } else if (this.ArtifactModel.version == undefined) {
                this.artifactVersionErrorCheck();
            }
            else if (!this.ArtifactModel.attachment && this.$refs.file.files.length == 0 && this.frameworkEditFlag) {
                this.selectFile();
            }
            else {
                // this.$router.push("/searchAssessment");
                this.ArtifactModel.assessmentId = this.assessmentId;
                const formData = new FormData();
                if (this.file != undefined) {
                    formData.append('file', this.file, this.file.name);
                    formData.append("artifact", JSON.stringify(this.ArtifactModel))
                    this.saveArtifact(formData);

                }
                else {
                    this.updateArtifact(this.ArtifactModel);
                }

            }
            event.preventDefault();
        },
        artifactNameErrorCheck() {
            if (this.ArtifactModel.artifactName == undefined) {
                this.errors.artifactName = 'Please Enter valid Artifact Name';
            } else {
                this.errors.artifactName = '';
            }
        },
        artifactTypeErrorCheck() {
            if (this.ArtifactModel.artifactType == undefined) {
                this.errors.artifactType = 'Please Choose valid Artifact Type';
            } else {
                this.errors.artifactType = '';
                event.preventDefault();
                this.FrameworkModel = [];
                this.frameworkListByType = [];
                for (let framework of toRaw(this.frameworkList)) {
                    if (this.ArtifactModel.artifactType === framework.type && framework.status === 'submitted') {
                        this.frameworkListByType.push(reactive(framework));
                    }
                }
            }

        },
        selectFile() {
            if (!this.ArtifactModel.attachment && this.$refs.file.files.length == 0) {
                this.errors.attachment = 'Please upload valid document';
            } else {
                this.file = this.$refs.file.files[0];
                if (this.file == undefined) {
                    this.errors.attachment = '';
                }
                else if (this.file.size > 3000000) {
                    this.errors.attachment = 'Please upload a document less than 3mb';
                }

                else {
                    this.errors.attachment = '';
                    const dotIndex = this.file.name.lastIndexOf('.');
                    if (dotIndex !== -1) {
                        this.ArtifactModel.attachment = this.file.name.slice(0, dotIndex);
                        this.ArtifactModel.attachmentExtn = this.file.name.slice(dotIndex + 1);
                    } else {
                        this.ArtifactModel.attachment = this.file.name;
                        this.ArtifactModel.attachmentExtn = '';
                    }
                }
                // this.ArtifactModel.attachment = this.file.name;
            }

        },
        frameworkNameErrorCheck() {
            if (this.ArtifactModel.frameworkId == undefined) {
                this.errors.artifactFramework = 'Please choose valid framework';
            } else {
                for (let framework of this.frameworkList) {
                    if (framework._id === this.ArtifactModel.frameworkId) {
                        this.ArtifactModel.frameworkActiveStatus = framework.isActive;
                    }
                }
                this.errors.artifactFramework = '';
                this.viewButtonVisible = {
                    visibility: "visible"
                };
            }
        },
        artifactVersionErrorCheck() {
            if (this.ArtifactModel.version == undefined) {
                this.errors.version = 'Please Enter valid Artifact version';
            } else {
                this.errors.version = '';
            }

        },
        checkTextareaLength() {
            console.log(this.$nextTick)
            let descriptionCharacterLength = this.ArtifactModel.artifactDescription
            if (descriptionCharacterLength === undefined) {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 100) {
                    this.errors.artifactDescription = 'Please Enter valid Description less than 100 characters';
                } else {
                    this.errors.artifactDescription = ''
                }
            })
        },
        assessmentList() {
            if (this.assessmentId) {
                this.$router.push({ name: "editAssessment", params: { assessmentId: this.AssessmentModel.assessmentId } });
            } else {
                showToast("Assessment is not created", 'error', 'validationerror');
            }
        },
        assessmentUserList() {
            if (this.assessmentId && this.emptyArtifact) {
                this.$router.push({ name: "assignUserList", params: { assessmentId: this.assessmentId } });
            } else {
                showToast("Artifact is not created", 'error', 'validationerror');
            }

        },
        addArtifact() {
            this.ArtifactModel = {
                artifactStatus: 'draft'
            };
            this.file = undefined;
            this.$refs.modal.open();
            this.frameworkEditFlag = true;
            this.viewButtonVisible = {
                visibility: "hidden"
            };
        },
        closeArtifactUpload() {
            this.$refs.modal.close();
            this.fetchArtifactList(this.assessmentId);
            this.$refs.file.value = '';
            this.viewButtonVisible = {
                visibility: "hidden"
            };
            this.errors = [];
        },
        editArtifact(artifactDetails) {
            let artifactUser = this.userList.map((user) => {
                if (user.artifactId == artifactDetails.id) return user;
            })
            if (artifactUser && artifactDetails.artifactStatus != 'draft') {
                this.frameworkEditFlag = false;
                let frameworkName = null;
                for (let framework of this.frameworkList) {
                    if (framework._id == artifactDetails.frameworkId) {
                        frameworkName = framework.name;
                    }
                }
                this.editFrameworkName = frameworkName;
                /*                 let abc = this.editFrameworkName.join(', ')
                                abc.replace
                
                                console.log("AFTER COMMA REMOVE", this.editFrameworkName) */
            }
            else {
                this.frameworkEditFlag = true;
            }
            this.ArtifactModel = artifactDetails;
            this.artifactTypeErrorCheck();
            this.file = undefined;
            this.viewButtonVisible = {
                visibility: "visible"
            };
            this.$refs.modal.open();
        },
        deleteButton(artifactId) {

            // this.deleteArtifactId = artifactId
            this.showDeleteModule = !this.showDeleteModule;
            this.deleteModuleStyles = {
                opacity: "0.3",
                id: artifactId
            };
            // this.deleteSelectedAssessment(assessmentId);
        },
        closePopUp() {
            this.showDeleteModule = false
            this.deleteModuleStyles = {
                opacity: "1",
            };
            this.fetchArtifactList(this.assessmentId);
        },
        openFileDialog() {
            event.preventDefault();
            let fileInputElement = this.$refs.file;
            fileInputElement.click();
        },
        nextClick() {
            if (this.emptyArtifact) {
                this.$router.push({ name: "assignUserList", params: { assessmentId: this.assessmentId } });
            } else {
                showToast('Artifact is not created', 'error', 'validationerror')
            }
        },
        getStatus(status) {
            this.status = statusService.getStatus(status)

        },
        viewFramework() {
            const routerData = this.$router.resolve({ name: "viewFramework", params: { frameworkId: this.ArtifactModel.frameworkId } });
            window.open(routerData.href, 'blank');

        },
        
        filterArtifacts() {
            if (this.searchText) {
                this.filteredArtifacts = this.assessmentArtifactList.filter(artifactDetails =>
                    artifactDetails.artifactName.toLowerCase().includes(this.searchText.toLowerCase())
                );
            } else {
                this.filteredArtifacts = this.assessmentArtifactList;
            }
        },
        async fetchAssessment(assessmentId) {
            try {
                const response = await assessmentService.fetchAssessmentById(assessmentId);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.AssessmentModel = response.data.data;
                    sharedService.setData('assessment.model', this.AssessmentModel);
                } else {
                    showToast('Failed to fetch Assessment Details', 'error', 'validationerror')
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }
        },
        async fetchArtifactList(assessmentId) {
            try {
                const response = await artifactService.fetchArtifactListByAssessmentId(assessmentId)
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.assessmentArtifactList = response.data.data
                    if (this.assessmentArtifactList.length <= 0) {
                        this.showDisplayMessage = true
                        this.tableBodyStyles = {
                            display: 'flex !important',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }
                        this.emptyArtifact = false;
                    } else {
                        this.showDisplayMessage = false
                        this.tableBodyStyles = {
                            display: 'block !important',
                        }
                        this.emptyArtifact = true
                    }
                    this.updateDisplayedRecords();
                } else {
                    showToast('Failed to fetch ArtifactList For this Assessment', 'error', 'validationerror')
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }

        },

        async fetchArtifactUserList(assessmentId) {
            try {
                const response = await userService.fetchUserListByAssessmentId(assessmentId)
                console.log("response", response);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.userList = response.data.data;
                } else {
                    showToast('Failed to User List for this Assessment', 'error', 'validationerror')
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }
        },
        async fetchFrameworkList() {
            try {
                const response = await artifactService.fetchFrameworkList()
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.frameworkList = response.data.data
                    console.log("frameworkList", this.frameworkList)
                    this.frameworkTypes = [...new Set(this.frameworkList.map(framework => framework.type))]
                    console.log('frameworkType', this.frameworkTypes);
                } else {
                    showToast('Failed to fetch frameworkList ', 'error', 'validationerror')
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }
        },
        async saveArtifact(formData) {
            try{
                this.serverRequestOn = true;
                const response = await artifactService.saveArtifact(formData);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.saveArtifactResponse = response.data.data;
                    sharedService.setData('artifact.model', this.saveArtifactResponse);
                                  } else {
                    showToast("Failed to Create Artifact", 'error', 'validationerror');
                }
            }
            catch(error){
                showToast(error.response.data.message,'error','validationerror');
            }
            this.serverRequestOn = false;
            this.closeArtifactUpload()

        },

        async updateArtifact(artifact) {

            try{
                this.serverRequestOn = true;
                const updatedArtifactResponse=await artifactService.updateArtifact(artifact);
                if (updatedArtifactResponse.data.header.responseMessage === "SUCCESS") {
                    this.saveArtifactResponse = updatedArtifactResponse.data.data;
                    sharedService.setData('artifact.model', this.saveArtifactResponse)
                } else {
                    showToast("Failed to update Artifact", 'error', 'validationerror');
                }
            }
            catch(error){
                showToast(error.response.data.message,'error','validationerror');
            }
            this.serverRequestOn = false;
            this.closeArtifactUpload()


        },
        async deleteSelectedArtifact() {
            try {
                this.serverRequestOn = true;
                const response = await artifactService.deleteArtifact(this.deleteModuleStyles.id);
                this.serverRequestOn = false;
                //if (response.data.header.responseMessage === "SUCCESS")
                if (response.data.data === "SUCCESS") {
                    showToast("Artifact Delete Success", 'success', 'validationsuccess');
                } else {
                    showToast("Failed to Delete Artifact", 'error', 'validationerror');                    
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }
            this.closePopUp();
            this.serverRequestOn = false;
        },

        sortArtifact(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = "common";
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedArtifactList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedArtifactList, currentPage, rowsPerPage)
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
            if (this.isSearchVisible) {
                this.searchText = '';

            }
        },
        updateDisplayedRecords(column) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            // use paginationservice to handle pagination logic
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.assessmentArtifactList
            );
            // set pagination data from the service
            this.displayedArtifactList = this.paginationData.displayedRecords;
        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.assessmentArtifactList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },
    },
};