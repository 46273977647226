import userService from '@/app/login/login.service.js';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import * as CryptoJS from 'crypto-js';
import SharedService from '../services/shared.service.js';
import { showToast, toast } from '../shared/toast/toast.controller.js';

export default {
    name: 'Login',
    components: {
        ToastComponent
    },
    setup() {
        return {
            toastState: toast
        }
    },
    data() {
        return {
            UserList: [],
            User: {},
            errors: {
                emailId: "",
                password: ""
            },
            validateEmail: false,
            validatePassword: false,
            successMsg: "",
        };
    },
    methods: {
        forgotPassword() {
            this.$router.push('/forgotPassword');
        },
        
        encryptPassword(password) {
            var encryptPassword = CryptoJS.SHA256(password);
            return encryptPassword.toString(CryptoJS.enc.Hex);
        },

        validatingEmail(emailId) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.com$/;
            if (!regex.test(emailId)) {
                this.errors.emailId = "Please enter the Email in correct format";
                event.preventDefault();
            } else {
                this.validateEmail = true;
            }
            return this.validateEmail
        },

        validatingPassword(password) {
            /* const regexp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/; */
            const regexp = /(?=.[a-z])(?=.{5,})/;

            if (!regexp.test(password)) {
                this.errors.password = "Please enter a valid password";
                event.preventDefault();
            } else {
                if (password == undefined) {
                    this.errors.password = "Please enter a valid password"
                } else {
                    this.validatePassword = true;
                }

            }
            return this.validatePassword
        },

        clearEmail() {
            this.errors.emailId = ' ';
            /* this.User.emailId = ''; */
        },

        clearPassword() {
            this.errors.password = ' ';
            this.User.password = '';
        },
        
        login(event) {
            if (!(this.validateEmail) || !(this.validatePassword)) {
                this.validatingEmail(this.User.emailId);
                this.validatingPassword(this.User.password);
            }
            if ((this.validateEmail) && (this.validatePassword)) {
                event.preventDefault()
                this.User.password = this.encryptPassword(this.User.password);
                this.authenticateAPI(this.User);
            } else if ((this.validateEmail == true) && (this.validatePassword == !true)) {
                showToast('Please enter Password', 'error', 'validationerror');
                event.preventDefault();

            } else if ((this.validateEmail == !true) && (this.validatePassword == true)) {
                showToast('Please enter Username', 'error', 'validationerror');
                event.preventDefault();
            } else if ((this.validateEmail == !true) && (this.validatePassword == !true)) {
                showToast('Please enter Username and Password details', 'error', 'validationerror');
                event.preventDefault();
            } else {
                event.preventDefault();
            }
        },

        async authenticateAPI(user)
        {
            try{
                const response=await userService.authenticate(user);
               
                    if (response.data.header.responseMessage === "SUCCESS") {
                        if(response.data.data){
                            const userRoles =[];
                            const token = response.data.data.jwtToken;
                            const refreshToken = response.data.data.refreshToken;
                            SharedService.storeDataInBrowser('auth_token', token);
                            if(refreshToken)
                                    SharedService.storeDataInBrowser('refreshToken', refreshToken);
                            const role = response.data.data.roles;
                            SharedService.storeDataInBrowser('role', role);
                            const additionalRoles=response.data.data.additionalRoles;
                            userRoles.push({
                                label:role.charAt(0).toUpperCase()+ role.slice(1),
                                value:role,                                              
                                isPrimary:'Y'});
                            if(additionalRoles.length>0)
                             {
                                for(let role of additionalRoles)
                                {
                                    userRoles.push(
                                        {
                                            label:role.charAt(0).toUpperCase()+ role.slice(1),
                                            value:role,
                                            isPrimary:'N'
                                        })
                                }
                             }
                            SharedService.storeDataInBrowser('userRoles', userRoles);
                            const firstName = response.data.data.firstName;
                            SharedService.storeDataInBrowser('firstName', firstName);
                            const userId = response.data.data.currentId;
                            SharedService.storeDataInBrowser('userId', userId);
                            console.log(role);
                            console.log(userRoles);
                            // const enterpriseId = response.data.data.enterpriseId;
                            // SharedService.storeDataInBrowser('enterpriseId', enterpriseId);
                            // Store the token and handle login success 
                            this.$router.push("/landingPage");
                        }
                    }
                 else {
                    showToast('Please enter valid Username and Password', 'error', 'validationerror');
                }
            }
            catch(error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }
        }
    }
}