// let deleteOptionSeleted = Boolean
export default {
    name: 'deleteModule',
    data() {
        return {
            /* deleteOptionSeleted: false */
            rejectionReason:"",
        }
    },
    props: {
        headerMessage: String,
        message: String,
        showTextArea:Boolean,
    },
    methods: {
        confirmSelected() {
            // deleteOptionSeleted = true        
            this.$emit('confirm',this.rejectionReason);
        },
        cancelSelected() {
            //  deleteOptionSeleted = false
            this.$emit('cancel');
        },
    }
}
// const confirmDelete = () => {
//     const deleteOptionValue = deleteOptionSeleted
//     console.log(deleteOptionValue)
//     return deleteOptionValue
// }

// export {
//     confirmDelete
// }