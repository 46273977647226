import apiEndpoint from '@/app/services/uri-constants.js';
import axios from '../shared/interceptor.js';
import { showToast } from "../shared/toast/toast.controller.js";

export default {
    async fetchFrameworkList() {
        return await axios.get(`${apiEndpoint.fetchFrameworkList}`)
            .catch(function (error) {
                throw error;
            });
    },

    async createFramework(framework) {
        return axios.post(`${apiEndpoint.createFramework}`, framework)
            .catch(function (error) {
                throw error;
            });
    },

    async createFrameworkRecord(framework) {
        return axios.post(`${apiEndpoint.createFrameworkRecord}`, framework)
            .catch(function (error) {
                throw error;
            });
    },

    async saveAllFrameworkDetails(framework) {
        return axios.post(`${apiEndpoint.saveAllFrameworkDetails}`, framework)
            .catch(function (error) {
                throw error;
            });
    },

    async editFramework(frameworkId) {
        return axios.get(`${apiEndpoint.configureFramework}` + frameworkId).
            catch(function (error) {
                if (error.code == 'ERR_BAD_RESPONSE') {
                    showToast('Failed to fetch the Framework', 'error', 'validationerror');
                } else {
                    showToast('Failed to fetch the Framework ', 'error', 'validationerror');
                }
            });
    },
    async updateFrameworkStatus(frameworkId, status) {
        return axios.post(`${apiEndpoint.updateFrameworkStatus}` + frameworkId + '&status=' + status)
        .catch(function (error) {
            throw error;
        });
    },
    async deleteFramework(frameworkId) {
        return axios.delete(`${apiEndpoint.deleteFramework}` + frameworkId)
            .catch(function (error) {
                throw error;
            });
    },
    async copyFramework(frameworkId) {
        return axios.get(`${apiEndpoint.copyFramework}` + frameworkId)
            .catch(function (error) {
                throw error;
            });

    },
    async deleteFrameworkRecord(id, type) {
        return axios.delete(`${apiEndpoint.deleteFrameworkRecord}` + id + '&type=' + type)
            .catch(function (error) {
                throw error;
            });
    },
    async viewFramework(currentId) {
        return axios.get(`${apiEndpoint.viewFramework}` + currentId)
        .catch(function (error) {
            throw error;
        });
    }


}