<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type"
    :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0">
        <div class="signIn"></div>
        <div class="row mb-0 d-flex flex-column justify-content-center align-items-center createUserBox">
          <div class="row p-0 d-flex justify-content-start align-items-center mb-3" :style="breadcrumbStyles">
            <BreadcrumbComponent />
          </div>
          <div class="headingTextBox d-flex align-items-center me-5 mt-4">Create User</div>
          <div class="whiteBox">
            <div class="d-flex flex-column justify-content-between">
              <div class="d-flex justify-content-end align-items-right mandatoryField">
                <span class="mandatoryFieldforForm">* &nbsp;</span> - Mandatory Fields
              </div>
              <form>
                <div class="d-flex flex-column justify-content-between">
                  <div class="row d-flex justify-content-start align-items-center mt-2 p-0 margin150">
                    <div class="col-4">
                      <label for="firstName" class="form-label createUserLabel">First Name</label><span
                        class="mandatoryFieldInput"> * </span>
                      <div class="createUserDisplayField d-flex">
                        <input v-model="UserModel.firstName" name="firstName" type="text" placeholder="First Name"
                          @change="firstNameValidation" />
                      </div>
                      <div v-if="errors.firstName" class="validationMessage">{{ errors.firstName }}</div>
                    </div>
                    <div class="col-4">
                      <label for="lastName" class="form-label createUserLabel">Last Name</label><span
                        class="mandatoryFieldInput"> * </span>
                      <div class="createUserDisplayField d-flex">
                        <input v-model="UserModel.lastName" name="lastName" type="text" placeholder="Last Name"
                          @change="lastNameValidation" />
                      </div>
                      <div v-if="errors.lastName" class="validationMessage">{{ errors.lastName }}</div>
                    </div>
                    <div class="col-4">
                      <label for="role" class="form-label createUserLabel">Primary Role</label><span
                        class="mandatoryFieldInput"> * </span>
                      <div class="dropdownSelect dropdownResponsive">
                        <select v-model="UserModel.primaryRole" class="dynamicDropdown" @change="userRoleValidation">
                          <option selected value="undefined" disabled>Select User Role</option>
                      <option selected class="optionClass" value="frameworkAdmin">FrameworkAdmin</option>
                          <option selected class="optionClass" value="assessor">Assessor</option>
                          <option selected class="optionClass" value="reviewer">Reviewer</option>
                        </select>
                      </div>
                      <div v-if="errors.primaryRole" class="validationMessage">{{ errors.primaryRole }}</div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-start align-items-center mt-5 p-0 margin150">
                    <div class="col-4">
                      <label for="EmailId" class="form-label createUserLabel">EmailId</label><span
                        class="mandatoryFieldInput"> * </span>
                      <div class="createUserDisplayField d-flex">
                        <input type="emailId" v-model="UserModel.emailId" placeholder="EmailId"
                      @blur="validatingEmail(UserModel.emailId)" @focus="clearEmail()" @change="emailIdValidation" />
                      </div>
                      <div v-if="errors.emailId" class="validationMessage">{{ errors.emailId }}</div>
                    </div>
                    <div class="col-4">
                      <label for="PhoneNumber" class="form-label createUserLabel">Phone Number</label><span
                        class="mandatoryFieldInput"> * </span>
                      <div class="createUserDisplayField d-flex">
                        <input v-model="UserModel.phoneNumber" name="phoneNumber" type="text" placeholder="Phone Number"
                          @change="phoneNumberValidation" />
                      </div>
                      <div v-if="errors.phoneNumber" class="validationMessage">{{ errors.phoneNumber }}</div>
                    </div>
                    <div class="col-4">
                  <label for="state" class="form-label createUserLabel">State</label><span class="mandatoryFieldInput">
                        * </span>
                      <div class="dropdownSelect">
                        <select v-model="UserModel.isActive" id="isActive" class="dynamicDropdown">
                          <option selected value="Y">Active</option>
                          <option value="N">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="row d-flex justify-content-end p-0 buttonsAlignmentResponsive">
              <button type="submit" class="btn btn-primary saveLaterButton float-end ms-3"
                v-on:click="createUserClick()">
                Save
              </button>
              <input type="submit" value="Cancel" class="btn btn-primary nextButton float-end ms-3"
                style="width: 10% !important; " v-on:click="cancelClick()">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./createUser.controller.js"></script>
<style scoped src="./createUser.style.css"></style>
<style></style>