// let deleteOptionSeleted = Boolean
export default {
  name: 'deleteModule',
  data() {
      return {
          /* deleteOptionSeleted: false */
          textareaContent:this.staticMessage,
          passwordLink:this.hyperLink
      }
  },
  props: {
      headerMessage: String,
      message: String,
      showTextArea:Boolean,
      showHyperLink:Boolean,
      hyperLink:String,
      staticMessage:String,
  },
  methods: {
      okSelected() {
          this.$emit('ok'); 
      },
      redirect(link)
      {
        //this.$router.push(link);
        window.open(link, '_blank');
      }
  }
}
// const confirmDelete = () => {
//     const deleteOptionValue = deleteOptionSeleted
//     console.log(deleteOptionValue)
//     return deleteOptionValue
// }

// export {
//     confirmDelete
// }