<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type"
    :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="showModuleStyles">
        <div class="bg-right"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center searchAssessmentBox">
          <div class="d-flex justify-content-start align-items-center mb-3 me-3 breadcrumbsResponsive"
            :style="breadcrumbStyles">
            <BreadcrumbComponent />
          </div>
          <div class="row mx-auto d-flex justify-content-center align-items-center ms-5 me-1 mt-2 assessList150"
            style="width: 95%;">
            <div class="d-flex justify-content-between align-items-center ms-4 p-0 frameworkListResponsive"
              style="width: 92%;">
              <div class="headingTextBoxTwo d-flex align-items-center">Framework List</div>
              <div class="d-flex justify-content-between align-items-center" style="width: 12%;">
                <div v-if="frameworkList.length > 0" class="searchApplication" style="width: 5em;z-index: 1;">
                  <input v-if="isSearchVisible" v-model="searchText" type="text" name="searchText"
                    placeholder="Search by Framework" class="form-control customSearch" @click="filterFrameworks()">
                  <span @click="toggleSearch" class="customSearchIcon me-3"></span>
                </div>
                <select v-if="frameworkList.length > 0" class="page-selector ms-4" v-model="selectedView"
                  @change="viewTo">
                  <option selected disabled>view</option>
                  <option v-for="view in viewDetails" :key="view" :value="view">{{ view }}</option>
                </select>
              </div>
              <button type="search" class="btn btn-primary addNewAssessmentButton" v-on:click="createNewFramework()"
                v-if=showCreateFrameworkButton>
                Create Framework
              </button>
            </div>
            <div class="assessmentListTable mt-3 ms-4 p-0">
              <table class="searchAssessmentListTable">
                <thead>
                  <tr>
                    <th style="border-radius: 10px 0px 0px 0px;">
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Framework Name</span>
                        <span
                          :class="sortOrder.name === 'ascending' ? 'icon-ascending' : sortOrder.name === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortFramework('name')"></span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Framework Type</span>
                        <span
                          :class="sortOrder.type === 'ascending' ? 'icon-ascending' : sortOrder.type === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortFramework('type')"></span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Client Names</span>
                        <span
                          :class="sortOrder.clientNames === 'ascending' ? 'icon-ascending' : sortOrder.clientNames === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortFramework('clientNames')"></span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Status</span>
                        <span
                          :class="sortOrder.status === 'ascending' ? 'icon-ascending' : sortOrder.status === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortFramework('status')"></span>
                      </div>
                    </th>
                    <th style="border-radius: 0px 10px 0px 0px;">
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Action</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>

                  <div v-if="loggedInRole === 'frameworkAdmin' && showEmptyTableContent"
                    class="d-flex justify-content-center mt-5 mb-5 displayContent">
                    <span>No Framework to view, continue to create Framework</span>
                  </div>

                  <tr v-for="(framework, index) in filteredFrameworkList" :key="index">
                    <td>
                      <span class="p-0">
                        {{ framework.name }}
                      </span>
                    </td>
                    <td>
                      <span> {{ framework.type }}</span>
                    </td>
                    <td>
                      <span class="clientNames" v-if="framework.clientNames.length > 25" :title="framework.clientNames">{{
                        truncateClientNames(framework.clientNames.join(', ')) }} </span>
                      <span v-else>{{ framework.clientNames.join(', ') }}</span>
                    </td>
                    <td :class="getStatus(framework.status)"><span>{{ status }}</span></td>
                    <td>
                      <div class="d-flex justify-content-start align-items-center">
                        <span type="search" class="btn editIcon d-flex justify-content-start align-items-center p-0"
                          v-on:click="editFramework(framework)" @mouseover="showToolTip(index, 'edit')"
                          @mouseleave="hideToolTip(index, 'edit')">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'edit'" class="toolTip">
                            <div class="tooltiptext">
                              Edit
                            </div>
                          </div>
                        </span>
                        <span type="search"
                          class="btn frameworkIcon d-flex justify-content-start align-items-center p-0"
                          v-on:click="cloneFramework(framework)" @mouseover="showToolTip(index, 'clone')"
                          @mouseleave="hideToolTip(index, 'clone')">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'clone'" class="toolTip">
                            <div class="tooltiptext">
                              Clone
                            </div>
                          </div>
                        </span>
                        <span v-if="loggedInRole === 'frameworkAdmin'" type="search"
                          class="btn deleteIcon d-flex justify-content-start align-items-center p-0"
                          v-on:click="deleteFramework(framework)" @mouseover="showToolTip(index, 'delete')"
                          @mouseleave="hideToolTip(index, 'delete')">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'delete'" class="toolTip">
                            <div class="tooltiptext">
                              Delete
                            </div>
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="frameworkList.length > 0" class="d-flex justify-content-end align-items-center mt-3 paginationBox">
              <span class="float-right pagination-detail">Jump to</span>
              <span class="me-1 ms-1 seperator"></span>
              <label class="page">Page</label>
              <div class="dropdown-wrapper">
                <select class="page-selector-pagination" v-model="selectedPage" @change="updateDisplayedRecords">
                  <option v-for="page in paginationData.totalPages" :key="page" :value="page">{{ page }}</option>
                </select>
              </div>
              <span class="ms-2 me-2 seperator"></span>
              <span class="pagination-detail">
                Showing {{ paginationData.startRecordIndex }} to {{ paginationData.endRecordIndex }} of {{
                  paginationData.totalRecords }}
              </span>
            </div>
        </div>
      </div>
      <PopUpModule v-if="showPopUpModule" :headerMessage=showModuleStyles.headerMessage
        :message="showModuleStyles.dynamicMessage" @confirm="popupConfirm(showModuleStyles.popupAction)"
        @cancel="closePopUp"></PopUpModule>

      <!-- <delete-module v-if="showDeleteModule" headerMessage="Delete Action" message= "This action will delete the selected Framework and non reversible. Are you sure you want to delete?" @confirm="deleteSelectedFramework" @cancel="closePopUp"></delete-module> -->
      <InfoPopup v-if="showInfoPopUpModule" headerMessage="Message"
        message="Framework cannot be deleted. It is already assigned to one or more Assessment/s." @ok="closePopUp">
      </InfoPopup>
    </div>
  </div>
</template>
<script src="./frameworkList.controller.js"></script>
<style scoped src="./frameworkList.style.css"></style>