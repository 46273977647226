import sharedService from '@/app/services/shared.service';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import { showToast, toast } from '@/app/shared/toast/toast.controller';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import clientService from '../../client/client.service.js';
import projectService from '../project.service';
import CreateProject from './CreateProject.vue';



export default {
    name: "createProject",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        CreateProject,
        SideNav,
        ToastComponent
        // ProjectModel,
    },
    setup() {
        return {
            // toastState: toast,
            toastState: toast,
        };
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            projectError: "",
            ProjectModel: {
                isActive: "Y"
            },
            projectId: "",
            editProject: false,
            errors: {
                client: "",
                project: "",
                sbu: "",

            },
            breadcrumbStyles:{},
            clientList: [],
            clientName: "",
            saveLater: false,
            CreateProject,
            createProjectResponse: {},
            ClientModel: {},

        };
    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    created() {
        this.inputReadOnly = {
            color: "#767676 !important",
        };
        this.breadcrumbStyles = {
            width: "91.5%",
        };
        this.projectId = this.$route.params.projectId;
        this.$store.commit("addToHistory", this.$route);
        if (this.projectId) {
            this.routePath = "/editProject";
            this.ProjectModel = sharedService.getData("project.model");
            this.editProject = true;
        } else {
            this.routePath = "/createProject";
            this.clientId = this.$route.params.clientId;
        }
        this.ClientModel = sharedService.getData("client.model");
        if (!this.ClientModel) {
            this.fetchClientDetails(this.clientId);
        }
    },
    methods: {


        cancelClick() {
            this.$router.push({ name: "projectList", params: { clientId: this.ClientModel.id } });
        },

        clientValidation() {
            if (this.ProjectModel.clientName == undefined) {
                this.errors.client = "Please Select a Client";
            } else {
                this.errors.client = "";
            }
        },
        projectValidation() {
            if (this.ProjectModel.projectName == undefined) {
                this.errors.project = "Please Enter a Project";
            } else {
                this.errors.project = "";


            }

        },
        sbuValidation() {
            if (this.ProjectModel.sbu == undefined) {
                this.errors.sbu = "Please Enter a sbu";
            } else {
                this.errors.sbu = "";


            }

        },

        checkTextareaLength() {
            console.log(this.$nextTick)
            let descriptionCharacterLength = this.ProjectModel.projectDescription
            if (descriptionCharacterLength === undefined) {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 100) {
                    this.errors.projectDescription = 'Please Enter valid Description less than 100 characters';
                } else {
                    this.errors.projectDescription = ''
                }
            })
        },

        createProjectClick() {
            if (this.ProjectModel.projectName == undefined) {
                this.projectValidation();
            } else if (this.ProjectModel.sbu == undefined) {
                this.sbuValidation();
            } else {
                this.createProject();
            }
            event.preventDefault();
        },

        async createProject() {
            this.serverRequestOn = true;
            this.ProjectModel.clientId = this.ClientModel.id;
            const response = await projectService.createProjectAPI(
                this.ProjectModel
            );
            this.serverRequestOn = false;
            if (response.data.header.responseMessage === "SUCCESS") {
                this.createProjectResponse = response.data.data;
                this.ProjectModel.id = this.createProjectResponse.id;
                sharedService.setData(
                    "project.model",
                    this.createProjectResponse
                );
                showToast("Project created successfully", "success", 'validationsuccess');
                this.$router.push({ name: "projectList", params: { clientId: this.ClientModel.id } });


            } else {
                showToast("Failed to Create Project", "error", 'validationerror');
            }
        },
        async fetchProject(projectId) {
            const response = await projectService.fetchProjectById(
                projectId
            );
            if (response.data.header.responseMessage === "SUCCESS") {
                this.ProjectModel = response.data.data;
                sharedService.setData("project.model", this.ProjectModel);
                this.$route.params.projectId;

            } else {
                showToast(
                    "Failed to fetch Project Details",
                    "error",
                    "validationerror"
                );
            }
        },

        async fetchClientDetails(clientId) {
            const response = await clientService.fetchClientById(clientId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.ClientModel = response.data.data;
                sharedService.setData("client.model", this.ClientModel);
            } else {
                showToast(
                    "Failed to fetch Client Details", "error", "validationerror");
            }
        },



    }
}