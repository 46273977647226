import SearchAssessment from '@/app/AssessementDetails/SearchAssessment.vue';
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import AssessmentModel from '@/app/model/assessment.model.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import listAssessmentData from '../../assets/data/listAssessment.json';
import AssessmentScoring from '../score/AssessmentScoring.vue';
import SharedService from '../services/shared.service.js';
import { sharedVariable } from '../services/sharedData.js';
import { showToast, toast } from '../shared/toast/toast.controller.js';

import Paginate from "vuejs-paginate-next";
import sharedService from '../services/shared.service.js';
/* import { DataService } from '../services/service'; */
/* import LarvelVuePagination from 'laravel-vue-pagination'; */
import deleteModule from "../shared/custompopup/PopUpModule.vue";
/* import {confirmDelete} from "../shared/custompopup/popupModule.controller.js" */
import sortingService from '@/app/services/sort.service.js';
import { paginationService } from '../services/pagination.service';
import statusService from '../services/status.service';

export default {
    name: "searchAssessment",
    components: {
        FooterComponent,
        HeaderComponent,
        SearchAssessment,
        BreadcrumbComponent,
        SideNav,
        AssessmentScoring,
        paginate: Paginate,
        ToastComponent,
        deleteModule,
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const loggedInRole = SharedService.getDataFromBrowser("role");
        sharedVariable.value = window.location.pathname;
        return {
            toastState: toast,
            loggedInRole,
        };
    },

    created() {
        if (this.loggedInRole == "assessor" || this.loggedInRole == "reviewer") {
            this.displaySearchFilter();
        } else {
            this.ClientModel = sharedService.getData("client.model");
            this.ProjectModel = sharedService.getData("project.model");
            if (!this.ClientModel || !this.ProjectModel) {
                this.ProjectModel = {}
                this.ClientModel = {}
                this.onLoadClientName();
            } else {
                    this.searchClick(this.ProjectModel.id);
            }

        }
        this.breadcrumbStyles = {
            width: "83.75%",
        };
        // this.showEmptyTableContent = true
        //this.$store.commit("clearHistory");
        this.$store.commit("addToHistory", this.$route);

    },
    data() {
        return {
            assessments: listAssessmentData,
            page: 10,
            showDisplayContent: true,
            showDefaultContent: true,
            showFirstSearchBox: true,
            showSecondSearchBox: false,
            clientList: [],
            projectList: [],
            assessmentList: [],
            assessmentListforAssessor: [],
            userAssignedArtifactList: [],
            clientName: "",
            projectName: "",
            AssessmentModel,
            ProjectModel: {},
            ClientModel: {},
            ArifactModel: {},
            projectId: "",
            userId: "",
            role: "",
            assessmentId: "",
            showAssessmentSearchFilter: true,
            showAddAssessmentButton: true,
            searchAssessmentList: {},
            validateClientName: false,
            validateProjectName: false,
            clientError: "",
            projectError: "",
            navigationClick: "Home",
            breadcrumbStyles: {},
            assessmentListStyleAssessor: {},
            showBreadcrumbs: false,
            showDeleteModule: false,
            deleteModuleStyles: {},
            showEmptyTableContent: false,
            showAssessorTableContent: false,
            showToolTipIndex: null,
            activeTooltipType: null,
            createAssessmentResponse: {},
            newAssessmentID: "",
            iconStyleAssessor: {},
            spaceDivAssessor: {},
            sortOrder: {
                assessmentName: "common",
                startdate: "common",
                artifactCount: "common",
                status: "common"

            },
            assessmentListResponse: [],
            searchText: '',
            filteredAssessments: [],
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            selectedPage: 1,
            displayedAssessmentList: [],
            currentPage: 1,
            rowsPerPage: 10,
            // totalPages: 0,
            paginationData: {
                startRecordIndex: 1,
                endRecordIndex: 3,
                totalRecords: 3,
                totalPages: 2
            },
        };
    },
    computed: {
        filteredassessmentList() {
            if (!this.searchText) {
                return this.displayedAssessmentList;
            }
            const enteredText = this.searchText.toLowerCase();
            return this.displayedAssessmentList.filter(assessment =>
                assessment.assessmentName.toLowerCase().includes(enteredText)

            );
        }
    },


    methods: {
        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            console.log(index);
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },
        /*   deleteAssessmentCli
        ck() {
            this.showDeleteModule = !this.showDeleteModule;
            this.deleteModuleStyles = {
              opacity: "0.3",
            };      
          }, */
        clickCallback(pageNum) {
            console.log(pageNum);
        },
        enableSearchClick(projectId) {
            this.validateSelection(projectId);
            this.clearMessage = {
                visibility: "visible !important",
            };
        },
        validateSelection(projectId) {
            if (this.validateClientName == true && this.validateProjectName == true) {
                this.searchClick(projectId);
                this.clientError = "";
                this.projectError = "";
                event.preventDefault();
            } else if (this.validateClientName == true && this.validateProjectName == !true) {
                this.projectError = "Please select Project";
                this.clientError = "";
                event.preventDefault();
            } else if (this.validateClientName == !true && this.validateProjectName == true) {
                this.clientError = "Please select Client";
                event.preventDefault();
            } else {
                this.clientError = "Please select Client";
                event.preventDefault();
            }
        },
        dateSelected(date) {
            const dateObj = new Date(date);
            return dateObj.toLocaleDateString("en-us", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        },
        searchClick(projectId) {
            this.showFirstSearchBox = false;
            this.showDisplayContent = false;
            this.showSecondSearchBox = true;
            this.showDefaultContent = false;
            this.fetchAssessmentListByProjectId(projectId);
            event.preventDefault();
        },

        getStatus(status) {
            return statusService.getStatus(status)

        },
        reFilterClick() {
            this.ProjectModel = {};
            this.ClientModel = {};
            this.assessmentList = [];
            this.displayedAssessmentList = [];
            this.validateClientName = false;
            this.validateProjectName = false;
            this.showFirstSearchBox = true;
            this.showSecondSearchBox = false;
            this.showDisplayContent = true;
            this.showDefaultContent = true;
            this.onLoadClientName();
            event.preventDefault();
            this.clearMessage = {
                visibility: "hidden !important",
            };

        },
        addNewAssessmentClick() {
            this.$router.push("/createAssessment");
            // this.$router.push({ name: "createAssessment", params: { assessmentId: ' ' } });
        },
        editAssessment(assessment) {

            sharedService.setData("assessment.model", assessment);
            if (this.loggedInRole == "frameworkAdmin") {
                this.$router.push({
                    name: "editAssessment",
                    params: { assessmentId: assessment.id },
                });
            }
            if (this.loggedInRole == "assessor" || this.loggedInRole == "reviewer") {
                this.$router.push({
                    name: "viewAssessment",
                    params: { assessmentId: assessment.id },
                });

            }
        },
        deleteAssessmentClick(assessmentId) {
            this.showDeleteModule = !this.showDeleteModule;
            this.deleteModuleStyles = {
                opacity: "0.3",
                id: assessmentId
            };
            // this.deleteSelectedAssessment(assessmentId);
        },
        closePopUp() {
            this.showDeleteModule = false
            this.deleteModuleStyles = {
                opacity: "1",
            };
            this.fetchAssessmentListByProjectId(this.ProjectModel.id);
        },
        addArtifact(assessment) {
            sharedService.setData("assessment.model", assessment);
            this.$router.push({
                name: "artifactList",
                params: { assessmentId: assessment.id },
            });
        },


        scoreIconClick(assessment) {
            if (assessment.artifactCount > 0) {
                this.ArtifactModel = sharedService.clearData('artifact.model');
                this.$router.push({
                    name: "assessmentScoring",
                    params: { assessmentId: assessment.id, assessmentName: assessment.assessmentName },
                });
            } else {
                showToast("Please add artifact to score", "error", 'validationerror');
            }
        },

        dashboardIconClick(assessment) {
            if (assessment.artifactCount > 0) {
                this.$router.push({ name: "assessmentDashboard", params: { assessmentId: assessment.id } });
                sharedService.setData("assessment.model", assessment);
            } else {
                showToast("Please add artifact, Score it and view the dashboard", "error", 'validationerror');
            }
        },
        selectedProjectId() {
            this.validateProjectName = true;
            this.projectError = "";
            // this.projectId = event.target.value
        },
        displaySearchFilter() {
            //const role = SharedService.getDataFromBrowser("role");

            this.showAssessmentSearchFilter = false;
            this.showAddAssessmentButton = false;
            this.showDisplayContent = false;
            this.showDefaultContent = true;
            this.searchAssessmentList = {
                justifyContent: "flex-start !important",
            };
            this.showBreadcrumbs = true;
            this.assessmentListStyleAssessor = {
                marginTop: "3rem !important",
            };
            this.findAssessmenListByUserId();

        },
        async onLoadClientName() {
            /*       if(role == "frameworkAdmin"){
                        response = await assessmentService.fetchClient()
                      } */
            /* else if(role == "assessor"){} */
            const response = await assessmentService.fetchClient();
            if (response.data.header.responseMessage === "SUCCESS") {
                this.clientList = response.data.data;
                console.log(this.clientList);
            } else {
                showToast("Failed to fetch Clients", "error", 'validationerror');
            }
        },
        async fetchAssessmentListByProjectId(projectId) {
            const response = await assessmentService.fetchAssessmentByProjectId(
                projectId
            );
            console.log("response", response);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.assessmentList = response.data.data;
                if (this.assessmentList.length <= 0) {
                    this.showEmptyTableContent = true
                } else {
                    this.showEmptyTableContent = false
                }
                this.updateDisplayedRecords();
                sharedService.setData("client.model", this.ClientModel);
                sharedService.setData("project.model", this.ProjectModel);
                sharedService.setData("assessment.model", this.AssessmentModel);
            } else {
                showToast("Failed to fetch Projects", "error", 'validationerror');
            }
        },


        filterAssessments() {
            if (this.searchText) {
                this.filteredAssessments = this.assessmentList.filter(assessment =>
                    assessment.assessmentName.toLowerCase().includes(this.searchText.toLowerCase())
                );
            } else {
                this.filteredAssessments = this.assessmentList;
            }
        },
        updateDisplayedRecords(column) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            // use paginationservice to handle pagination logic
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.assessmentList
            );

            // set pagination data from the service
            this.displayedAssessmentList = this.paginationData.displayedRecords;
        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.assessmentList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },
        async loadProjectByClient() {
            this.validateClientName = true;
            const response = await assessmentService.fetchProjectByClientId(
                this.ClientModel.id
            );
            if (response.data.header.responseMessage === "SUCCESS") {
                this.projectList = response.data.data;
                if(this.projectList.length <= 0){
                    showToast("No projects available for the selected client", "error", "validationerror");   
                }
                console.log(this.projectList);
            } else {
                showToast("Failed to fetch Projects", "error", 'validationerror');
            }
            this.clientError = "";
            this.projectError = "";
        },
        async findAssessmenListByUserId() {
            const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))');
            if (mediaQuery.matches) {
                this.spaceDivAssessor = {
                    justifyContent: 'start !important',
                    marginLeft: '-0.55em'
                }
                this.iconStyleAssessor = {
                    width: '3.5em !important'
                }
            }
            this.userId = SharedService.getDataFromBrowser("userId");
            this.role = SharedService.getDataFromBrowser("role");
            console.log("ID is--->", this.userId);

            assessmentService.fetchAssessmentByUserId(this.userId, this.role).then(response => {
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.displayedAssessmentList = response.data.data;
                    if (this.displayedAssessmentList.length <= 0) {
                        this.showAssessorTableContent = true;

                    } else {
                        this.showAssessorTableContent = false;

                    }
                } else {
                    showToast("Failed to fetch user assigned assessment", "error", 'validationerror');
                }

            })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                    showToast('Failed to fetch user assigned assessment', 'error', 'validationerror')
                });
        },
        async deleteSelectedAssessment() {
            const response = await assessmentService.deleteAssessment(this.deleteModuleStyles.id);
            //if (response.data.header.responseMessage === "SUCCESS")
            if (response.data.data === "SUCCESS") {
                showToast("Assessment Deleted Successfully", 'success', 'validationsuccess');
                this.closePopUp();
            } else {
                showToast("Failed to Delete Assessment", 'error', 'validationerror');
                this.closePopUp();
            }
        },

        sortAssessment(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = "common";
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedAssessmentList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedAssessmentList, currentPage, rowsPerPage)
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
            if (this.isSearchVisible) {
                this.searchText = '';

            }
        },
    },
};