import SharedService from "@/app/services/shared.service";
import { sharedVariable } from '../../services/sharedData.js';
import LogoutModule from "../custompopup/popupModule.controller.js";
export default {
    name: "customPopUpComponent",
    components: {
        LogoutModule
    },
    props: ['title'],
    setup() {
        // Access the shared variable from Controller A
        const currentPathVariable = sharedVariable.value;
        // Your component logic here

        return {
            currentPathVariable
        }
    },
    data() {
        return {
            isOpen: false,
            userName: '',
            headerLogoStyles: {},
            currentPathVariable: '',
            showPopUpModule: false,
            PopUpModuleStyles: {},
            showDropdown: false,
            showRoleDropdown: false,
            selectedRole: 'admin',
            additionalRoles: [],
            currentRole: {}
        }
    },
    created() {
        this.displayUserName();
        this.checkCondition();
        this.roleList();
    },
    methods: {
        roleList() {
            const loggedInRole = SharedService.getDataFromBrowser('role');
            this.additionalRoles = SharedService.getDataFromBrowser('userRoles');
            if(Object.keys(this.currentRole).length===0){
                for(let role of this.additionalRoles)
                {
                    if( role.value==loggedInRole)
                    {
                        this.currentRole=role
                    }
                }

            }
            console.log(this.currentRole);
            },
        checkCondition() {
            if (this.currentPathVariable == "/landingPage") {
                this.headerLogoStyles = {
                    padding: " 1em !important"
                }
            } else {
                this.headerLogoStyles = {
                    /* padding: "1em 1em 1em 7em !important" */
                    padding: "1em 1em 1em 1em !important"
                }
            }
        },

        validateAdditionalRole(role){
            if(role == "frameworkAdmin"){
                return (this.additionalRoles[0] = "Framework Admin");
            }
            else if(role == "assessor"){
                return (this.additionalRoles[0] = "Assessor")
            }
            else if(role == "reviewer"){
                return (this.additionalRoles[0] = "Reviewer")
            }
        },
        async displayUserName() {
            const username = SharedService.getDataFromBrowser('firstName');
            this.userName = username;
        },
        logout() {
            SharedService.removeDataFromBrowser('auth_token');
            SharedService.removeDataFromBrowser('roles');
            this.$router.push("/");
            SharedService.clearData('project.model');
            SharedService.clearData('client.model');
            this.$store.commit('clearHistory');
        },
        logoutSession() {
            this.showPopUpModule = !this.showPopUpModule;
            this.PopUpModuleStyles = {
                opacity: "0.3",
            };
            // this.deleteSelectedAssessment(assessmentId);
        },
        closePopUp() {
            this.showPopUpModule = false
            this.PopUpModuleStyles = {
                opacity: "1",
            };
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        toggleRoleDropdown() {
            this.showRoleDropdown = !this.showRoleDropdown;
        },
        changeRole(role) {
            // Handle role change logic here
            SharedService.storeDataInBrowser('role', role.value);
            this.currentRole=role
            this.$router.push({ name: 'LandingPage', query: { role: role.value } });

            console.log('Role changed to:', role);
            this.toggleDropdown();
        },


    }
}