<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type"
    :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="deleteModuleStyles">
        <div class="bg-right"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center searchAssessmentBox"
          :style="searchAssessmentList">
          <div class="row p-0 d-flex justify-content-start align-items-center mb-3 breadcrumbsResponsive"
            :style="breadcrumbStyles">
            <BreadcrumbComponent />
          </div>
          <div
            class="row mx-auto d-flex justify-content-center align-items-center ms-4 me-1 mb-5 searchAssessmentResponsive"
            v-if="showAssessmentSearchFilter">
            <div class="headingTextBoxOne d-flex align-items-center ms-5">Assessment Search</div>
            <div class="whiteBoxOne ms-5" v-if="showFirstSearchBox">
              <!-- <div class="subHeadingText mt-3 mb-3 row g-3 p-0">Search Filter</div> -->
              <form class="row g-3 p-0">
                <div class="d-flex justify-content-between align-items-center mt-4 p-0 ">
                  <div class="d-flex justify-content-between align-items-center" style="width: 90%;">
                    <div class="col-4 d-flex flex-column">
                      <span class="mandatoryFieldInput"><label for="clientName" class="form-label createAssessmentLabel">Client Name</label> &nbsp; * </span>
                      <div class="dropdownSelect">
                        <select v-model="ClientModel" id="clientName" class="dynamicDropdown" :value="value" ref='input'
                          @change="loadProjectByClient">
                          <option disabled selected :value="{}" class="dropdownOption">Client Name</option>
                          <option v-for="client in clientList" :key="client.id" :value="client" selected>
                            {{ client.clientName }}
                          </option>
                        </select>
                        <div v-if="clientError" class="validationMessage">{{ clientError }}</div>
                      </div>
                    </div>
                    <div class="col-4 d-flex flex-column">
                      <span class="mandatoryFieldInput"><label for="projectName"
                          class="form-label createAssessmentLabel">Project Name</label> &nbsp; * </span>
                      <div class="dropdownSelect">
                        <select v-model="ProjectModel" id="projectName" class="dynamicDropdown"
                          :disabled="!ClientModel.clientName" @change="selectedProjectId()">
                          <option disabled selected :value="{}" class="dropdownOption">Project Name</option>
                          <option v-for="project in projectList" :key="project.id" :value="project" selected>
                            {{ project.projectName }}
                          </option>
                        </select>
                        <div v-if="projectError" class="validationMessage">{{ projectError }}</div>
                      </div>
                    </div>
                    <div class="col-4 d-flex flex-column">
                      <label for="status" class="form-label createAssessmentLabel" style="color:#727272;">Status</label>
                      <div class="dropdownSelect">
                        <select class="dynamicDropdown" disabled>
                          <option disabled selected value="undefined" class="dropdownOption">Select</option>
                          <option selected class="optionClass">Not Started</option>
                          <option selected class="optionClass">In Progress</option>
                          <option selected class="optionClass">Completed</option>
                          <option selected class="optionClass">All</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end align-items-center" style="width: 15%;margin-top: 1.5em;">
                    <button type="search" class="btn btn-primary searchButton"
                      v-on:click="enableSearchClick(ProjectModel.id)">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="whiteBoxOne ms-5" v-if="showSecondSearchBox">
              <!-- <div class="subHeadingText mt-3 mb-3 row g-3 p-0">Filtered</div> -->
              <form class="row g-3 p-0">
                <div class="d-flex justify-content-between align-items-center mt-4 p-0 ">
                  <div class="d-flex justify-content-between align-items-center" style="width: 90%;">
                    <div class="col-4 d-flex flex-column">
                      <label for="clientName" class="form-label searchAssessmentLabel">Client Name</label>
                      <div class="searchAssessPlaceHolder">
                        <input v-model="ClientModel.clientName" name="clientName" type="text" readonly
                          class="fontSize150" />
                      </div>
                    </div>
                    <div class="col-4 d-flex flex-column">
                      <label for="projectName" class="form-label searchAssessmentLabel">Project Name</label>
                      <div class="searchAssessPlaceHolder">
                        <input v-model="ProjectModel.projectName" name="projectName" type="text" readonly
                          class="fontSize150" />
                      </div>
                    </div>
                    <div class="col-4 d-flex flex-column">
                      <label for="statusInput" class="form-label searchAssessmentLabel">Status</label>
                      <div class="searchAssessPlaceHolder">
                        <input name="statusInput" id="statusInput" type="text" placeholder="All" readonly
                          class="fontSize150" />
                      </div>
                    </div>
                  </div>
                  <div v-if="assessmentList.length > 0" class="d-flex justify-content-end align-items-center"
                    style="width: 17%;margin-top: 1.5em;">
                    <button type="reset" class="btn btn-primary resetButton" v-on:click="reFilterClick()">
                      Re-filter
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row mx-auto d-flex justify-content-center align-items-center ms-4 me-1 mt-2 assessList150"
            style="width: 95%;" :style="assessmentListStyleAssessor">
            <div class="d-flex justify-content-between align-items-center ms-5 p-0" style="width: 92%;">
              <div class="headingTextBoxTwo d-flex align-items-center">Assessment List</div>
              <div class="d-flex justify-content-between align-items-center">
                <div v-if="displayedAssessmentList.length > 0" class="searchApplication" style="z-index: 1;">
                  <input v-if="isSearchVisible" v-model="searchText" type="text" name="searchText"
                    placeholder="Search by Assessment" class="form-control customSearch" @click="filterAssessments()">
                  <span @click="toggleSearch" class="customSearchIcon me-3"></span>
                </div>
                <select v-if="displayedAssessmentList.length > 0" class="page-selector ms-4" v-model="selectedView"
                  @change="viewTo">
                  <option selected disabled>view</option>
                  <option v-for="view in viewDetails" :key="view" :value="view">{{ view }}</option>
                </select>
              </div>
              <button type="search" class="btn btn-primary addNewAssessmentButton" v-on:click="addNewAssessmentClick()"
                v-if=showAddAssessmentButton>
                Add New Assessment
              </button>
            </div>
            <div class="assessmentListTable mt-3 ms-5 p-0">
              <table class="searchAssessmentListTable">
                <thead>
                  <tr>
                    <th style="border-radius: 10px 0px 0px 0px;">
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Assessment</span>
                        <span
                          :class="sortOrder.assessmentName === 'ascending' ? 'icon-ascending' : sortOrder.assessmentName === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortAssessment('assessmentName')"></span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Start Date</span>
                        <span
                          :class="sortOrder.assessmentStartDate === 'ascending' ? 'icon-ascending' : sortOrder.assessmentStartDate === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortAssessment('assessmentStartDate')"></span>
                      </div>
                    </th>
                    <th v-if="loggedInRole === 'frameworkAdmin'">
                      <div class="d-flex justify-content-start align-items-center ">
                        <span>Artifact Count</span>
                        <span
                          :class="sortOrder.artifactCount === 'ascending' ? 'icon-ascending' : sortOrder.artifactCount === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortAssessment('artifactCount')"></span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-start align-items-center">
                        <span>OverAll Status</span>
                        <span
                          :class="sortOrder.assessmentStatus === 'ascending' ? 'icon-ascending' : sortOrder.assessmentStatus === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortAssessment('assessmentStatus')"></span>
                      </div>
                    </th>
                    <th style="border-radius: 0px 10px 0px 0px;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <div class="d-flex justify-content-center mt-5 mb-5 displayContent"
                    v-if="loggedInRole === 'frameworkAdmin' && showDefaultContent">
                    <span>Search using the above filter to display content here</span>
                  </div>
                  <div v-if="loggedInRole === 'frameworkAdmin' && showEmptyTableContent"
                    class="d-flex justify-content-center mt-5 mb-5 displayContent">
                    <span :style="clearMessage">No Assessments to view for selected project, continue adding
                      Assessments</span>
                  </div>
                  <div class="d-flex justify-content-center mt-5 mb-5 displayContent"
                    v-if="(loggedInRole === 'assessor' || loggedInRole === 'reviewer') && showAssessorTableContent">
                    <span>No Assessment assigned for this user</span>
                  </div>
                  <tr v-for="(assessment, index) in filteredassessmentList" :key="index">
                    <td>
                      <span class="p-0" style="cursor:pointer;color: #2E4CA0;" v-on:click="editAssessment(assessment)">
                        {{ assessment.assessmentName }}
                      </span>
                    </td>
                    <td>{{ dateSelected(assessment.assessmentStartDate) }}</td>
                    <td v-if="loggedInRole === 'frameworkAdmin'">{{ assessment.artifactCount }}</td>
                    <td>
                      {{ getStatus(assessment.assessmentStatus) }}
                    </td>
                    <td>
                      <div class="d-flex justify-content-start align-items-center" :style="spaceDivAssessor">
                        <span v-if="loggedInRole === 'frameworkAdmin'" type="search"
                          class="btn addIcon d-flex justify-content-center align-items-center p-0"
                          v-on:click="addArtifact(assessment)" @mouseover="showToolTip(index, 'add')"
                          @mouseleave="hideToolTip(index, 'add')">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'add'" class="toolTip">
                            <div class="tooltiptext">
                              Add
                            </div>
                          </div>
                        </span>
                        <span v-if="loggedInRole === 'frameworkAdmin'" type="search"
                          class="btn editIcon d-flex justify-content-center align-items-center p-0"
                          v-on:click="editAssessment(assessment)" @mouseover="showToolTip(index, 'edit')"
                          @mouseleave="hideToolTip(index, 'edit')">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'edit'" class="toolTip">
                            <div class="tooltiptext">
                              Edit
                            </div>
                          </div>
                        </span>
                        <span v-if="loggedInRole === 'frameworkAdmin'" type="search"
                          class="btn deleteIcon d-flex justify-content-center align-items-center p-0"
                          v-on:click="deleteAssessmentClick(assessment.id)" @mouseover="showToolTip(index, 'delete')"
                          @mouseleave="hideToolTip(index, 'delete')">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'delete'" class="toolTip">
                            <div class="tooltiptext">
                              Delete
                            </div>
                          </div>
                        </span>
                        <span v-if="loggedInRole === 'frameworkAdmin'" type="search"
                          class="btn scoreIcon d-flex justify-content-center align-items-center p-0"
                          v-on:click="scoreIconClick(assessment)" @mouseover="showToolTip(index, 'score')"
                          @mouseleave="hideToolTip(index, 'score')" :style="iconStyleAssessor">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'score'" class="toolTip">
                            <div class="tooltiptext">
                              Scoring
                            </div>
                          </div>
                        </span>
                        <span v-if="loggedInRole === 'frameworkAdmin'" type="search"
                          class="btn dashboardIcon d-flex justify-content-center align-items-center p-0"
                          v-on:click="dashboardIconClick(assessment)" @mouseover="showToolTip(index, 'dashboard')" @mouseleave="hideToolTip(index, 'dashboard')"
                          :style="iconStyleAssessor">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'dashboard'" class="toolTip">
                            <div class="tooltiptext">
                              Dashboard
                            </div>
                          </div>
                        </span>
                        <span v-if="loggedInRole === 'assessor' || loggedInRole === 'reviewer'" type="search"
                          class="btn scoreIcon d-flex justify-content-center align-items-center p-0" id="assessorScore"
                          v-on:click="scoreIconClick(assessment)" @mouseover="showToolTip(index, 'score')"
                          @mouseleave="hideToolTip(index, 'score')" :style="iconStyleAssessor">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'score'" class="toolTip">
                            <div class="tooltiptext">
                              Scoring
                            </div>
                          </div>
                        </span>
                        <span v-if="loggedInRole === 'assessor' || loggedInRole === 'reviewer'" type="search"
                          class="btn dashboardIcon d-flex justify-content-center align-items-center p-0"
                          id="assessorDashboard" v-on:click="dashboardIconClick(assessment)"
                          @mouseover="showToolTip(index, 'dashboard')" @mouseleave="hideToolTip(index, 'dashboard')" :style="iconStyleAssessor">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'dashboard'" class="toolTip">
                            <span class="tooltiptext">
                              Dashboard
                            </span>
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="displayedAssessmentList.length > 0" class="d-flex justify-content-end align-items-center mt-3 paginationBox">
            <span class="float-right pagination-detail">Jump to</span>
            <span class="me-1 ms-1 seperator"></span>
            <label class="page">Page</label>
            <div class="dropdown-wrapper">
              <select class="page-selector-pagination" v-model="selectedPage" @change="updateDisplayedRecords">
                <option v-for="page in paginationData.totalPages" :key="page" :value="page">{{ page }}</option>
              </select>
            </div>
            <span class="ms-2 me-2 seperator"></span>
            <span class="pagination-detail me-1">
              Showing {{ paginationData.startRecordIndex }} to {{ paginationData.endRecordIndex }} of {{
                paginationData.totalRecords }}
            </span>
          </div>
        </div>
      </div>
      <delete-module v-if="showDeleteModule" headerMessage="Delete Action"
        message="This action will delete the selected Assessment and all the artifacts associated with it. Are you sure you want to delete?"
        @confirm="deleteSelectedAssessment" @cancel="closePopUp"></delete-module>
    </div>
  </div>
</template>
<script src="./searchAssessment.controller.js"></script>
<style scoped src="./searchAssessment.style.css"></style>