export default class ClientModel {
    constructor() {
        this.id = null;
        this.clientName = null;
        this.region = null;
        this.contactPerson = null;
        this.email = null;
        this.clientDescription = null;
        this.industry = null;
    }

}