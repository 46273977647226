
class sortingService {
    constructor() {
        this.models = {};

    }
    getSortColumn(column, sortOrder, responseList) {
        responseList.sort((a, b) => {
            let aValue = a[column] ? a[column].toString().toLowerCase() : '';
            let bValue = b[column] ? b[column].toString().toLowerCase() : '';
            let result = 0;
            if (aValue > bValue) {
                result = 1;
            } else if (aValue < bValue) {
                result = -1;
            }
            else {
                result = 0;
            }
            return sortOrder === 'ascending' ? result : -result;
        });
        console.log(responseList);
        return responseList;
    }

}
export default new sortingService();




