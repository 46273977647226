import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import SearchAssessment from '@/app/AssessementDetails/SearchAssessment.vue';
import sharedService from '@/app/services/shared.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import Paginate from "vuejs-paginate-next";
import frameworkService from '../FrameworkDetails/framework.service';
import { sharedVariable } from '../services/sharedData.js';
import statusService from '../services/status.service';
import PopUpModule from "../shared/custompopup/PopUpModule.vue";
import { showToast, toast } from '../shared/toast/toast.controller.js';

export default {
    name: "createFramework",
    components: {
        FooterComponent,
        HeaderComponent,
        SearchAssessment,
        BreadcrumbComponent,
        SideNav,
        paginate: Paginate,
        ToastComponent,
        PopUpModule,
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");
        sharedVariable.value = window.location.pathname;
        return {
            toastState: toast,
            loggedInRole,
        };
    },

    data() {
        return {
            page: 10,
            showSaveButton: true,
            showFirstSearchBox: false,
            showSecondSearchBox: false,
            clientList: [],
            frameworkList: [],
            frameworkId: '',
            frameworkTypes: ["Usecase", "BRD", "FRD"],
            ClientModel: {},
            FrameworkModel: {
                clientId: [],
                status: 'Draft',
            },
            type: ['category', 'subCategory', 'qc'],
            FrameworkRecord: {},
            createframeworkFlag: false,
            clientError: "",
            navigationClick: "Home",
            breadcrumbStyles: {},
            showBreadcrumbs: false,
            showPopUpModule: false,
            showModuleStyles: {},
            showToolTipDelete: false,
            errors: {},
            categoryLineHeight: [],
            subCategoryLineHeight: [],
            qcLineHeight: [],
            enableAddCategory: false,
            validToSave: false,
            isCatActive: true,
            isSubCatActive: true,
            isQcActive: false,
            isCategoryActive: false,
            isSubCategoryActive: false,
            isQuestionCriteriaActive: false,
            category: [],
            showFrameworkBox: false,
            showFrameworkRecords: false,
            addCategoryErrorMessage: '',
            addSubCategoryButton: false,
            textareaContent: '',
            tooltipStyle: {},
            showDropdown: false,
            userInput: "",
            isRejectedPopup: false,
            toggleLabel: "Expand All",
            toggleFlag: false
        };
    },

    created() {
        this.breadcrumbStyles = {
            width: "83.75%",
        };
        //this.$store.commit("clearHistory");
        //  this.$store.commit("addToHistory", this.$route);
        this.frameworkId = this.$route.params.frameworkId;
        this.$store.commit("addToHistory", this.$route);
        if (this.frameworkId) {
            this.routePath = "/editFramework";
            this.FrameworkModel = sharedService.getData("framework.model");
            this.editFrameworkAPI(this.frameworkId);
            //this.editAssessment = true;
        }
        else {
            this.showFirstSearchBox = true;
        }
        this.onLoadClientName();

        this.$store.commit("addToHistory", this.$route);
    },
    mounted() {
        document.addEventListener("click", this.closeDropdown);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.closeDropdown);
        this.$store.commit("removeFromHistory", this.routePath);
    },
    methods: {

        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },

        closeDropdown(event) {
            if (!this.$el.contains(event.target)) {
                this.showDropdown = false;
            }
        },

        clearError(item, field) {
            switch (field) {
                case "name":
                    item.errorMsg = "";
                    break;
                case "weight":
                    item.weightErrorMsg = "";
                    break;
                case "evaluate":
                    item.evaluateErrorMsg = "";
                    break;
                case "guideline":
                    item.guidelineErrorMsg = "";
                    break;
                default:
                    break;
            }
        },

        tooltipStyle() {
            return {
                border: '2px solid green',
                borderRadius: '5px',
                padding: '8px',
                width: '200px',
                height: '300px',
            }
        },
        createNewFramework() {
            this.$router.push("/createFramework");
        },
        closePopUp() {
            this.showPopUpModule = false
            this.showModuleStyles = {
                opacity: "1",
            };
        },
        getSelectedClientNames() {
            return this.clientList.filter(client => this.FrameworkModel.clientId.includes(client.id)).map(client => client.clientName).join(",");
        },
        getTruncatedClientsNames() {
            const fullNames = this.getSelectedClientNames();
            if (fullNames.length > 30) {
                return fullNames.slice(0, 30) + "...";
            }
            return fullNames || "Select Clients";
        },
        checkTextareaLength() {
            console.log(this.$nextTick)
            let descriptionCharacterLength = this.FrameworkModel.frameworkDescription
            if (descriptionCharacterLength === undefined) {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 250) {
                    this.errors.frameworkDescription = 'Please Enter valid Description less than 250 characters';
                } else {
                    this.errors.frameworkDescription = ''
                }
            })
        },

        getStatus(status) {
            return statusService.getStatus(status);
        },

        frameworkNameValidation() {
            if (this.FrameworkModel.name == undefined) {
                this.errors.frameworkName = "Please enter the Framework name";
            } else {
                this.errors.frameworkName = "";
            }
        },
        frameworkTypeValidation() {
            if (this.FrameworkModel.type == undefined) {
                this.errors.frameworkName = " ";
                this.errors.frameworkType = "Please select Framework type";
            } else {
                this.errors.frameworkType = "";
            }
        },

        frameworkClientValidation() {
            if (this.FrameworkModel.clientId == undefined || this.FrameworkModel.clientId.length == 0) {
                this.errors.frameworkType = "";
                this.errors.clientId = "Please select Clients";
            } else {
                this.errors.clientId = "";
            }
        },

        createFramework() {

            this.frameworkValidation();
            if (this.validToSave) {
                this.createframeworkFlag = true;
                this.createFrameworkAPI();
            }

        },

        updateFramework() {

            this.frameworkValidation();
            if (this.validToSave) {
                this.createframeworkFlag = false;
                this.createFrameworkAPI();
            }
        },

        frameworkValidation() {
            if (this.FrameworkModel.name == undefined) {
                this.frameworkNameValidation();
            } else if (this.FrameworkModel.type == undefined) {
                this.frameworkTypeValidation();
            }
            else if (this.FrameworkModel.clientId == undefined || this.FrameworkModel.clientId.length == 0) {
                this.frameworkClientValidation();
            }
            else {
                if (this.FrameworkModel.status == "Draft") {
                    this.FrameworkModel.status = "draft";
                }
                this.validToSave = true;
            }

            event.preventDefault();
        },

        popupConfirm(action) {
            if (action == 'delete') {
                this.deleteFrameworkRecordApi();
            }
            if (action == 'submit') {
                this.submitFramework();
            }

        },

        reFilterClick() {
            this.showFirstSearchBox = true;
            this.showSecondSearchBox = false;
            this.showSaveButton = false;
            event.preventDefault();
        },
        addNewCategory(createdRecord) {
            /* this.isCategoryActive = !this.isCategoryActive */
            if (this.showFrameworkRecords) {
                this.category.push({
                    subCategory: [],
                    type: 'category',
                    _id: createdRecord._id,
                    expanded: true,
                    active: true,
                    weight: createdRecord.weight
                });
                this.isCategoryActive = true
                this.addSubCategoryButton = true
                if (this.isCategoryActive == true) {
                    this.addCategoryErrorMessage = "";

                } else {
                    this.validationMessageStyle = {
                        marginLeft: '8%'
                    }
                    this.addCategoryErrorMessage = "Expand the section to add a new Category"
                }
                this.showFrameworkRecords = false;
            }
            if (!this.createframeworkFlag) {
                showToast("New Category added successfully", "success", 'validationsuccess');
            }
        },
        addNewSubCategory(catIndex, createdRecord) {
            this.category[catIndex].subCategory.push({
                qc: [],
                _id: createdRecord._id,
                expanded: true,
                active: true,
                weight: 0
            });
            showToast("New Subcategory added successfully", "success", 'validationsuccess');
        },
        addNewQuestionCriteria(catIndex, subCatIndex, createdRecord) {
            /* this.isQuestionCriteriaActive = !this.isQuestionCriteriaActive */
            // if (this.isQuestionCriteriaActive == false) {
            //     this.isQuestionCriteriaActive = true
            // }

            this.category[catIndex].subCategory[subCatIndex].qc.push({
                _id: createdRecord._id,
                expanded: true,
                active: true,
                weight: 0
            });
            showToast("New Quality Criteria added successfully", "success", 'validationsuccess');
        },

        scrollToTop() {

            window.scrollTo(0, 0);
        },


        toggleClick() {
            this.toggleFlag = !this.toggleFlag;
            if (this.toggleFlag) {
                this.toggleLabel = "Collapse All";
                this.toggleAll(this.toggleFlag);
            }
            else {
                this.toggleLabel = "Expand All";
                this.toggleAll(this.toggleFlag);
            }
        },
        toggleAll(flag) {
            for (let index = 0; index < this.category.length; index++) {
                this.category[index].expanded = flag;
                this.category[index].active = flag;
                this.isCatActive = false;
                this.catIndex = index;
                if (this.category[index].subCategory.length > 0) {
                    setTimeout(() => this.dynamicCategoryListHeight(index), 100);
                    setTimeout(() => {
                        this.$refs.subCategoryHeight.forEach((_, index) => {
                            this.dynamicSubCatListHeight(index)
                        }, 50)
                    })
                }
                for (let subCatIndex = 0; subCatIndex < this.category[index].subCategory.length; subCatIndex++) {
                    this.category[index].subCategory[subCatIndex].expanded = flag;
                    this.category[index].subCategory[subCatIndex].active = flag;
                    this.isSubCategoryActive = false;
                    setTimeout(() => this.dynamicSubCatListHeight(subCatIndex), 100);
                    setTimeout(() => {
                        this.$refs.qcHeight.forEach((_, subCatIndex) => {
                            this.dynamicQcListHeight(subCatIndex)
                        }, 50)
                    })
                }
            }
        },


        toggleCategory(item, index, flag) {
            if (item.expanded === undefined) {
                item.expanded = true;
                item.active = true;
                this.isCatActive = false;
            } else if (item.expanded === true) {
                item.expanded = false;
                item.active = false;
                this.isCatActive = flag;
            } else if (item.expanded === false) {
                item.expanded = true;
                item.active = true;
                this.isCatActive = flag;
            }
            // this.active = !this.active
            // console.log("INDEX FOR CATEGORY", this.category[index])
            // this.isQuestionCriteriaActive = false
            this.catIndex = index;
            if (item.subCategory.length > 0) {
                setTimeout(() => this.dynamicCategoryListHeight(index), 100);
                setTimeout(() => {
                    this.$refs.subCategoryHeight.forEach((_, index) => {
                        this.dynamicSubCatListHeight(index)
                    }, 50)
                })
            }
        },
        toggleSubCategory(subCategoryitem, subCatindex, flag) {
            if (subCategoryitem.expanded === undefined) {
                subCategoryitem.expanded = true;
                subCategoryitem.active = true;
                this.isSubCategoryActive = false;
            } else if (subCategoryitem.expanded === true) {
                subCategoryitem.expanded = false;
                subCategoryitem.active = false;
                this.isSubCategoryActive = flag;
            } else if (subCategoryitem.expanded === false) {
                subCategoryitem.expanded = true;
                subCategoryitem.active = true;
                this.isSubCategoryActive = flag;
            }
            setTimeout(() => this.dynamicSubCatListHeight(subCatindex), 100);
            // setTimeout(() => {
            //     this.$refs.qcHeight.forEach((_, subCatindex) => {
            //         this.dynamicQcListHeight(subCatindex)
            //     }, 50)
            // })
        },
        dynamicSubCatListHeight(index) {
            if (this.$refs.subCategoryHeight && this.$refs.subCategoryHeight[index]) {
                const divSubCategoryElement = this.$refs.subCategoryHeight[index];
                this.subCategoryLineHeight[index] = divSubCategoryElement.clientHeight + 30
                console.log(this.subCategoryLineHeight[index])
                this.dynamicCategoryListHeight(this.catIndex)
            } else {
                console.error("subCategoryLineHeight or the specified index is not defined.");
            }

        },
        dynamicCategoryListHeight(index) {
            const divCategoryElement = this.$refs.categoryHeight[index]
            if (divCategoryElement) {
                this.categoryLineHeight[index] = divCategoryElement.clientHeight + 35
                console.log(this.categoryLineHeight[index])
            }

        },
        dynamicQcListHeight(index) {
            const divQcElement = this.$refs.qcHeight[index]
            if (divQcElement) {
                this.qcLineHeight[index] = divQcElement.clientHeight + 35
                this.questionListLinkEnd = {
                    margin: '10px 0px 0px 15px'
                }
                console.log(this.qcLineHeight[index])
            }

        },
        async onLoadClientName() {
            assessmentService.fetchClient().then(response => {
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.clientList = response.data.data;
                } else {
                    showToast("Failed to fetch Clients", "error", 'validationerror');
                }
            });
        },

        async createFrameworkAPI() {
            const response = await frameworkService.createFramework(this.FrameworkModel);
            try {
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.FrameworkModel.name = response.data.data.name;
                    this.FrameworkModel._id = response.data.data._id;
                    this.FrameworkModel.type = response.data.data.type;
                    this.FrameworkModel.status = this.getStatus(response.data.status);
                    this.FrameworkModel.description = response.data.data.description;
                    this.FrameworkModel.clientId = response.data.data.clientId;
                    sharedService.setData("framework.model", this.FrameworkModel);
                    this.frameworkId = this.FrameworkModel._id;
                    this.showFirstSearchBox = false;
                    this.showFrameworkBox = true;
                    this.showSecondSearchBox = true;
                    this.enableAddCategory = true;
                    if (this.createframeworkFlag) {
                        showToast("Framework created successfully", "success", 'validationsuccess');
                        this.addCategory(0, null, this.type[0], this.FrameworkModel._id)

                    }
                    else {
                        showToast("Framework updated successfully", "success", 'validationsuccess');
                    }
                } else {
                    showToast('Failed to save framework', 'error', 'validationerror');
                    return false;
                }
            }
            catch (err) {
                // Handle errors
                showToast('Failed to save framework', 'error', 'validationerror');
                console.error('error in controller--', err);
                return false;
            }

        },
        addCategory(catIndex, subCatIndex, type, parentId) {
            let validToCreate = true;
            if (this.category.length > 0) {
                this.category.totalCatWeight = 0
                for (let cat of this.category) {
                    this.category.totalCatWeight = this.category.totalCatWeight + cat.weight;
                }
                if (this.category.totalCatWeight >= 100) {
                    this.category.catErrorMsg = " Category total weight cannot exceed 100";
                    validToCreate = false;
                } else {
                    this.category.catErrorMsg = " ";
                }

            }
            this.createFrameworkRecordAPI(catIndex, subCatIndex, type, parentId, validToCreate);
            if (this.category.length > 0) {
                setTimeout(() => this.dynamicCategoryListHeight(catIndex), 100);
            }

        },
        addSubCategory(catIndex, subCatIndex, type, parentId) {
            let validToCreate = true;
            let cat = this.category[catIndex];
            if (cat.subCategory.length > 0) {
                cat.totalSubCatWeight = 0
                for (let subCat of cat.subCategory) {
                    cat.totalSubCatWeight = cat.totalSubCatWeight + subCat.weight;
                }
                if (cat.totalSubCatWeight >= 100) {
                    cat.subCatErrorMsg = "Sub Category total weight cannot exceed 100";
                    validToCreate = false;
                } else {
                    cat.subCatErrorMsg = "";
                }

            }
            this.createFrameworkRecordAPI(catIndex, subCatIndex, type, parentId, validToCreate);
            this.dynamicSubCatListHeight(subCatIndex);
        },
        addQc(catIndex, subCatIndex, type, parentId) {
            let validToCreate = true;
            let subCat = this.category[catIndex].subCategory[subCatIndex];
            if (subCat.qc.length > 0) {
                subCat.totalQcWeight = 0
                for (let qc of subCat.qc) {
                    subCat.totalQcWeight = subCat.totalQcWeight + qc.weight;
                }
                if (subCat.totalQcWeight >= 100) {
                    subCat.qcErrorMsg = "Quality Criteria total weight cannot exceed 100";
                    validToCreate = false;
                } else {
                    subCat.qcErrorMsg = "";
                }
            }
            this.createFrameworkRecordAPI(catIndex, subCatIndex, type, parentId, validToCreate);
        },

        async createFrameworkRecordAPI(catIndex, subCatIndex, type, parentId, validToCreate) {
            if (validToCreate) {
                this.FrameworkRecord.type = type
                this.FrameworkRecord.parentId = parentId;
                this.FrameworkRecord.weight = 0;
                this.FrameworkRecord.frameworkId = this.FrameworkModel._id;
                const response = await frameworkService.createFrameworkRecord(this.FrameworkRecord);
                try {
                    console.log("response in controller--", response);
                    if (response.data.header.responseMessage === "SUCCESS") {
                        this.showFrameworkRecords = true;
                        if (type == 'category') {
                            this.addNewCategory(response.data.data)
                            //this.dynamicCategoryListHeight(catIndex);
                        }
                        if (type == 'subCategory') {
                            this.addNewSubCategory(catIndex, response.data.data);
                            this.catIndex = catIndex;
                            this.dynamicSubCatListHeight(subCatIndex)
                        }
                        if (type == 'qc') {
                            this.addNewQuestionCriteria(catIndex, subCatIndex, response.data.data)
                            this.catIndex = catIndex;
                            this.dynamicSubCatListHeight(subCatIndex)
                        }

                    } else {
                        showToast('Failed to Create framework record', 'error', 'validationerror');
                    }
                }
                catch (err) {
                    // Handle errors
                    showToast('Failed to Create framework record', 'error', 'validationerror');
                    console.error('error in controller--', err);
                }
            }

        },
        async editFrameworkAPI(frameworkId) {
            const response = await frameworkService.editFramework(frameworkId);
            try {
                console.log("response in controller--", response);
                if (response.data.data.category.length > 0) {
                    this.category = response.data.data.category;
                    if (!this.FrameworkModel) {
                        this.FrameworkModel = {};
                    }
                    this.FrameworkModel.name = response.data.name;
                    this.FrameworkModel._id = response.data.currentId;
                    this.FrameworkModel.type = response.data.type;
                    this.FrameworkModel.status = this.getStatus(response.data.status);
                    this.FrameworkModel.description = response.data.description;
                    this.FrameworkModel.clientId = response.data.clientId;
                    this.showFirstSearchBox = false;
                    this.showSecondSearchBox = true;
                    this.showFrameworkBox = true;
                    this.enableAddCategory = true;
                    this.addSubCategoryButton = true;
                    sharedService.setData("framework.model", this.FrameworkModel);
                    this.toggleFlag = true;
                    this.toggleLabel = "Collapse All"
                    this.toggleAll(this.toggleFlag);

                } else {
                    showToast('Failed to fetch framework', 'error', 'validationerror');
                }

                // this.isSubCategoryActive = true;
                /* this.active= true; */
            }
            catch (err) {
                // Handle errors
                console.error('error in controller--', err);
            }

        },
        async updateFrameworkStatus(status) {
            const response = await frameworkService.updateFrameworkStatus(this.frameworkId, status);
            try {
                if (response.data.header.responseMessage === "SUCCESS") {
                    if (status === "submitted") {
                        showToast("Framework Submitted successfully", "success", 'validationsuccess');
                    }
                } else {
                    showToast('Failed to Submit framework ', 'error', 'validationerror');
                }
            }
            catch (err) {
                // Handle errors
                console.error('error in controller--', err);
            }

        },
        deleteSelectedFrameworkRecord(id, type, cIndex, scIndex) {
            if (type == 'category' && this.category.length == 1) {
                showToast("Framework should have at least one catogery", 'error', 'validationerror');
            } else {
                this.scrollToTop();
                this.showPopUpModule = !this.showPopUpModule;
                this.showModuleStyles = {
                    opacity: "0.3",
                    id: id,
                    type: type,
                    catIndex: cIndex,
                    subCatIndex: scIndex,
                    popupAction: "delete",
                    headerMessage: "Delete Framework Record Action",
                    dynamicMessage: "This action will delete the selected Framework record and all the associated record with it. Are you sure you want to delete",
                };
            }
        },

        async deleteFrameworkRecordApi() {
            this.saveForLater(false);
            const response = await frameworkService.deleteFrameworkRecord(this.showModuleStyles.id, this.showModuleStyles.type);
            try {
                //if (response.data.header.responseMessage === "SUCCESS")
                var types = this.showModuleStyles.type;
                if (response.data.data === "SUCCESS") {
                    console.log(response.data.data);
                    this.closePopUp();
                    if (types == 'category') {
                        showToast("Selected Category deleted", "success", "validationsuccess");
                    }
                    if (types == 'subCategory') {
                        showToast("Selected Subcategory deleted", "success", "validationsuccess");
                    }
                    if (types == 'qc') {
                        showToast("Selected Quility Criteria deleted", "success", "validationsuccess");
                    }
                    if (this.FrameworkModel.status === 'submitted' || this.FrameworkModel.status === 'Submitted') {
                        this.updateFrameworkStatus("inprogress");
                    }
                    this.editFrameworkAPI(this.frameworkId);
                } else {
                    showToast("Failed to Delete Assessment", 'error', 'validationerror');
                    this.closePopUp();
                }
            }
            catch (err) {
                // Handle errors
                console.error('error in controller--', err);
            }
        },
        async saveAllFrameworkDetails(saveAllFrameworkData, toastFlag) {
            const response = await frameworkService.saveAllFrameworkDetails(saveAllFrameworkData);
            try {
                if (response.data.header.responseMessage === "SUCCESS") {
                    if (toastFlag) {
                        showToast("Framework Details saved successfully", "success", 'validationsuccess');
                    }
                    this.scrollToTop();
                    // this.editFramework(this.frameworkId);
                    if (this.FrameworkModel.status == 'draft' || this.FrameworkModel.status == 'Draft') {
                        this.updateFrameworkStatus('inprogress');
                    }

                } else {
                    showToast('Failed to Create framework record', 'error', 'validationerror');
                }
            }
            catch (err) {
                // Handle errors
                console.error('error in controller--', err);
            }
        },
        closeFrameworkScreen() {
            this.$router.push("/frameworkList");
        },

        saveForLater(toastFlag) {
            var validationFailed = false;
            var saveAllFrameworkData = [];
            var frameworkData = {}
            var frameworkSubCatData = {};
            var frameworkQcData = {};
            for (let category of this.category) {
                frameworkData = {};
                frameworkData._id = category._id;
                frameworkData.name = category.name;
                frameworkData.weight = category.weight;
                frameworkData.description = category.description
                frameworkData.type = this.type[0];
                frameworkData.parentId = this.FrameworkModel._id;
                frameworkData.frameworkId = this.FrameworkModel._id;

                //category weight validation
                if (category.weight <= 0 || category.weight > 100) {
                    category.weightErrorMsg = "category weight must be between 1 and 100";
                    validationFailed = true;
                } else {
                    category.weightErrorMsg = "";

                }

                saveAllFrameworkData.push(frameworkData);
                if (category.subCategory.length > 0) {
                    for (let subCat of category.subCategory) {
                        frameworkSubCatData = {}
                        frameworkSubCatData._id = subCat._id;
                        frameworkSubCatData.name = subCat.name;
                        frameworkSubCatData.weight = subCat.weight;
                        frameworkSubCatData.description = subCat.description
                        frameworkSubCatData.type = 'subCategory';
                        frameworkSubCatData.parentId = category._id;
                        frameworkSubCatData.frameworkId = this.FrameworkModel._id;

                        //subcat weight validation
                        if (subCat.weight <= 0 || subCat.weight > 100) {
                            subCat.weightErrorMsg = "sub category weight must be between 1 and 100";
                            validationFailed = true;
                        } else {
                            subCat.weightErrorMsg = "";
                        }
                        saveAllFrameworkData.push(frameworkSubCatData);

                        if (subCat.qc.length > 0) {
                            for (let question of subCat.qc) {
                                frameworkQcData = {}
                                frameworkQcData._id = question._id;
                                frameworkQcData.name = question.name; //quality creteria
                                frameworkQcData.weight = question.weight;
                                frameworkQcData.evaluate = question.evaluate;
                                frameworkQcData.guideline = question.guideline;
                                frameworkQcData.type = 'qc'; //category = qc
                                frameworkQcData.parentId = subCat._id;
                                frameworkQcData.frameworkId = this.FrameworkModel._id;

                                //qc weight validation
                                if (question.weight <= 0 || question.weight > 100) {
                                    question.weightErrorMsg = "Quality Criteria weight must be between 1 and 100";
                                    validationFailed = true;
                                } else {
                                    question.weightErrorMsg = "";
                                }
                                saveAllFrameworkData.push(frameworkQcData);
                            }
                        }
                    }
                }
            }
            if (validationFailed) {
                showToast("validation failed. please correct the error", "error", "validationerror");
                return;
            }
            this.saveAllFrameworkDetails(saveAllFrameworkData, toastFlag);
        },
        submitFramework() {

            this.updateFrameworkStatus('submitted');
            this.closePopUp();
        },

        submitFrameworkValidation(toastFlag) {
            this.saveForLater(toastFlag);
            let validToSubmit = {};
            this.catTotalWeight = 0;
            var validationFailed = false;
            this.category.forEach(cat => {
                this.catTotalWeight = this.catTotalWeight + cat.weight;
                cat.totalSubCatWeight = 0;
                // Category Name Validation
                if (!cat.name || cat.name.trim() === "") {
                    cat.errorMsg = "Category name is required.";
                    validationFailed = true;
                } else {
                    cat.errorMsg = "";
                }

                cat.subCategory.forEach(subCat => {
                    cat.totalSubCatWeight = cat.totalSubCatWeight + subCat.weight;
                    subCat.totalQcWeight = 0;

                    // subcategory Name Validation
                    if (!subCat.name || subCat.name.trim() === "") {
                        subCat.errorMsg = "Sub Category name is required.";
                        validationFailed = true;
                    } else {
                        subCat.errorMsg = "";
                    }

                    subCat.qc.forEach(question => {
                        subCat.totalQcWeight = subCat.totalQcWeight + question.weight;

                        // qc Name Validation
                        if (!question.name || question.name.trim() === "") {
                            question.errorMsg = "Quality Criteria name is required.";
                            validationFailed = true;
                        } else {
                            question.errorMsg = "";
                        }


                        // evaluate quality Validation
                        if (!question.evaluate || question.evaluate.trim() === "") {
                            question.evaluateErrorMsg = "Evaluate Quality is required.";
                            validationFailed = true;
                        } else {
                            question.evaluateErrorMsg = "";
                        }

                        // scoring guidelines Validation
                        if (!question.guideline || question.guideline.trim() === "") {
                            question.guidelineErrorMsg = "Scoring criteria is required.";
                            validationFailed = true;
                        } else {
                            question.guidelineErrorMsg = "";
                        }

                    });

                    if ((cat.totalSubCatWeight > 100)) {
                        this.throwSaveLaterError = true
                        validToSubmit.catScore = false;
                        cat.subCatErrorMsg = "Sub Category total weight cannot exceed 100";

                    } else if ((cat.totalSubCatWeight < 100)) {
                        validToSubmit.catScore = false;
                        cat.subCatErrorMsg = "Sub Category total weight should be equal to 100";
                    }
                    else {
                        cat.subCatErrorMsg = "";
                        if (cat.totalSubCatWeight == 100) {
                            validToSubmit.catScore = true;
                        }

                    }

                    if ((subCat.totalQcWeight > 100)) {
                        this.throwSaveLaterError = true
                        validToSubmit.subCatScore = false;
                        subCat.qcErrorMsg = "Quality Criteria total weight cannot exceed 100";
                    } else if ((subCat.totalQcWeight < 100)) {
                        validToSubmit.subCatScore = false;
                        subCat.qcErrorMsg = "Quality Criteria total weight should be equal to 100";
                    }
                    else {
                        subCat.qcErrorMsg = "";
                        if (subCat.totalQcWeight == 100) {
                            validToSubmit.subCatScore = true;
                        }
                    }

                });
                if ((this.catTotalWeight > 100)) {
                    this.throwSaveLaterError = true
                    validToSubmit.qcScore = false;
                    this.category.catErrorMsg = "Category  weight cannot exceed 100";
                } else if ((this.catTotalWeight < 100)) {
                    validToSubmit.qcScore = false;
                    this.category.catErrorMsg = "Category total weight should be equal to 100";
                }
                else {
                    this.category.catErrorMsg = "";
                    if (this.catTotalWeight == 100) {
                        validToSubmit.qcScore = true;
                    }
                }

            });
            if (validToSubmit.catScore && validToSubmit.subCatScore && validToSubmit.qcScore && !validationFailed) {
                this.showPopUpModule = !this.showPopUpModule;
                this.showModuleStyles = {
                    opacity: "0.3",
                    popupAction: "submit",
                    dynamicMessage: "This action will Submit the selected Framework. Are you sure want to proceed",
                    headerMessage: "Submit Framework Action",

                };
            }
            else {
                if (validationFailed) {
                    showToast("validation failed. please correct the errors", "error", "validationerror");
                }
                else {
                    showToast("validation failed. please correct the errors", "error", "validationerror");
                }
            }

        },

    },
};