import apiEndpoint from '@/app/services/uri-constants.js';
import axios from '../shared/interceptor.js';
import { showToast } from "../shared/toast/toast.controller.js";


export default {

    async fetchArtifactScoreList(artifactId,assessorId) {
        return axios.get(`${apiEndpoint.fetchArtifactScoreList}` +
            artifactId + '&assessorId=' + assessorId)
           .catch(function(error) {
               if (error.code == 'ERR_BAD_RESPONSE') {
                   showToast('Failed to fetch the Dashboard Please contact administrator', 'error', 'validationerror');
               } else {
                   showToast('Failed to fetch the Artifact Dashboard ', 'error', 'validationerror');
               }
           });

    },


}