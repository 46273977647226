<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type"
    :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0">
        <div class="signIn"></div>
        <div class="row mb-0 d-flex flex-column justify-content-center align-items-center createProjectBox">
          <div class="row p-0 d-flex justify-content-start align-items-center mb-3" :style="breadcrumbStyles">
            <BreadcrumbComponent />
          </div>
          <div class="headingTextBox d-flex align-items-center me-5 mt-4">Create Project</div>
          <div class="whiteBox">
            <div class="d-flex flex-column justify-content-between">
              <div class="d-flex justify-content-end align-items-right mandatoryField">
                <span class="mandatoryFieldforForm">* &nbsp;</span> - Mandatory Fields
              </div>
              <form class="row g-3 p-0">
                <div class="row d-flex justify-content-start align-items-center mt-2 p-0 margin150">
                  <div class="col-4 ">
                    <label for="clientName" class="form-label createProjectLabel">Client Name</label>
                    <div class="projectPlaceHolder">
                      <input v-model="ClientModel.clientName" name="projectName" type="text" readonly
                        class="fontSize150" />
                    </div>
                  </div>
                  <div class="col-4">
                    <label for="projectName" class="form-label createProjectLabel">Project Name</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="createProjectDisplayField d-flex">
                      <input v-model="ProjectModel.projectName" name="projectName" type="text" class="fontSize150"
                        placeholder="Project Name" @change="projectValidation" />
                    </div>
                    <div v-if="errors.project" class="validationMessage">{{ errors.project }}</div>
                  </div>
                  <div class="col-4">
                    <label for="sbu" class="form-label createProjectLabel">SBU</label><span class="mandatoryFieldInput"> *
                    </span>
                    <div class="createProjectDisplayField d-flex">
                      <input v-model="ProjectModel.sbu" name="sbu" type="text" class="fontSize150" placeholder="sbu"
                        @change="sbuValidation" />
                    </div>
                    <div v-if="errors.sbu" class="validationMessage">{{ errors.sbu }}</div>
                  </div>
                </div>
                <div class="row d-flex justify-content-start align-items-center mt-5 p-0 margin150">
                  <div class="col-4">
                    <label for="status" class="form-label createProjectLabel">Status</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="dropdownSelect">
                      <select v-model="ProjectModel.isActive" id="isActive" class="dynamicDropdown">
                        <option selected value="Y">Active</option>
                        <option value="N">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-4">
                    <label for="projectDescription" class="form-label createProjectLabel">Description</label><br>
                    <textarea v-model="ProjectModel.projectDescription" type="text" class="descriptionField"
                      placeholder="A brief description of the Project" @keypress="checkTextareaLength"
                      maxlength="100"></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div class="row d-flex justify-content-end p-0 buttonsAlignmentResponsive">
                <button type="submit" class="btn btn-primary saveLaterButton float-end ms-3"
                  v-on:click="createProjectClick()">
                  Save
                </button>
                <input type="submit" value="Cancel" class="btn btn-primary nextButton float-end ms-3"
                  style="width: 10% !important; " v-on:click="cancelClick()">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./createProject.controller.js"></script>
<style scoped src="./createProject.style.css"></style>