import { ref } from "vue";
export default class AssessmentModel {
    constructor() {
        this.id = null;
        this.assessmentDescription = null;
        this.assessmentName = null;
        this.assessmentStatus = "draft";
        this.assessmentStartDate = ref(new Date());
        this.projectId = null;
    }
}