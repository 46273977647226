import { createStore } from 'vuex'

export default createStore({
    state: {
        historyPaths: [],
    },
    mutations: {
        addToHistory(state, routeObj) {
            var found=false;
            for(let i = state.historyPaths.length - 1; i >= 0; i--) {
                if(state.historyPaths[i].path == routeObj.path) {
                   found=true;
                   break;
                }
            }
            if(!found) {
                state.historyPaths.push(routeObj);
            }
        },
        removeFromHistory(state, path) {
            var routeObjToRemove=null;
            for(let i = state.historyPaths.length - 1; i >= 0; i--) {
                if(state.historyPaths[i].path == path) {
                    routeObjToRemove=state.historyPaths[i];
                    break;
                }
            }
            state.historyPaths.splice(state.historyPaths.indexOf(routeObjToRemove), 1);
        },
        clearHistory(state) {
            state.historyPaths = [];
        }
    },
});