<template>
  <!--   <div class="side-nav">
    <div class="side-nav-content">
            <div><router-link to="/">dashboard</router-link></div>
      <div><router-link to="/">Assessment</router-link></div>
      <div><router-link to="/">Manage</router-link></div>
      <div><router-link to="/">Setup</router-link></div>
      <div><router-link to="/">Report</router-link></div>
      <div><router-link to="/">User</router-link></div>
    </div>
  </div> -->
  <div class="menu d-flex justify-content-center align-items-center">
    <span class="hamburgerIcon"></span>
  </div>
  <div class="sidenav d-flex justify-content-center">
    <div class="sidenavContent d-flex flex-column justify-content-center align-items-center">
      <div v-if="loggedInRole==='frameworkAdmin'" class="sidnavIcons d-flex justify-content-center align-items-center">
        <div class="clientIcon" @click="clickClient()">
          <div class="sideNavHalo d-flex justify-content-center align-items-center"></div>

          <span class="sideNavText  d-flex justify-content-center align-items-center">Client</span>
          <div class="triangleLeft"></div>
        </div>
      </div>
      <div v-if="loggedInRole==='frameworkAdmin'"  class="sidnavIcons d-flex justify-content-center align-items-center">
        <div class="frameworkIcon" @click="clickFramework()">
          <div class="sideNavHalo d-flex justify-content-center align-items-center"></div>
          <span class="sideNavText">Framework</span>

          <div class="triangleLeft"></div>
        </div>
      </div>
      <div v-if="loggedInRole==='frameworkAdmin'" class="sidnavIcons d-flex justify-content-center align-items-center">
        <div class="userIcon" @click="clickUser()">
          <div class="sideNavHalo d-flex justify-content-center align-items-center"></div>
          <span class="sideNavText  d-flex justify-content-center align-items-center">User</span>

          <div class="triangleLeft"></div>
        </div>
      </div>
      <div  class="sidnavIcons d-flex justify-content-center align-items-center">
        <div class="assessmentIcon" @click="clickAssesment()">
          <div class="sideNavHalo d-flex justify-content-center align-items-center"></div>
          <span class="sideNavText">Assessment</span>

          <div class="triangleLeft"></div>
        </div>
      </div>
      <div  class="sidnavIcons d-flex justify-content-center align-items-center">
        <div class="dashboardIcon" @click="clickDashboard()">
          <div class="sideNavHalo d-flex justify-content-center align-items-center"></div>
          <span class="sideNavText">Dashboard</span>

          <div class="triangleLeft"></div>
        </div>
      </div>
    </div>   
  </div> 
</template>

<script src="./sidenav.controller.js"></script>
<style scoped
src="./sidenav.style.css"></style>











