import apiEndpoint from '@/app/services/uri-constants.js';
import axios from '@/axios';
import * as CryptoJS from 'crypto-js';

export default {

      encryptPassword(password) {
        var encryptPassword = CryptoJS.SHA256(password);
        return encryptPassword.toString(CryptoJS.enc.Hex);
    },

    async authenticate(User) {
       return axios.post(`${apiEndpoint.authenticate}`, User)
       .catch(function (error) {
         throw error;
       });
       
    },
    async refreshToken(accessToken) {
      return await axios.get(`${apiEndpoint.refreshToken}` + accessToken)
      .catch(function (error) {
         throw error;
       });
    },
    async forgotPassword(emailId) {
      return axios.get(`${apiEndpoint.forgotPassword}`+ emailId)
      .catch(function (error) {
         throw error;
       });
    },
    async changePassword(resetPasswordModel) {
      return axios.post(`${apiEndpoint.changePassword}`, resetPasswordModel)
      .catch(function (error) {
         throw error;
       });
    }
};