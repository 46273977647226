<template>
    <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent>
    <div class="container-fluid p-0">
        <div class="row login-bg m-0">
            <div class="leftContainer col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                <div class="flexContainer mt-5 ml-5">
                    <div class="logo"></div>
                    <div class="nttLogo"></div>
                </div>
                <div class="graphicImage ml-5"></div>
                <div class="copyRights ml-5">
                    <span>©2024 NTT DATA, Inc. All Rights Reserved.</span>
                </div>
            </div>
            <div  class="rightContainer d-flex justify-content-end col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0 m-0" :style="popUpModuleStyles">
                <div v-if="showforgotPassword" class="signIn d-flex justify-content-center align-items-center">
                    <div class="container p-0 m-0 d-flex justify-content-center align-items-center loginContainer">
                        <div
                            class="col-sm-5 col-md-9 col-lg-7 col-xl-5 offset-4 offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4">
                            <form  @submit="forgotPassword" novalidate>
                                <div class="d-flex flex-column justify-content-center align-items-center resetSignInHeader">
                                    <span>Reset Password</span>
                                    <span class="resetPasswordContent mt-2">To reset your password, enter the email address you
                                        use to sign in</span>
                                </div>
                                <div class="d-flex userDetails">
                                    <div class="emailIcon"></div>
                                    <div class="form-outline d-flex flex-column justify-content-start align-items-start" style="width:85% ;margin-bottom: 5em;">
                    <input type="email" id="emailId" v-model="emailId" class="form-control form-control-lg"
                      placeholder=" " @blur="validatingEmail(emailId)" />
                    <label class="form-label m-0 userName" for="emailId" required>Email Id</label>
                    <div v-if="errors.emailId" class="validationMessage">{{ errors.emailId }}</div>
                  </div>     
                                </div>
                  <div class="mt-6 d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary btn-lg btn-block signInButton" style="font-size: 17px !important;">SEND PASSWORD LINK</button>
                                </div>
                                <div class="d-flex justify-content-end align-items-center referenceLink">
                                    <!-- <a href="#!">Terms of Use</a> -->
                                    <a href="#!" @click="signIn()"><span class="resetLink">Already have an account :</span>Sign In</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div  v-if="!showforgotPassword"  class="signIn d-flex justify-content-center align-items-center">
           <div class="container p-0 m-0 d-flex justify-content-center align-items-center loginContainer">
            <div class="col-sm-6 col-md-10 col-lg-8 col-xl-6 offset-4 offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4">
              <form @submit="changePassword" novalidate id="changePasswordForm">
                <div class="d-flex justify-content-center align-items-center signInHeader mb-5">
                  <span>Change Password</span>
                </div>
                <div class="d-flex userDetails">
                <div class="passwordIcon"></div>
                <div class="form-outline d-flex justify-content-start align-items-center mb-5" style="width:85% ;">
                  <input type="password"  id="newPassword"  v-model="ResetPasswordModel.newPassword" class="form-control form-control-lg"
                    placeholder=" "  @blur="validatingPasswords()" @focus="clearPassword('newPassword')" />
                  <label class="form-label m-0 newPassword" for="newPassword">New Password</label> 
                </div>
                <div v-if="errors.newPassword" class="errorMessage">{{ errors.newPassword }}</div>                 
                </div>
                <div class="d-flex userDetails">
                  <div class="confirmPasswordIcon"></div>
                <div class="form-outline d-flex justify-content-start align-items-center mb-5" style="width:85% ;">
                <input type="password"  id="confirmPassword"  v-model="ResetPasswordModel.confirmPassword" class="form-control form-control-lg"
                @blur="checkConfirmPassword()" @focus="clearPassword('confirmPassword')" placeholder=" " />
                <label class="form-label m-0 confirmPassword" for="confirmPassword">Confirm Password</label>
                </div>
                <div v-if="errors.confirmPassword" class="errorMessage">{{ errors.confirmPassword }}</div>
                </div>
              <div class="mt-5 d-flex justify-content-center">
              <button type="submit" class="btn btn-primary btn-lg btn-block signInButton">Change Password</button>
            </div>
              <div class="d-flex justify-content-between align-items-center referenceLink">
                <a href="#!" >Terms of Use</a>
                <a @click="signIn()" class="customStyles" >Back to Login</a>
              </div>
              </form>
            </div>
          </div>
                </div>
            </div>
        </div>
        <InfoPopup v-if="showPassworkLink" :headerMessage="this.popUpModuleStyles.headerMessage" :message="this.popUpModuleStyles.message" showHyperLink="true" @ok="closePopUp" :hyperLink="this.popUpModuleStyles.link"></InfoPopup>

    </div>
</template>
<script src="./resetpassword.controller.js"></script>
<style scoped
  src="./../login.style.css"></style>





  