import assessmentService from "@/app/AssessementDetails/assessment.service.js";
import CreateAssessment from "@/app/AssessementDetails/CreateAssessment.vue";
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from "@/app/shared/footer/FooterComponent.vue";
import HeaderComponent from "@/app/shared/header/HeaderComponent.vue";
import SideNav from "@/app/shared/side-navigation/SideNav.vue";
import ToastComponent from "@/app/shared/toast/ToastComponent.vue";
import { ref } from "vue";
import Datepicker from "vue3-datepicker";
import sharedService from "../services/shared.service.js";
import statusService from "../services/status.service.js";
import { showToast, toast } from "../shared/toast/toast.controller.js";


export default {
    name: "createassessment",
    components: {
        FooterComponent,
        HeaderComponent,
        CreateAssessment,
        SideNav,
        Datepicker,
        ToastComponent,
        BreadcrumbComponent,
    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");

        return {
            toastState: toast,
            loggedInRole,
        };
    },
    data() {
        return {
            picked: null,
            clientList: [],
            projectList: [],

            assessmentId: "",
            errors: {
                client: "",
                project: "",
                assessmentName: "",
                assessmentDescription: "",
            },
            AssessmentModel: {

            },
            inputReadOnly: {},
            ClientModel: {
                // id: ''y
            },
            clientId: "",
            ProjectModel: {},
            createAssessmentResponse: {},
            editAssessment: false,
            trimmedDate: "",
            breadcrumbStyles: {},
            saveLater: false,
            routePath: "",
            fontResize: '19px',
            serverRequestOn: false

        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    created() {
        this.inputReadOnly = {
            color: "#767676 !important",
        };
        this.breadcrumbStyles = {
            width: "91.5%",
        };
        this.assessmentId = this.$route.params.assessmentId;
        this.$store.commit("addToHistory", this.$route);
        if (this.assessmentId) {
            if (this.loggedInRole == "frameworkAdmin") {
                this.routePath = "/editAssessment";
            }
            if (this.loggedInRole == "assessor" || this.loggedInRole == "reviewer") {
                this.routePath = "/viewAssessment";
            }
            this.AssessmentModel = sharedService.getData("assessment.model");
            if (!this.AssessmentModel) {
                //   this.$store.commit('clearHistory');
                this.fetchAssessment(this.assessmentId);
            } else {
                //   this.$store.commit('addToHistory', this.$route.fullPath);
                const dateObj = new Date(this.AssessmentModel.assessmentStartDate);
                this.AssessmentModel.assessmentStartDate = dateObj;
                if (this.loggedInRole === "frameworkAdmin") {
                    this.ClientModel = sharedService.getData("client.model");
                    this.ProjectModel = sharedService.getData("project.model");
                    if (!this.ProjectModel && !this.ClientModel) {
                        this.fetchClientAndproject();
                    }
                } else {
                    this.fetchClientAndproject();
                }
            }
            this.editAssessment = true;
        } else {
            this.routePath = "/createAssessment";
            this.AssessmentModel.assessmentStatus = "draft";
            this.AssessmentModel.assessmentStartDate = ref(new Date());
            this.onLoadClientName();
        }
    },
    methods: {
        /* nextClick(id, assessmentName) */
        handleResize() {
            if ((window.innerWidth > 1441) && (window.innerWidth <= 1600)) {
                this.fontResize = '16px';
            } else if ((window.innerWidth > 1025) && (window.innerWidth <= 1280)) {
                this.fontResize = '11px';
            } else {
                this.fontResize = '16px'
            }
        },
        getStatus(status) {
            this.status = statusService.getStatus(status)

        },

        assessmentArtifactList() {
            if (this.AssessmentModel.id) {
                this.$router.push({
                    name: "artifactList",
                    params: {
                        assessmentId: this.AssessmentModel.id,
                        assessmentName: this.AssessmentModel.assessmentName,
                    },
                });
            } else {
                showToast("Assessment is not created", "error", "validationerror");
            }
        },
        assessmentUserList() {
            if (this.AssessmentModel.id) {
                this.$router.push({ name: "assignUserList", params: { assessmentId: this.AssessmentModel.id } });
            } else {
                showToast("Assessment is not created", "error", "validationerror");
            }
        },
        saveLaterClick() {
            this.saveLater = true;
            this.createAssessmentClick();
        },
        viewClick() { },
        clearEmail() {
            event.preventDefault();
            this.$refs.calendarReset.focus();
        },
        clientValidation() {
            // this.ClientModel = {};
            if (this.ClientModel.id == undefined) {
                this.errors.client = "Please Select a Client";
            } else {
                this.errors.client = "";

                this.clientId = this.ClientModel.id;
                this.loadProjectByClient(this.clientId);
            }
        },
        projectValidation() {
            // this.validateProjectName = true;
            // this.validateClientName = true;
            if (!this.ProjectModel.id) {
                this.errors.project = "Please Select a project";
            } else {
                this.errors.project = "";
                this.AssessmentModel.projectId = this.ProjectModel.id;
                console.log(this.ProjectModel);
            }
        },
        assessmentNameValidation() {
            if (!this.AssessmentModel.assessmentName) {
                this.errors.assessmentName = "Please Enter valid Assessment Name";
            } else {
                this.errors.assessmentName = "";
            }
        },
        closeButton() {
            this.$router.push("/searchAssessment");
        },
        checkTextareaLength() {
            console.log(this.$nextTick)
            let descriptionCharacterLength = this.AssessmentModel.assessmentDescription
            if (descriptionCharacterLength === undefined) {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 100) {
                    this.errors.assessmentDescription = 'Please Enter valid Description less than 100 characters';
                } else {
                    this.errors.assessmentDescription = ''
                }
            })
        },
        createAssessmentClick() {
            console.log(this.AssessmentModel);
            if (this.ClientModel.id == undefined && !this.AssessmentModel.id) {
                this.clientValidation();
            } else if (this.ProjectModel.id == undefined && !this.AssessmentModel.projectId) {
                this.projectValidation();
            } else if (!this.AssessmentModel.assessmentName) {
                this.assessmentNameValidation();
            } else {
                this.createAssessment();
            }
            event.preventDefault();
        },
        async fetchClientAndproject() {
            assessmentService.fetchProjectById(this.AssessmentModel.projectId).then(projectResponse => {
                if (projectResponse.data.header.responseMessage === "SUCCESS") {
                    this.ProjectModel = projectResponse.data.data;
                    sharedService.setData("project.model", this.ProjectModel);
                    assessmentService.fetchClientById(projectResponse.data.data.clientId).then(clientResponse => {

                        if (clientResponse.data.header.responseMessage === "SUCCESS") {
                            this.ClientModel = clientResponse.data.data;
                            sharedService.setData("client.model", this.ClientModel);
                        } else {
                            showToast(
                                "Failed to fetch Client Details",
                                "error",
                                "validationerror"
                            );
                        }
                    });
                } else {
                    showToast(
                        "Failed to fetch Project Details",
                        "error",
                        "validationerror"
                    );
                }

            });
        },
        async fetchAssessment(assessmentId) {
            const response = await assessmentService.fetchAssessmentById(
                assessmentId
            );
            if (response.data.header.responseMessage === "SUCCESS") {
                this.AssessmentModel = response.data.data;
                const dateObj = new Date(this.AssessmentModel.assessmentStartDate);
                this.AssessmentModel.assessmentStartDate = dateObj;
                sharedService.setData("assessment.model", this.AssessmentModel);
                this.fetchClientAndproject();
            } else {
                showToast(
                    "Failed to fetch Assessment Details",
                    "error",
                    "validationerror"
                );
            }
        },

        async createAssessment() {
            this.serverRequestOn = true;
            const response = await assessmentService.createAssessmentAPI(
                this.AssessmentModel
            );
            this.serverRequestOn = false;
            //const response = await assessmentService.fetchClient();
            if (response.data.header.responseMessage === "SUCCESS") {
                this.createAssessmentResponse = response.data.data;
                this.AssessmentModel.id = this.createAssessmentResponse.id;
                sharedService.setData(
                    "assessment.model",
                    this.createAssessmentResponse
                );
                /* alert(this.saveLater); */
                if (this.saveLater != true) {
                    this.$router.push({
                        name: "artifactList",
                        params: { assessmentId: this.createAssessmentResponse.id },
                    });
                } else {
                    this.saveLater = false;
                    showToast("Assessment created successfully", "success", 'validationsuccess');
                }
            } else {
                showToast("Failed to Create Assessment", "error", 'validationerror');
            }
        },

        async onLoadClientName() {
            const response = await assessmentService.fetchClient();
            if (response.data.header.responseMessage === "SUCCESS") {
                this.clientList = response.data.data;
                this.inputReadOnly = {
                    outline: "none !important",
                    border: "none !important",
                    color: "#767676 !important",
                };
                console.log(this.clientList);
            } else {
                showToast("Failed to fetch Clients", "error", "validationerror");
            }
        },
        async loadProjectByClient(clientId) {
            const response = await assessmentService.fetchProjectByClientId(clientId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.projectList = response.data.data;
                this.inputReadOnly = {
                    outline: "none !important",
                    border: "none !important",
                    color: "#767676 !important",
                };
                console.log(this.projectList);
            } else {
                showToast("Failed to fetch Projects", "error", "validationerror");
            }
            this.errors.client = "";
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    }
};