<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent> 
   <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="popUpModuleStyles">
        <div class="signIn"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center assessmentScoreScreen">
          <div class="row p-0 d-flex justify-content-start align-items-center" :style="breadcrumbStyles">
                <BreadcrumbComponent />
            </div>
<!--             <ol class="breadcrumb" :style="breadcrumbStyles">
              <li @click="homeClick()">Home</li>
              <li class="active">Artifact Scoring</li>
            </ol> -->
          <div class="row mx-auto d-flex justify-content-center align-items-center ms-5 me-1 mb-5 mt-4" style="width: 95%">
            <div class="headingTextBox d-flex align-items-center ms-5">Assessment - {{assessmentName}}</div>
            <div class="whiteBox ms-5" id="whiteBoxOne">
              <!-- <div class="subHeadingText mt-3 mb-3"></div> -->
              <form class="row g-3 p-0">
                <div class="d-flex justify-content-between align-items-center mt-4 p-0">
                  <div class="d-flex justify-content-between align-items-center" v-if="this.loggedInRole == 'assessor'" style="width: 100%;">
                    <!-- <div class="col-4">
                      <label for="artifactsType" class="form-label createAssessmentLabel">Artifacts Type</label>
                      <div class="dropdownSelect">
                        <select v-model="ArtifactModel.artifactType" id="artifactType" class="dynamicDropdown" v-on:input="loadArtifactNameByType($event)" >
                          <option disabled selected value="undefined" class="dropdownOption">Select Artifact Type</option>
                          <option v-for="artifact in artifactTypes" :key="artifact.artifactType" :value="artifact" selected>
                            {{ artifact}}
                          </option>
                        </select>
                        <div v-if="error.artifactTypeError" class="validationMessage">
                          {{ error.artifactTypeError }}
                        </div>
                      </div>
                    </div> -->
                    <div class="d-flex justify-content-between assessmentScoringCol">
                      <div class="d-flex flex-column" style="width: 100%;">
                      <label for="artifactsName" class="form-label createAssessmentLabel">Artifact Name</label>
                      <div class="dropdownSelect">
                        <!-- <select v-model="ArtifactModel" id="artifactsName" class="dynamicDropdown"  v-on:input="setArtifactFlags(ArtifactModel)" :disabled="!ArtifactModel.artifactType" > -->
                          <select v-model="ArtifactModel" id="artifactsName" class="dynamicDropdown"   @change="setArtifactFlags(ArtifactModel)" >
                          <option disabled selected :value="{}" class="dropdownOption">Select Artifact Name</option>
                          <option v-for="artifact in artifactList" :key="artifact.id" :value="artifact" selected>
                            {{ artifact.artifactName }}
                          </option>
                        </select>
                        <div v-if="error.artifactNameError" class="validationMessage">
                          {{ error.artifactNameError }}
                        </div>
                      </div>
                      </div>
                      <div>
                        <span type="search" class="btn downloadIcon"  @mouseover="showToolTip(index,'download')" @mouseleave="hideToolTip(index,'download')" @click="downloadFile(ArtifactModel.artifactUrl)">
                          <div v-if="showToolTipIndex === index && activeTooltipType==='download'" class="downloadOption">
                            Download
                          </div>
                      </span>
                      </div>
                    </div>
                    <div class="assessmentScoringCol">
                          <label for="artifactType" class="form-label createAssessmentLabel">Artifact Type</label>
                          <div class="d-flex flex-column" >
                          <div class="scoringPlaceHolder">
                           <input v-model="this.ArtifactModel.artifactType" name="artifactType" type="text"  readonly class="fontSize150"/>
                          </div>
                          </div>
                    </div>
                    <div class="assessmentScoringCol">
                          <label for="artifactStatus" class="form-label createAssessmentLabel">Assessor Artifact Status</label>
                          <div class="d-flex flex-column" >
                          <div class="scoringPlaceHolder">
                           <input :class="getStatus(selectedAssessor.artifactStatus)" v-model="status" name="artifactStatus" type="text"  readonly class="fontSize150"/>
                          </div>
                          </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between align-items-center" v-if="this.loggedInRole == 'frameworkAdmin' || this.loggedInRole == 'reviewer'" style="width: 100%;">
                    <!-- <div class="col-3">
                      <label for="artifactsType" class="form-label createAssessmentLabel">Artifacts Type</label>
                      <div class="dropdownSelect">
                        <select v-model="ArtifactModel.artifactType" id="artifactType" class="dynamicDropdown" v-on:input="loadArtifactNameByType($event)" >
                          <option disabled selected value="undefined" class="dropdownOption">Select Artifact Type</option>
                          <option v-for="artifact in artifactTypes" :key="artifact.artifactType" :value="artifact" selected>
                            {{ artifact}}
                          </option>
                        </select>
                        <div v-if="error.artifactTypeError" class="validationMessage">
                          {{ error.artifactTypeError }}
                        </div>
                      </div>
                
                    </div> -->
                    <div class="d-flex justify-content-between assessmentScoringAdmin">
                      <div class="d-flex flex-column" style="width: 100%;">
                        <label for="artifactsName" class="form-label createAssessmentLabel">Artifact Name</label>
                      <div class="dropdownSelect">
                        <!-- <select v-model="ArtifactModel" id="artifactsName" class="dynamicDropdown"  v-on:input="setArtifactFlags(ArtifactModel)" :disabled="!ArtifactModel.artifactType" > -->
                          <select v-model="ArtifactModel" id="artifactsName" class="dynamicDropdown"   @change="setArtifactFlags(ArtifactModel)" >
                          <option disabled selected :value="{}" class="dropdownOption">Select Artifact Name</option>
                          <option v-for="artifact in artifactList" :key="artifact.id" :value="artifact" selected>
                            {{ artifact.artifactName }}
                          </option>
                        </select>
                        <div v-if="error.artifactNameError" class="validationMessage">
                          {{ error.artifactNameError }}
                        </div>
                      </div>
                      </div>
                      <div>
                        <span type="search" class="btn downloadIcon"  @mouseover="showToolTip(index,'download')" @mouseleave="hideToolTip(index,'download')" @click="downloadFile(ArtifactModel.artifactUrl)">
                        <div v-if="showToolTipIndex === index && activeTooltipType==='download'" class="downloadOption">
                          Download
                        </div>
                      </span>  
                      </div>                
                    </div>
                    <div class="assessmentScoringAdmin">
                          <label for="artifactType" class="form-label createAssessmentLabel">Artifact Type</label>
                          <div class="d-flex flex-column" >
                          <div class="scoringPlaceHolder">
                           <input v-model="this.ArtifactModel.artifactType" name="artifactType" placeholder="Select Artifact Type " type="text"  readonly class="fontSize150"/>
                          </div>
                          </div>
                    </div>
                    <div class="d-flex flex-column assessmentScoringAdmin">
                      <label for="assessor" class="form-label createAssessmentLabel">Assessor</label>
                      <div class="dropdownSelect">
                          <select v-model="selectedAssessor" id="assessorId" class="dynamicDropdown" @change="setSelectedAssessor(selectedAssessor)" >
                          <option disabled selected   :value="{}" class="dropdownOption">Select Assessor</option>
                          <option v-for="assessor in artifactAssessorList" :key="assessor.id" :value="assessor" >
                            {{ assessor.emailId }}
                          </option>
                        </select>                         
                        <div v-if="error.artifactAssessorError" class="validationMessage">
                          {{ error.artifactAssessorError }}
                        </div>
                      </div>
                    </div>
                    <div class="assessmentScoringAdmin">
                          <label for="artifactStatus" class="form-label createAssessmentLabel">Assessor Artifact Status</label>
                          <div class="d-flex flex-column" >
                          <div class="scoringPlaceHolder">
                            <input  :class="getStatus(selectedAssessor.artifactStatus)" v-model="status" name="artifactStatus" type="text"  readonly class="fontSize150"/>
                          </div>
                          </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="d-flex justify-content-end align-items-center">
                <div class="d-flex justify-content-end align-items-center" style="width: 15%;margin-top: 1.5em;">
                    
                    <button type="search" class="btn btn-primary searchButton" v-on:click="searchClick()">
                      Search
                    </button>
                </div>
              </div>
            </div>
            <div class="headingTextBox d-flex align-items-center ms-5 mt-5" v-if="enableQualityCriteria">
              <div class="d-flex justify-content-between align-items-center" style="width:100%">
                <span>Scoring Section </span>
                <!-- <span :class="artifactStatus(ArtifactModel.artifactStatus)">Artifact Status: {{ status }}</span> -->
            </div>
            </div>
            <div class="whiteBox ms-5 mb-5" style="padding: 1.5em 0.5em 2em 0em;" id="whiteBoxTwo" v-if="enableQualityCriteria">             
              <div class="d-flex justify-content-start align-items-start fontSize150">
                <ul class="mainCategoryList" style="min-width:55%">
                  <div class="d-flex flex-column"> 
                    <div v-if="overAllErrorMsg.length>0" class="validationScoreMessage" :style="validationMessageStyleForSubmit">
                      {{overAllErrorMsg}}
                    </div>
                    <li v-for="(category,index) in artifactScoreModel.data.category" :key="category.catId" class="subCategoryListItems" ref="categoryHeight">
                      <div class="d-flex align-items-center">
                        <div class="d-flex flex-column align-items-center">
                          <div @click="toggleCategory(category, index,!isActive)" :class="{'categoryMaximiseIcon':!category.active,'categoryMinimiseIcon':category.active}" >
                            <div class="categoryLinkLine" :style="{height:categoryLineHeight[index] + 'px'}">
                          </div>
                          </div>
                          <div class="d-flex flex-column align-items-center <!-- lineControl -->" v-for="category in artifactScoreModel.data.categoryList" :key="category.catId">
                          </div>
                        </div>
                        <div class="col-12 d-flex align-items-center ms-2">
                          <div class="col-4 d-flex align-items-center">
                            <span class="mainCategoryQuestionContent" id="activeCategory">
                              &nbsp; 
                              {{ category.name }}
                              &nbsp;
                            </span>
                          </div>
                          <div class="col-9 d-flex">
                            <ul class="col 12 subCategoryList">
                              <div class="d-flex  align-items-center">
                                <li v-for="subCategory in category.subCategory" :key="subCategory.id">
                                  <span class="subCategoryQuestionContent">
                                    &nbsp; {{ subCategory.name }} : {{ subCategory.qc.length }} 
                                  </span>
                                </li>
                                <div class="lineSeparator"></div>
                                <li>
                                  <span class="subCategoryQuestionContent">weight : {{ category.weight }}</span>
                                </li>
                              </div>
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <ul class="col-12 subCategoryList">
                          <div class="d-flex flex-column ms-3" v-if="category.expanded">
                            <li v-for="(subCategory,index) in category.subCategory" :key="subCategory.subCatId" style="margin-top: 2em;" ref="subCategoryHeight">
                              <div class="d-flex align-items-center mt-3">
                                <div @click="togglesSubCategory(subCategory,index,!isSubCategoryActive)" :class="{'subCategoryMaximiseIcon':!subCategory.active,'subCategoryMinimiseIcon':subCategory.active}">
                                  <div class="linkLine" :style="{height:subCategoryLineHeight[index] + 'px'}">
                                  </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                  <div class="d-flex align-items-center">
                                    <span class="questionsContent" style="padding-right: 2em;">
                                      &nbsp; {{ subCategory.name }}
                                    </span>
                                  </div> 
                                  <div class="lineSeparator">
                                  </div>
                                  <div class="d-flex align-items-center">
                                    <span class="questionsContent" style="padding-left: 2em;">
                                      Weight :{{ subCategory.weight }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <ul class="questionListItem" v-if="subCategory.expanded">
                                <div class="d-flex flex-column ms-4 mt-3">
                                  <li v-for="(qc,index) in subCategory.qc" :key="qc.id" style="margin-top: 1em;" ref="qcHeight">
                                    <div class="d-flex align-items-center">
                                      <div class="d-flex align-items-center">
                                        <div class="questionListIcon">
                                          <div class="qcLinkLine" :style="{height:qcLineHeight[index] + 'px'}">
                                          </div>
                                        </div>
                                        <span class="questionsContent">
                                          QC 0{{index+1}} :
                                        </span>
                                      </div>
                                      &nbsp;<span class="questionContentDisplay">{{ qc.name }}</span> 
                                    </div>
                                    <br>
                                    <div class="d-flex align-items-center ms-4">
                                      <span class="questionsContent">
                                        How to Evaluate Quality :
                                      </span>&nbsp;<span class="questionContentDisplay">{{ qc.evaluate }}</span> 
                                    </div>
                                    <br>
                                    <div class="d-flex align-items-center ms-4">
                                      <span class="questionsContent">
                                        Scoring Guidelines :
                                      </span>
                                      &nbsp; <span class="questionContentDisplay">{{ qc.guideline }}</span>
                                    </div>
                                    <br>
                                    <div class="d-flex justify-content-between align-items-baseline ms-4">
                                      <div class="d-flex align-items-center">
                                        <span class="questionsContent">
                                          Weight :
                                        </span>
                                        &nbsp;<span class="questionContentDisplay">{{ qc.weight }}</span> 
                                      </div>
                                      <div class="d-flex flex-column ms-4">
                                        <span class="questionsContent">
                                          Manual Score :
                                        </span>
                                        <input v-if="((loggedInRole==='assessor' && (this.selectedAssessor.artifactStatus==='draft' || this.selectedAssessor.artifactStatus==='inprogress' || this.selectedAssessor.artifactStatus==='rejected')) ||( loggedInRole==='frameworkAdmin' && this.selectedAssessor.artifactStatus==='approved'))" type="number" class="manualScore" v-model="qc.score" @blur="checkScore(qc)"> 
                                        <input v-else type="number" class="manualScoreReadOnly" v-model="qc.score" readonly>                                         
                                          <div v-if="!viewScore && qc.scoreNotValid" class="validationMessage" :style="validationMessageStyle">
                                            {{manualScoreError}}
                                          </div> 
                                      </div>
                                      <div class="d-flex flex-column ms-4">
                                        <span class="questionsContent">
                                          Assessor Observations :
                                        </span>
                                        <textarea v-if="(loggedInRole==='assessor' && (this.selectedAssessor.artifactStatus==='draft' || this.selectedAssessor.artifactStatus==='inprogress' || this.selectedAssessor.artifactStatus==='rejected'))" type="text" class="descriptionField" v-model="qc.assessorComment"
                                          placeholder="Type the Observation text here…"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" >
                                          </textarea>
                                          <textarea v-else type="text"  class="descriptionReadOnly" v-model="qc.assessorComment"
                                          placeholder="Type the Observation text here…"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>
                                          <div v-if="!viewScore && checkLength" class="validationMessage">
                                            {{inputTextError}}
                                          </div>
                                      </div>
                                      <div class="d-flex flex-column" style="padding-left: 2em;" v-if=" loggedInRole==='frameworkAdmin' || loggedInRole==='assessor' && (this.selectedAssessor.artifactStatus!='inprogress' || this.selectedAssessor.artifactStatus!='draft')">
                                        <span class="questionsContent" >
                                          Reviewer Observations :
                                        </span>
                                        <textarea type="text" class="descriptionReadOnly" v-model="qc.reviewerComment"
                                          placeholder="Type the Observation text here"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>
                                      </div>  
                                      <!-- <div class="d-flex flex-column" style="padding-left: 2em;" v-if="(loggedInRole==='assessor' && (this.selectedAssessor.artifactStatus!='rejected' || this.selectedAssessor.artifactStatus!='draft' || this.selectedAssessor.artifactStatus!='inprogress'))">
                                        <span class="questionsContent" >
                                          Reviewer Observations :
                                        </span>
                                        <textarea type="text" class="descriptionReadOnly" v-model="qc.reviewerComment"
                                          placeholder="Type the Observation text here"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>
                                      </div>   -->
                                      <div class="d-flex flex-column" style="padding-left: 2em;" v-if="(loggedInRole==='reviewer' && (this.selectedAssessor.artifactStatus!='draft' || this.selectedAssessor.artifactStatus!='inprogress' ))">
                                        <span class="questionsContent" >
                                          Reviewer Observations :
                                        </span>
                                          <textarea v-if="(this.selectedAssessor.artifactStatus==='submitted' && loggedInRole=='reviewer')" type="text"  class="descriptionField" v-model="qc.reviewerComment"
                                          placeholder="Type the Observation text here…" 
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100">
                                          </textarea>
                                          <textarea v-else type="text" class="descriptionReadOnly" v-model="qc.reviewerComment"
                                          placeholder="Type the Observation text here"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>
                                          <div v-if="!viewScore && checkLength" class="validationMessage">
                                            {{inputTextError}}
                                          </div>
                                      </div>

                                    </div>
                                  </li>
                                  <div class="linkLineEnd" :style="questionListLinkEnd"></div>
                                </div>
                              </ul>

                            </li>
                            <div class="linkLineEnd">
                                      
                            </div>
                          </div>
                        </ul>
                      </div>
                    </li>
                    <div class="linkLineEnd">
                                      
                    </div>
                  </div>
                </ul>             
                 <div class="d-flex justify-content-end align-items-center" style="position: absolute;width: 88%;z-index:10"> 
                  <div class="d-flex justify-content-between align-items-center" >                    
                    <button type="search" class="btn btn-primary d-flex justify-content-between align-items-center saveLaterButton me-4" @click="toggleClick()">
                      <div class="fontSize150">{{ toggleLabel }}</div>
                    </button>               
                    <button type="search" class="btn btn-primary d-flex justify-content-between align-items-center saveLaterButton me-4" @click="artifactDashboard(ArtifactModel.id)">
                      <div class="dashboardIcon"></div>
                      <div class="fontSize150">Dashboard</div>
                    </button>
                </div>
                 </div>
              
              </div>
              <div v-if="loggedInRole==='frameworkAdmin'" class="d-flex justify-content-end align-items-end mt-5">
                  <button type="reset" class="btn btn-primary saveLaterButton me-4 fontSize150" v-on:click="closeScore()">
                    <div>Close</div>
                  </button>
                </div>

                <div v-if="loggedInRole==='assessor'" class="d-flex justify-content-end align-items-end mt-5">
                  <button type="reset" class="btn btn-primary saveLaterButton me-4" v-on:click="closeScore()">
                        <div class="fontSize150">Close</div>
                    </button>
                  <button type="reset" class="btn btn-primary saveLaterButton me-4" v-if="this.selectedAssessor.artifactStatus!='submitted' && this.selectedAssessor.artifactStatus!='approved'" v-on:click="saveForLater()">
                        <div class="fontSize150">Save For Later</div>
                    </button>
                    <button type="reset" class="btn btn-primary submitButton me-4" v-if="this.selectedAssessor.artifactStatus!='submitted' && this.selectedAssessor.artifactStatus!='approved'" v-on:click="submitForApproval()">
                        <div class="fontSize150">Submit for Approval</div>
                    </button>
                </div>

                <div v-if="loggedInRole==='reviewer'" class="d-flex justify-content-end align-items-end mt-5">
                  <button type="reset" class="btn btn-primary saveLaterButton buttonWithoutIcon me-4" v-on:click="closeScore()">
                        <div>Close</div>
                      </button>
                   <button type="reset"  v-if="this.selectedAssessor.artifactStatus=='submitted'" class="btn btn-primary saveLaterButton buttonWithoutIcon me-4" v-on:click="saveForLater()">
                        <div>Save For Later</div>
                  </button>
                  <button type="reset" v-if="this.selectedAssessor.artifactStatus=='submitted'" class="btn btn-primary d-flex align-items-center resetButton me-4" v-on:click="rejectApproval()">
                        <div class="rejectIcon"></div>
                        <div>Reject</div>
                    </button>
                    <button type="reset" v-if="this.selectedAssessor.artifactStatus=='submitted'" class="btn btn-primary d-flex align-items-center saveLaterButton me-4" v-on:click="artifactApproval()" >
                        <div class="approveIcon"></div>
                        <div>Approve</div>
                    </button>

                </div>
            </div>
          </div>
        </div>
      </div>
      
      <PopUpModule v-if="showPopUpModule" :headerMessage="popUpModuleStyles.headerMessage" :message="popUpModuleStyles.message"  @confirm="popUpConfirmButton(popUpModuleStyles.buttonAction)" @cancel="closePopUp"></PopUpModule>
      <PopUpModule v-if="showPopUpModuleReject" :headerMessage="popUpModuleStyles.headerMessage" :message="popUpModuleStyles.message" showTextArea="true" @confirm="rejectArtifact" @cancel="closePopUp"></PopUpModule>
      <InfoPopup v-if="showAlreadyRejPopUpModule && loggedInRole==='assessor'" headerMessage="Message" message="Artifact is Rejected by Reviewer. Please find the below comments" showTextArea="true" @ok="closePopUp" :staticMessage="this.selectedAssessor.overAllComments"></InfoPopup>
      <div :class="{'overlay':this.serverRequestOn}">
      </div>
      <div class="loading-bar-spinner" :class="{'spinner':this.serverRequestOn}">
      <div class="spinner-icon"></div>
                      </div>
    </div>
  </div>

  <!--  </div> -->
</template>
<script src="./AssessmentScoring.contoller.js"></script>
<style scoped src="./AssessmentScoring.style.css"></style>
  