<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type"
    :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0">
        <div class="signIn"></div>
        <div class="row mb-0 d-flex flex-column justify-content-center align-items-center createClientBox">
          <div class="row p-0 d-flex justify-content-start align-items-center mb-3" :style="breadcrumbStyles">
            <BreadcrumbComponent />
          </div>
          <div class="headingTextBox d-flex align-items-center me-5">Create Client</div>
          <div class="whiteBox">
            <div class="d-flex flex-column justify-content-between">
              <div class="d-flex justify-content-end align-items-right mandatoryField"><span
                  class="mandatoryFieldforForm">* &nbsp;</span> - Mandatory Fields</div>
              <form class="row g-3 p-0">
                <div class="row d-flex justify-content-start align-items-center mt-2 p-0 margin150">
                  <div class="col-4">
                    <label for="clientName" class="form-label createClientLabel">Client Name</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="createClientDisplayField d-flex">
                      <input v-model="ClientModel.clientName" name="clientName" type="text" placeholder="Client Name"
                        @change="clientValidation" />
                    </div>
                    <div v-if="errors.client" class="validationMessage">{{ errors.client }}</div>
                  </div>
                  <div class="col-4">
                    <label for="regionInput" class="form-label createClientLabel">Region</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="createClientDisplayField d-flex">
                      <input v-model="ClientModel.region" name="regionInput" type="text" placeholder="Region"
                        @change="regionValidation" />
                    </div>
                    <div v-if="errors.region" class="validationMessage">{{ errors.region }}</div>
                  </div>
                  <div class="col-4">
                    <label for="industryInput" class="form-label createClientLabel">Industry</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="createClientDisplayField d-flex">
                      <input v-model="ClientModel.industry" name="industry" type="text" placeholder="Industry"
                        @change="industryValidation" />
                    </div>
                    <div v-if="errors.industry" class="validationMessage">{{ errors.industry }}</div>
                  </div>
                </div>
                <div class="row d-flex justify-content-start align-items-center mt-5 p-0 margin150">
                  <div class="col-4">
                    <label for="Email" class="form-label createClientLabel">Email</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="createClientDisplayField d-flex">
                      <input v-model="ClientModel.email" name="email" type="text" placeholder="email"
                        @change="emailIdValidation" @blur="validatingEmail(ClientModel.email)" @focus="clearEmail()" />
                    </div>
                    <div v-if="errors.email" class="validationMessage">{{ errors.email }}</div>
                  </div>
                  <div class="col-4">
                    <label for="contactPerson" class="form-label createClientLabel">Contact Person</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="createClientDisplayField d-flex">
                      <input v-model="ClientModel.contactPerson" name="contactPerson" type="text"
                        placeholder="Contact Person" @change="contactPersonValidation" />
                    </div>
                    <div v-if="errors.contactPerson" class="validationMessage">{{ errors.contactPerson }}</div>
                  </div>
                  <div class="col-4">
                    <label for="status" class="form-label createClientLabel">Status</label>
                    <div class="dropdownSelect">
                      <select v-model="ClientModel.isActive" id="isActive" class="dynamicDropdown">
                        <option selected value="Y">Active</option>
                        <option value="N">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row d-flex mt-5 p-1">
                  <div class="col-4">
                    <label for="clientDescription" class="form-label createClientLabel">Description</label><br>
                    <textarea v-model="ClientModel.clientDescription" type="text" class="descriptionField"
                      placeholder="A brief description of the Client" @keypress="checkTextareaLength"
                      maxlength="100"></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div class="row d-flex justify-content-end p-0 buttonsAlignmentResponsive">
              <button type="submit" class="btn btn-primary saveLaterButton float-end ms-2 "
                v-on:click="createClientClick()">
                Save
              </button>
              <input type="submit" value="Cancel" class="btn btn-primary nextButton float-end ms-2"
                style="width: 10% !important; " v-on:click="cancelClick()">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./createClient.controller.js"></script>
<style scoped src="./createClient.style.css"></style>