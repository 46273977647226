<template>
  <nav class="breadcrumbs">
    <router-link :to="{ path: '/landingPage' }" class="breadcrumbHomeLink">Home</router-link>
    <template v-for="(histObj, index) in historyPaths" :key="index">
          <span v-if="histObj.path!='/landingPage'"> > </span>
          <router-link :to="{path: histObj.path}" v-if="histObj!=null && histObj.path!='/landingPage'" class="breadcumbsLink">{{ histObj.meta.breadcrumb }}</router-link>
    </template>
  </nav>
</template>
<script src="./breadcrumb.controller.js"></script>
<style scoped src="./breadcrumb.style.css"></style>