import axios from '../shared/interceptor.js';
import apiEndpoint from '@/app/services/uri-constants.js';

export default {

    async projectListByClientId(clientId) {
        const response = await axios.get(`${apiEndpoint.projectListByClientId}` + clientId)
        return response;
    },
    async createProjectAPI(client) {
        const response = await axios.post(`${apiEndpoint.createProject}`, client)
        return response;
    },
    async fetchProjectById(projectId) {
        const response = await axios.get(`${apiEndpoint.fetchProject}` +
            projectId)
        return response;
    },
    async deleteProject(projectId) {
        const response = await axios.delete(`${apiEndpoint.deleteProject}` + projectId)
        return response;
    }

}