<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0">
        <div class="signIn"></div>
          <div class="row mb-0 d-flex flex-column justify-content-center align-items-center createAssessmentBox">
              <div class="row p-0 d-flex justify-content-start align-items-center mb-3" :style="breadcrumbStyles">
                <BreadcrumbComponent />
              </div>
            <div v-if="loggedInRole==='frameworkAdmin'" class="d-flex flex-column justify-content-between align-items-center z-3 position-relative">
              <div class="navigationImageBox d-flex justify-content-between  ms-2 me-2">
                <div class="d-flex flex-column justify-content-end align-items-center imagesForNavigation">
                  <div class="assessImage">
                    <span class="dottedCircle"></span>
                  </div>
                  <div class="navigationImageText">Assessment</div>
                </div>
                <span class="horizontalLine mt-5 mb-5"></span>
                <div class="d-flex flex-column justify-content-end align-items-center imagesForNavigation" v-on:click="assessmentArtifactList()">
                  <div class="artifactImage" >
                  </div>
                  <div class="navigationImageText">Artifacts</div>
                </div>
                <span class="horizontalLine mt-5 mb-5"></span>
                <div class="d-flex flex-column justify-content-end align-items-center imagesForNavigation" v-on:click="assessmentUserList()">
                  <div class="userImage">
                  </div>
                  <div class="navigationImageText">Assign User</div>
                </div>
              </div>
            </div>
            <div v-if="!editAssessment" class="headingTextBox d-flex align-items-center me-5 mt-4">Create Assessment</div>
            <div v-if="editAssessment && loggedInRole==='frameworkAdmin'" class="headingTextBox d-flex align-items-center me-5 mt-4">Edit Assessment - {{ AssessmentModel?.assessmentName }}</div>
            <div v-if="loggedInRole==='assessor' || loggedInRole == 'reviewer'" class="headingTextBox align-items-center d-flex me-5 mt-3">Assessment Details - {{ AssessmentModel?.assessmentName }}</div>
          <div class="whiteBox">
            <div class="d-flex justify-content-end align-items-right mandatoryField"><span class="mandatoryFieldforForm">* &nbsp;</span> - Mandatory Fields</div>
            <form class="row g-3 p-0">
              <div v-if="!editAssessment" class="row d-flex justify-content-start align-items-center mt-2 p-0 margin150">
                <div class="col-4">
                 <label for="clientName" class="form-label createAssessmentLabel">Client Name</label><span class="mandatoryFieldInput"> * </span>
                  <div class="dropdownSelect">
                    <select v-model="ClientModel" id="clientName" class="dynamicDropdown"  @change="clientValidation">
                      <option disabled selected :value="{}" class="dropdownOption">Client Name</option>
                      <option v-for="client in clientList" :key="client.id" :value="client" selected>
                        {{ client.clientName }}
                      </option>
                    </select>
                    <div v-if="errors.client" class="validationMessage">{{ errors.client }}</div>
                  </div>
                </div>
                <div class="col-4">
                  <label for="projectName" class="form-label createAssessmentLabel">Project Name</label><span class="mandatoryFieldInput"> * </span>
                  <div class="dropdownSelect">
                    <select v-model="ProjectModel" id="projectName" class="dynamicDropdown" :disabled="!this.ClientModel.clientName" @change="projectValidation" >
                      <option disabled selected :value="{}" class="dropdownOption">Project Name</option>
                      <option v-for="project in projectList" :key="project.id" :value="project" selected>
                        {{ project.projectName }}
                      </option>
                    </select>
                    <div v-if="errors.project" class="validationMessage">{{ errors.project }}</div>
                  </div>
                </div>
                <div class="col-4">
                 <label for="assessStartDateInput" class="form-label createAssessmentLabel">Assessment Start Date</label>
                  <div  v-if="loggedInRole==='frameworkAdmin'" class="dateSelected">
                    <!-- For DD-MM-YY format add type="date" after format attribute-->
                    <datepicker
                    v-model="AssessmentModel.assessmentStartDate" 
                      placeholder="Select Start Date"
                      format="dd-MM-YYYY"
                      @click="clearEmail()"
                      :typeable="true"
                      :multiple="true"
                      ref="calendarReset"
                      class="inputDatepicker"
                      style="
                        border: none !important;
                        background-color: transparent;
                        border-radius: 0 !important;
                        border-bottom: 1px solid #000000 !important;
                        color: #000000 !important;
                        width: 62% !important;
                        display: flex !important;
                        text-align: left !important;
                        cursor: pointer;
                        outline: none;
                        font: normal normal normal 1vw/4vh Ubuntu !important;
                        font-weight: 100 !important;
                      "
                      :style="{fontSize: fontResize}"
                    />
                    <span class="calendarImage"></span>
                    <!-- <button class="clearCalendar" v-on:click="clearCalendarValue()" ></button> -->
                  </div>
                  <div  v-if="loggedInRole==='assessor' || loggedInRole == 'reviewer'" class="dateSelected">
                    <datepicker
                    v-model="AssessmentModel.assessmentStartDate" disabled
                      placeholder="Select Start Date"
                      format="dd-MM-YYYY"
                      style="
                        border: none !important;
                        background-color: transparent;
                        border-radius: 0 !important;
                        border-bottom: 1px solid #000000 !important;
                        color: #000000 !important;
                        width: 62% !important;
                        display: flex !important;
                        text-align: left !important;
                        cursor: pointer;
                        outline: none;
                        font: normal normal normal 11px/20px Ubuntu !important;
                      "
                      :style="{fontSize: fontResize}"
                    />
             
                  </div>

                </div>
              </div>
              <div v-if="editAssessment && ClientModel!=null && ProjectModel!=null" class="row d-flex justify-content-start align-items-center mt-2 p-0 margin150">
                <div class="col-4">
                  <label for="clientName" class="form-label createAssessmentLabel">Client Name</label>
                  <div class="createAssessmentDisplayField d-flex">
                    <input v-model="ClientModel.clientName" name="clientName" type="text"  readonly class="fontSize150" :style="inputReadOnly"/>
                  </div>
                </div>
                <div class="col-4">
                  <label for="projectName" class="form-label createAssessmentLabel">Project Name</label>
                  <div class="createAssessmentDisplayField d-flex">
                    <input v-model="ProjectModel.projectName" name="projectname" type="text"  readonly class="fontSize150" :style="inputReadOnly"/>
                  </div>
                </div>
                <div class="col-4">
                 <label for="assessStartDateInput" class="form-label createAssessmentLabel">Assessment Start Date</label>
                  <div  v-if="loggedInRole==='frameworkAdmin'" class="dateSelected">
                    <!-- For DD-MM-YY format add type="date" after format attribute-->
                    <datepicker
                    v-model="AssessmentModel.assessmentStartDate" 
                      placeholder="Select Start Date"
                      format="dd-MM-YYYY"
                      @click="clearEmail()"
                      :typeable="true"
                      :multiple="true"
                      ref="calendarReset"
                      class="inputDatepicker"
                      style="
                        border: none !important;
                        background-color: transparent;
                        border-radius: 0 !important;
                        border-bottom: 1px solid #000000 !important;
                        color: #000000 !important;
                        width: 62% !important;
                        display: flex !important;
                        text-align: left !important;
                        cursor: pointer;
                        outline: none;
                      "
                      :style="{fontSize: fontResize}"
                    />
                    <span class="calendarImage"></span>
                    <!-- <button class="clearCalendar" v-on:click="clearCalendarValue()" ></button> -->
                  </div>
                  <div  v-if="loggedInRole==='assessor' || loggedInRole == 'reviewer'" class="dateSelected">
                    <datepicker
                    v-model="AssessmentModel.assessmentStartDate" disabled
                      placeholder="Select Start Date"
                      format="dd-MM-YYYY"
                      style="
                        border: none !important;
                        background-color: transparent;
                        border-radius: 0 !important;
                        border-bottom: 1px solid #000000 !important;
                        color: #000000 !important;
                        width: 62% !important;
                        display: flex !important;
                        text-align: left !important;
                        cursor: pointer;
                        outline: none;
                      "
                      :style="{fontSize: fontResize}"
                    />
             
                  </div>

                </div>
             </div>
              <div class="row d-flex justify-content-between align-items-center mt-5 p-0 margin150" v-if="ClientModel!=null && ProjectModel!=null">
                <div class="col-4">
                  <label for="regionInput" class="form-label createAssessmentLabel">Region</label>
                  <div class="createAssessmentDisplayField d-flex">
                    <input v-model="ClientModel.region" name="region" type="text"  readonly class="fontSize150" placeholder="Client Region" :style="inputReadOnly"/>
                </div>
                </div>
                <div class="col-4">
                  <label for="industryInput" class="form-label createAssessmentLabel">Industry</label>
                  <div class="createAssessmentDisplayField d-flex">
                    <input v-model="ClientModel.industry" name="industry" type="text"  readonly class="fontSize150" placeholder="Client Industry" :style="inputReadOnly"/>
                </div>
                </div>
                <div class="col-4">
                  <label for="SBUInput" class="form-label createAssessmentLabel">SBU</label>
                  <div class="createAssessmentDisplayField d-flex">
                    <input v-model="ProjectModel.sbu" name="sbu" type="text"  readonly class="fontSize150" placeholder="Project SBU" :style="inputReadOnly" >
                </div>
              </div>
              </div>
              <div class="row d-flex align-items-center mt-5 p-0 margin150">
                <div  class="col-4 d-flex flex-column">
                  <div>
                    <label for="assessNameInput" class="form-label createAssessmentLabel">Assessment Name</label><span class="mandatoryFieldInput"> * </span>
                  </div>
                  <div class="createAssessmentDisplayField d-flex">                 
                   <input v-if="loggedInRole==='frameworkAdmin'" type="text" autocomplete="off" id="assessmentName" name="assessName" v-model="AssessmentModel.assessmentName" @change="assessmentNameValidation" placeholder="Enter Assessment Name">
                   <input v-if="loggedInRole==='assessor' || loggedInRole == 'reviewer'" type="text" readonly autocomplete="off" id="assessmentName" name="assessName" v-model="AssessmentModel.assessmentName" :style="inputReadOnly" placeholder="Enter Assessment Name">
                  </div>
                  <div v-if="errors.assessmentName" class="validationMessage" style="margin-top: 3.65%;">{{ errors.assessmentName }}</div>
                </div>
                <div  class="col-4">
                  <label for="assessStatusInput" class="form-label createAssessmentLabel">Assessment Status</label>
                  <div class="createAssessmentDisplayField d-flex">
                    <input :class="getStatus(AssessmentModel.assessmentStatus)" v-model="status" name="assessmentStatus" type="text"  readonly class="fontSize150" placeholder="Assessment Status" :style="inputReadOnly"/>
                </div>
              </div>               
              </div>
              <div  class="row d-flex mt-5 p-0 margin150">
                <div class="col-4">
                  <label for="assessStatusInput" class="form-label createAssessmentLabel">Description</label><br>
                   <textarea v-if="loggedInRole==='frameworkAdmin'" v-model="AssessmentModel.assessmentDescription" type="text" class="descriptionField" placeholder="A brief description of the Assessment" @keypress="checkTextareaLength"
                   maxlength="100"></textarea>
                  <div v-if="errors.assessmentDescription" class="validationMessage">{{errors.assessmentDescription}} </div>
                 <textarea v-if="loggedInRole==='assessor' || loggedInRole == 'reviewer'" v-model="AssessmentModel.assessmentDescription" type="text" readonly class="descriptionField" placeholder="A brief description of the Assessment" :style="inputReadOnly"
                   ></textarea>
                </div>
              </div>
              <div v-if="loggedInRole==='frameworkAdmin'" class="row d-flex justify-content-end mt-3 p-0 buttonsAlignmentResponsive">
               
                <button type="submit" class="btn btn-primary saveLaterButton float-end ms-3" v-on:click="saveLaterClick()">
                  Save for Later
                </button>
                <input type="submit" value="Next" class="btn btn-primary nextButton float-end ms-3" v-on:click="createAssessmentClick()" style="width: 7% !important;">
                  
                <!-- </button> -->
              </div>

              <div v-if="loggedInRole==='assessor' || loggedInRole == 'reviewer'" class="row d-flex justify-content-end p-0 closeButtonStyle">
                <button type="submit" class="btn btn-primary saveLaterButton" v-on:click="closeButton()">
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div :class="{'overlay':this.serverRequestOn}">
      </div>
      <div class="loading-bar-spinner" :class="{'spinner':this.serverRequestOn}">
         <div class="spinner-icon"></div>
      </div>

  </div>
  
</template>
<script src="./createAssessment.controller.js"></script>
<style scoped src="./createAssessment.style.css"></style>
