import sortingService from '@/app/services/sort.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import Paginate from "vuejs-paginate-next";
import frameworkService from '../FrameworkDetails/framework.service';
import { paginationService } from '../services/pagination.service.js';
import { default as SharedService, default as sharedService } from '../services/shared.service.js';
import { sharedVariable } from '../services/sharedData.js';
import PopUpModule from "../shared/custompopup/PopUpModule.vue";
import InfoPopup from "../shared/infopopup/InfoPopup.vue";
import { showToast, toast } from '../shared/toast/toast.controller.js';
import statusService from '../services/status.service';

/* import {confirmDelete} from "../shared/custompopup/popupModule.controller.js" */
export default {
    name: "searchAssessment",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        SideNav,
        paginate: Paginate,
        ToastComponent,
        PopUpModule,
        InfoPopup
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const loggedInRole = SharedService.getDataFromBrowser("role");
        sharedVariable.value = window.location.pathname;
        return {
            toastState: toast,
            loggedInRole,
        };
    },

    created() {
        this.fetchFrameworkList();
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))');
        const largeScreenMedia = window.matchMedia('((min-width:1871px) and (max-width:1920px))')
        if (mediaQuery.matches) {
            this.breadcrumbStyles = {
                width: "81%",
            }
        }
        else if(largeScreenMedia.matches){
            this.breadcrumbStyles = {
                width: "83%",
            }
        }
         else {
            this.breadcrumbStyles = {
                width: "80.5%",
            };
        }
        // this.showEmptyTableContent = true
        //this.$store.commit("clearHistory");
        this.$store.commit("addToHistory", this.$route);
        this.showBreadcrumbs = true;

    },
    data() {
        return {
            page: 10,
            showDisplayContent: true,
            showDefaultContent: true,
            showFirstSearchBox: true,
            showSecondSearchBox: false,
            clientList: [],
            frameworkList: [],
            assessmentListforAssessor: [],
            userAssignedArtifactList: [],
            clientName: "",
            ClientModel: {},
            showCreateFrameworkButton: true,
            validateClientName: false,
            navigationClick: "Home",
            breadcrumbStyles: {},
            showBreadcrumbs: false,
            showPopUpModule: false,
            showModuleStyles: {},
            showEmptyTableContent: false,
            showFrameworkListTableContent: true,
            showToolTipIndex: null,
            activeTooltipType: null,
            showInfoPopUpModule: false,
            sortOrder: {
                frameworkName: "common",
                frameworkType: "common",
                status: "common"

            },
            frameworkListResponse: [],
            searchText: '',
            filteredFrameworks: [],
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            currentPage: 1,
            selectedPage: 1,
            rowsPerPage: 10,
            displayedFrameworkList: [],
            totalRecords: 0,
            totalPages: 0,
            paginationData: {},
        };
    },


    computed: {
        filteredFrameworkList() {
            if (!this.searchText) {
                return this.displayedFrameworkList;
            }
            const enteredText = this.searchText.toLowerCase();
            return this.displayedFrameworkList.filter(framework =>
                framework.name.toLowerCase().includes(enteredText)

            );
        }
    },

    methods: {

        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },
        truncateClientNames(clientName) {
            if (clientName.length > 25) {
                return clientName.slice(0, 24) + '...';
            }
            return clientName;
        },
        getStatus(status) {
            this.status = statusService.getStatus(status)
        },
        createNewFramework() {
            this.$router.push("/createFramework");
        },
        editFramework(framework) {
            sharedService.setData("framework.model", framework);
            if (framework.status === 'submitted') {
                this.$router.push({ name: "viewFramework", params: { frameworkId: framework._id } });

            }
            else {
                this.$router.push({ name: "editFramework", params: { frameworkId: framework._id } });
            }

        },
        popupConfirm(action) {
            if (action == 'delete') {
                this.deleteSelectedFramework();
            }
            if (action == 'clone') {
                this.cloneSelectedFramework();
            }

        },
        cloneFramework(framework) {

            this.showPopUpModule = true;
            this.showModuleStyles = {
                opacity: "0.3",
                id: framework._id,
                headerMessage: "Clone Framework Action",
                dynamicMessage: "This action will clone the selected Framework . Are you sure want to continue?",
                popupAction: "clone"
            };

        },
        deleteFramework(framework) {
            if (framework.isActive == 'N') {
                this.showPopUpModule = true;
                this.showModuleStyles = {
                    opacity: "0.3",
                    id: framework._id,
                    headerMessage: "Delete Framework Action",
                    dynamicMessage: "This action will delete the selected Framework and non reversible. Are you sure you want to delete?",
                    popupAction: "delete"
                };
            } else {
                this.showInfoPopUpModule = true;
                this.showModuleStyles = {
                    opacity: "0.3",
                };
            }
        },
        closePopUp() {
            this.showPopUpModule = false;
            this.showInfoPopUpModule = false;
            this.showModuleStyles = {
                opacity: "1",
            };
        },

        async fetchFrameworkList() {
            try {
                const response = await frameworkService.fetchFrameworkList();
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.frameworkListResponse = response.data.data;
                    this.frameworkList = [...this.frameworkListResponse];
                    if (this.frameworkList.length <= 0) {
                        this.showFrameworkListTableContent = false
                        this.showDefaultContent = true
                        this.showEmptyTableContent = true;
                    } else {
                        this.showFrameworkListTableContent = true
                        this.showDefaultContent = false
                        this.showEmptyTableContent = false;
                    }
                    this.updateDisplayedRecords();
                } else {
                    showToast("Failed to fetch Frameworks", "error", 'validationerror');
                }
            } catch (err) {
                console.error('error in controller--', err);

            }
        },


        filterFrameworks() {
            if (this.searchText) {
                this.filteredFrameworks = this.frameworkList.filter(framework =>
                    framework.name.toLowerCase().includes(this.searchText.toLowerCase())
                );
            } else {
                this.filteredFrameworks = this.frameworkList;
            }
        },

        deleteSelectedFramework() {
            frameworkService.deleteFramework(this.showModuleStyles.id).then(response => {
                //if (response.data.header.responseMessage === "SUCCESS")
                if (response.data.data === "SUCCESS") {
                    showToast("Framework Deleted Successfully", 'success', 'validationsuccess');
                    this.closePopUp();
                    this.fetchFrameworkList();
                } else {
                    showToast("Failed to Delete Framework", 'error', 'validationerror');
                    this.closePopUp();
                }
            })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                });

        },
        cloneSelectedFramework() {
            frameworkService.copyFramework(this.showModuleStyles.id).then(response => {
                if (response.status == 200) {
                    showToast("Framework cloned Successfully", 'success', 'validationsuccess');
                    this.closePopUp();
                    this.fetchFrameworkList();
                } else {
                    this.closePopUp();
                    showToast("Error in clone", 'error', 'validationerror');
                }
            })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                    showToast("Error in clone", 'error', 'validationerror');
                });

        },

        sortFramework(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = "common";
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedFrameworkList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedFrameworkList, currentPage, rowsPerPage)
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
            if (this.isSearchVisible) {
                this.searchText = '';

            }
        },
        updateDisplayedRecords(column) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            // use paginationservice to handle pagination logic
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.frameworkList
            );

            // set pagination data from the service
            this.displayedFrameworkList = this.paginationData.displayedRecords;

        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.frameworkList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },

    },
};