<template>
    <HeaderComponent />
    <FooterComponent />
    <SideNav />
     <div class="container-fluid p-0"> 
       <div class="row login-bg m-0">
        <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="deleteModuleStyles">
          <div class="bg-right"></div>
          <div class="mx-auto d-flex flex-column justify-content-start align-items-center searchProjectBox" :style="searchprojectList">
            <div class="d-flex justify-content-start align-items-center mb-3 me-5 breadcrumbsResponsive" :style="breadcrumbStyles">
                  <BreadcrumbComponent />
            </div>
            <div class="row mx-auto d-flex justify-content-center align-items-center ms-4 me-1 mt-2 projectList150" style="width: 95%;"> 
              <div class="d-flex justify-content-between align-items-center ms-5 p-0 projectListResponsive" style="width: 92%;">
                <div class="headingTextBoxTwo d-flex align-items-center">Project List -{{ ClientModel.clientName }}</div>
                <div  class="d-flex justify-content-between align-items-center" >
                <div  v-if="projectList.length > 0" class="searchApplication " style="z-index: 1;">
                <input v-if="isSearchVisible" v-model="searchText" type="text" name="searchText"  placeholder="Search by ProjectName"  class="form-control customSearch" @click="filterProjects()">
                <span  @click="toggleSearch" class="customSearchIcon me-3" ></span>
              </div>
                <select v-if="projectList.length > 0" class="page-selector ms-4" v-model="selectedView" @change="viewTo">
                <option selected disabled>view</option>
                <option v-for="view in viewDetails" :key="view" :value="view">{{view}}</option>
              </select> 
              </div>
                <button type="search" class="btn btn-primary addNewProjectButton" v-on:click="addProjectClick()">
                  Create Project
                </button>
              </div>
                <div class="projectListTable mt-3 ms-5 p-0">
                <table class="searchProjectListTable">
                  <thead>
                    <tr>
                    <th style="border-radius: 10px 0px 0px 0px;"  @click="sortProject('projectName')">
                      <div class="d-flex justify-content-start align-items-center"> 
                      <span>Project Name</span> 
                      <span  :class="sortOrder.projectName==='ascending'? 'icon-ascending': sortOrder.projectName==='descending'? 'icon-descending':'icon-common'"></span>
                      </div>
                    </th>
                    <th >
                    <div class="d-flex justify-content-start align-items-center">
                      <span >SBU</span>
                      <span :class="sortOrder.sbu==='ascending'? 'icon-ascending': sortOrder.sbu==='descending'? 'icon-descending':'icon-common'"  @click="sortProject('sbu')"></span>
                      </div>
                    </th>
                    <th >
                      <div class="d-flex justify-content-start align-items-center">
                        <span >Status</span>
                        <span :class="sortOrder.isActive==='ascending'? 'icon-ascending': sortOrder.isActive==='descending'? 'icon-descending':'icon-common'"  @click="sortProject('isActive')"></span>
                      </div>
                    </th>
                    <th style="border-radius: 0px 10px 0px 0px;">
                      <div class="d-flex justify-content-start align-items-center">
                        <span >Action</span>
                      </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody>
  
                    <div v-if="showEmptyTableContent" class="d-flex justify-content-center mt-5 mb-5 displayContent" >
                      <span>No Project to view, continue to create Project</span>
                    </div>
  
                    <tr v-for="(project,index) in filteredprojectList" :key="index" style="height:2px;" >
                      <td >
                        <span class="p-0">
                          {{ project.projectName }}
                        </span>
                      </td>
                      <td>
                        {{ project.sbu}}
                      </td>
                      <td>{{ getStatus(project.isActive) }}</td>
                      <td>
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="d-flex justify-content-start align-items-center" :style="spaceDivAssessor">
                          <span type="search" class="btn editIcon d-flex justify-content-center align-items-center p-0" v-on:click="editProject(project)" @mouseover="showToolTip(index,'edit')" @mouseleave="hideToolTip(index,'edit')">
                          <div v-if="showToolTipIndex === index && activeTooltipType==='edit'" class="toolTip">
                            <div class="tooltiptext">
                              Edit
                          </div>
                          </div>
                          </span>
                          <span type="search" class="btn deleteIcon d-flex justify-content-center align-items-center p-0" v-on:click="deleteProject(project.id)" @mouseover="showToolTip(index,'delete')" @mouseleave="hideToolTip(index,'delete')">
                          <div v-if="showToolTipIndex === index && activeTooltipType==='delete'" class="toolTip">
                          <div class="tooltiptext">
                              Delete
                          </div>
                          </div>
                        </span>
                        </div>
                        </div>
                      </td>
                    </tr>
                </tbody>
                </table>
                </div>
            </div>
            <div v-if="projectList.length > 0" class="d-flex justify-content-end align-items-center mt-3 paginationBox">
              <span class="float-right pagination-detail">Jump to</span>
              <span class="me-1 ms-1 seperator"></span>
                <label class="page">Page</label>
              <div class="dropdown-wrapper">
                <select class="page-selector-pagination" v-model="selectedPage" @change="updateDisplayedRecords">
                  <option v-for="page in paginationData.totalPages" :key="page" :value="page">{{page}}</option>
                </select>
              </div>
              <span class="ms-2 me-2 seperator"></span>
              <span class="pagination-detail me-1">
              Showing {{ paginationData.startRecordIndex }} to {{ paginationData.endRecordIndex }} of {{ paginationData.totalRecords }}
            </span>
            </div>
          </div>
        </div>
        <delete-module v-if="showDeleteModule" headerMessage="Delete Action" message="This action will delete the selected Project. Are you sure you want to delete?" @confirm="deleteProjectAPI()" @cancel="closePopup"> </delete-module>
      </div> 
     </div> 
  </template>
  <script src="./projectList.controller.js"></script>
  <style scoped src="./projectList.style.css"></style>  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  