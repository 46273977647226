<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="deleteModuleStyles">
        <div class="bg-right"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center searchUserBox"
          :style="searchuserList">
          <div class="d-flex justify-content-start align-items-center mb-3 me-5 breadcrumbsResponsive"
            :style="breadcrumbStyles">
            <BreadcrumbComponent />
          </div>
          <div class="row mx-auto d-flex justify-content-center align-items-center ms-5 me-1 mt-2 userList150"
            style="width: 95%;">
            <div class="d-flex justify-content-between align-items-center ms-4 p-0 userListResponsive"
              style="width: 92%;">
              <div class="headingTextBoxTwo d-flex align-items-center">User List</div>
              <div class="d-flex justify-content-between align-items-center">
                <div v-if="userList.length > 0" class="searchApplication " style="z-index: 1;">
                  <input v-if="isSearchVisible" v-model="searchText" type="text" name="searchText"
                    placeholder="Search by UserName" class="form-control customSearch" @click="filterUsers()">
                  <span @click="toggleSearch" class="customSearchIcon me-3"></span>
                </div>
                <select v-if="userList.length > 0" class="page-selector ms-4" v-model="selectedView" @change="viewTo">
                  <option selected disabled>view</option>
                  <option v-for="view in viewDetails" :key="view" :value="view">{{ view }}</option>
                </select>
              </div>
              <button type="search" class="btn btn-primary addNewUserButton" v-on:click="addNewUserClick()">
                Create User
              </button>
            </div>
            <div class="userListTable mt-3 ms-4 p-0">
              <table class="searchUserListTable">
                <thead>
                  <tr>
                    <th style="border-radius: 10px 0px 0px 0px;">
                      <div class="d-flex justify-content-start align-items-center">
                        <span>EmailID</span>
                        <span
                          :class="sortOrder.emailId === 'ascending' ? 'icon-ascending' : sortOrder.emailId === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortEmailId('emailId')"></span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-start align-items-center">
                        <span>FirstName</span>
                        <span
                          :class="sortOrder.firstName === 'ascending' ? 'icon-ascending' : sortOrder.firstName === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortEmailId('firstName')"></span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Status</span>
                        <span
                          :class="sortOrder.isActive === 'ascending' ? 'icon-ascending' : sortOrder.isActive === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortEmailId('isActive')"></span>
                      </div>
                    </th>
                    <th style="border-radius: 0px 10px 0px 0px;">
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Action</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <div v-if="loggedInRole === 'frameworkAdmin' && showEmptyTableContent"
                    class="d-flex justify-content-center mt-5 mb-5 displayContent">
                    <span>No Framework to view, continue to create User</span>
                  </div>

                  <tr v-for="(user, index) in filteredUserList" :key="index" style="height:2px;">
                    <td>
                      <span class="p-0">
                        {{ user.emailId }}
                      </span>
                    </td>
                    <td>
                      {{ user.firstName }}
                    </td>
                    <td>{{ getStatus(user.isActive) }}</td>
                    <td>
                      <div class="d-flex justify-content-start align-items-center">
                        <div class="d-flex justify-content-start align-items-center" :style="spaceDivAssessor">
                          <span type="search" class="btn editIcon d-flex justify-content-center align-items-center p-0"
                            v-on:click="editUser(user)" @mouseover="showToolTip(index, 'edit')"
                            @mouseleave="hideToolTip(index, 'edit')">
                            <div v-if="showToolTipIndex === index && activeTooltipType === 'edit'" class="toolTip">
                              <div class="tooltiptext">
                                Edit
                              </div>
                            </div>
                          </span>
                        </div>
                        <div class="d-flex justify-content-start align-items-center" :style="spaceDivAssessor">
                          <span type="search"
                            class="btn deleteIcon d-flex justify-content-center align-items-center p-0"
                            v-on:click="deleteUser(user.id)" @mouseover="showToolTip(index, 'delete')"
                            @mouseleave="hideToolTip(index, 'delete')">
                            <div v-if="showToolTipIndex === index && activeTooltipType === 'delete'" class="toolTip">
                              <div class="tooltiptext">
                                Delete
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="userList.length > 0" class="d-flex justify-content-end align-items-center mt-3 ms-5 paginationBox">
            <span class="float-right pagination-detail">Jump to</span>
            <span class="me-1 ms-1 seperator"></span>
            <label class="page">Page</label>
            <div class="dropdown-wrapper">
              <select class="page-selector-pagination" v-model="selectedPage" @change="updateDisplayedRecords">
                <option v-for="page in paginationData.totalPages" :key="page" :value="page">{{ page }}</option>
              </select>
            </div>
            <span class="ms-2 me-2 seperator"></span>
            <span class="pagination-detail me-1">
              Showing {{ paginationData.startRecordIndex }} to {{ paginationData.endRecordIndex }} of {{
                paginationData.totalRecords }}
            </span>
          </div>
        </div>
      </div>
      <delete-module v-if="showDeleteModule" headerMessage="Delete Action"
        message="This action will delete the selected User. Are you sure you want to delete?"
        @confirm="deleteSelectedUser()" @cancel="closePopUp"> </delete-module>
    </div>
  </div>
</template>
<script src="./userList.controller.js"></script>
<style scoped src="./userList.style.css"></style>