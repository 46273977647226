export default class UserModel {
    constructor() {
        this.id = null;
        this.emailId = null;
        this.primaryRole = null;
        this.phoneNumber = null;
        this.firstName = null;
        this.lastName = null;
        this.isActive = null;
        this.userName = null;
    }
}