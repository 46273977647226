export default class ArtifactModel {
    constructor() {
        this.id = null;
        this.assessmentId = null;
        this.artifactName = null;
        this.artifactType = null;
        this.attachment = null;
        this.attachmentExtn = null;
        this.version = null;
        this.artifactUrl = null;
        this.artifactStatus = null;
    }
}