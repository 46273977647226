import { paginationService } from '@/app/services/pagination.service.js';
import sharedService from '@/app/services/shared.service.js';
import sortingService from '@/app/services/sort.service.js';
import statusService from '@/app/services/status.service';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import deleteModule from '@/app/shared/custompopup/PopUpModule.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import { showToast } from '@/app/shared/toast/toast.controller.js';
import userService from '@/app/UserDetails/user.service.js';


export default {
    name: "userList",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        SideNav,
        deleteModule,

    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },

    created() {
        this.breadcrumbStyles = {
            width: '90%',
        }
        this.fetchUserList();
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))');
        if (mediaQuery.matches) {
            this.breadcrumbStyles = {
                width: "78.5%",
            }
        } else {
            this.breadcrumbStyles = {
                width: "80.5%",
            };
        }

        this.$store.commit("addToHistory", this.$route);
        this.showBreadcrumbs = true;

    },
    data() {
        return {
            page: 10,
            showDisplayContent: true,
            showDefaultContent: true,
            showFirstSearchBox: true,
            showSecondSearchBox: false,
            userList: [],
            assessmentListforAssessor: [],
            userAssignedUserList: [],
            UserModel: {},
            showCreateFrameworkButton: true,
            validateUserName: false,
            navigationClick: "Home",
            breadcrumbStyles: {},
            showBreadcrumbs: false,
            deleteUserId: '',
            userId: "",
            deleteModuleStyles: {},
            showDeleteModule: false,
            showEmptyTableContent: false,
            showuserListTableContent: true,
            showToolTipIndex: null,
            activeTooltipType: null,
            showInfoPopUpModule: false,
            sortOrder: {
                emailId: "common",
                firstname: "common",
                status: "common"

            },
            userListResponse: [],
            searchText: '',
            filteredUsers: [],
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            currentPage: 1,
            selectedPage: 1,
            rowsPerPage: 10,
            displayedUserList: [],
            totalRecords: 0,
            totalPages: 0,
            paginationData: {},
        };
    },
    computed: {
        filteredUserList() {
            if (!this.searchText) {
                return this.displayedUserList;
            }
            const enteredText = this.searchText.toLowerCase();
            return this.displayedUserList.filter(user =>
                user.emailId.toLowerCase().includes(enteredText)

            );
        }
    },


    methods: {

        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            console.log(index);
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },

        validateUserStatus(status) {
            if (status == "active") {
                return (this.status = "active");
            } else if (status == "inactive") {
                return (this.status = "inactive");
            } else if (status == "draft") {
                return (this.status = "Draft");
            } else {
                return (this.status = " ");
            }
        },
        createNewUser() {
            this.$router.push("/createuserList");
        },
        addNewUserClick() {
            this.$router.push("/createUser");
        },

        editUser(user) {
            sharedService.setData("user.model", user);
            this.$router.push({
                name: "editUser",
                params: { userId: user.id },
            });
        },
        deleteUser(userId) {
            this.showDeleteModule = !this.showDeleteModule;
            this.deleteModuleStyles = {
                opacity: "0.3",
                id: userId

            };
        },
        closePopUp() {
            this.showDeleteModule = false
            this.deleteModuleStyles = {
                opacity: "1",
            };
        },
        openFileDialog() {
            event.preventDefault();
            let fileInputElement = this.$refs.file;
            fileInputElement.click();
        },

        async fetchUserList() {
            try {
                const response = await userService.fetchUserList();
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.userListResponse = response.data.data;
                    this.userList = [...this.userListResponse];
                    if (this.userList.length <= 0) {
                        this.showuserListTableContent = false
                        this.showDefaultContent = true
                        this.showEmptyTableContent = true;
                    } else {
                        this.showuserListTableContent = true
                        this.showDefaultContent = false
                        this.showEmptyTableContent = false;
                    }
                    this.updateDisplayedRecords();
                } else {
                    showToast("Failed to fetch users", "error", 'validationerror');
                }
            } catch (err) {
                console.error('error in controller--', err);

            }
        },

        filterUsers() {
            if (this.searchText) {
                this.filteredUsers = this.userList.filter(user =>
                    user.firstname.toLowerCase().includes(this.searchText.toLowerCase())
                );
            } else {
                this.filteredUsers = this.userList;
            }
        },

        getStatus(status) {
            return statusService.getStatus(status)

        },
        async deleteSelectedUser() {
            this.serverRequestOn = true;
            const response = await userService.deleteUser(this.deleteModuleStyles.id);
            this.serverRequestOn = false;
            //if (response.data.header.responseMessage === "SUCCESS")
            if (response.data.data === "SUCCESS") {
                showToast("User Delete Success", 'success', 'validationsuccess');
                this.fetchUserList();
                this.closePopUp();
            } else {
                showToast("Failed to Delete User", 'error', 'validationerror');
                this.closePopUp();
            }
        },

        sortEmailId(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = "common";
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedUserList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedUserList, currentPage, rowsPerPage)
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
            if (this.isSearchVisible) {
                this.searchText = '';

            }
        },
        updateDisplayedRecords(column) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            // use paginationservice to handle pagination logic
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.userList
            );

            // set pagination data from the service
            this.displayedUserList = this.paginationData.displayedRecords;
        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.userList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },
    },
};