import sharedService from '@/app/services/shared.service';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import { showToast, toast } from '@/app/shared/toast/toast.controller';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import clientService from '../client.service';
import CreateClient from './CreateClient.vue';



export default {
    name: "createClient",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        CreateClient,
        SideNav,
        ToastComponent


    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");

        return {
            toastState: toast,
            loggedInRole,
        };
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            clientError: "",
            ClientModel: {
                isActive:"Y"
            },
            clientId: "",
            errors: {
                client: "",
                region: "",
                industry: "",
                email: "",
                contactPerson: "",

            },
            save: false,
            editClient: false,
            validateEmail: false,
            CreateClient,
            createClientResponse: {},
            showBreadcrumbs: false,
            serverRequestOn: false,
            breadcrumbStyles: {}
        };
    },
    // mounted() {
    //     window.addEventListener('resize', this.handleResize);
    //     this.handleResize();
    // },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    created() {
        this.inputReadOnly = {
            color: "#767676 !important",
        };
        this.breadcrumbStyles = {
            width: "91.5%",
        };
        //we can access the route parameters using this.$route.params object within the component.
        //if having route like /client/:clientId we can retrieve the clientId value using this.$route.params.clientId.
        this.clientId = this.$route.params.clientId;
        this.$store.commit("addToHistory", this.$route);
        if (this.clientId) {
            if (this.loggedInRole == "frameworkAdmin") {
                this.routePath = "/editClient";
            }
            this.ClientModel = sharedService.getData("client.model");
            this.editClient = true;
        } else {
            this.routePath = "/createClient";
        }
    },
    methods: {


        clientValidation() {
            if (this.ClientModel.clientName == undefined) {
                this.errors.client = "Please Enter a Client";
            } else {
                this.errors.client = "";
            }

        },
        regionValidation() {

            if (this.ClientModel.region == undefined) {
                this.errors.region = "Please Enter a region";
            } else {
                this.errors.region = "";

            }
        },
        industryValidation() {

            if (this.ClientModel.industry == undefined) {
                this.errors.industry = "Please Enter a industry";
            } else {
                this.errors.industry = "";

            }

        },
        emailIdValidation() {

            if (this.ClientModel.email == undefined) {
                this.errors.email = "Please Enter a email";
            } else {
                this.errors.email = "";

            }

        },
        validatingEmail(email) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.com$/;
            if (!regex.test(email)) {
                this.errors.email = "Please enter the Email in correct format";
                event.preventDefault();
            } else {
                this.validateEmail = true;
            }
            return this.validateEmail
        },
        clearEmail() {
            this.errors.email = " ";
        },
        contactPersonValidation() {

            if (this.ClientModel.contactPerson == undefined) {
                this.errors.contactPerson = "Please Enter a contact person";
            } else {
                this.errors.contactPerson = "";

            }

        },

        checkTextareaLength() {
            console.log(this.$nextTick)
            let descriptionCharacterLength = this.ClientModel.clientDescription
            if (descriptionCharacterLength === undefined) {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 100) {
                    this.errors.clientDescription = 'Please Enter valid Description less than 100 characters';
                } else {
                    this.errors.clientDescription = ''
                }
            })
        },


        createClientClick() {
            console.log(this.ClientModel);
            if (this.ClientModel.clientName == undefined) {
                this.clientValidation();
            } else if (this.ClientModel.region == undefined) {
                this.regionValidation();
            } else if (this.ClientModel.industry == undefined) {
                this.industryValidation();
            } else if (this.ClientModel.email == undefined) {
                this.emailIdValidation();
            } else if (this.ClientModel.contactPerson == undefined) {
                this.contactPersonValidation();
            } else {
                this.createClientAPI();
            }
            event.preventDefault();
        },

        async createClientAPI() {
            this.serverRequestOn = true;
            const response = await clientService.createClientAPI(
                this.ClientModel
            );
            this.serverRequestOn = false;
            if (response.data.header.responseMessage === "SUCCESS") {
                this.createClientResponse = response.data.data;
                this.save = true;
                this.ClientModel.id = this.createClientResponse.id;
                sharedService.setData(
                    "client.model",
                    this.createClientResponse
                );
                showToast('Client created successfully', 'success', 'validationsuccess')
                this.$router.push("/clientList");

                // this.$router.push({
                //     name: "projectList",
                //     params: { clientId: this.ClientModel.id },
                // });
            }
            else {
                this.save = false;
                showToast('Failed to Create Client', 'error', 'validationerror');
            }
        },


        async fetchClient(clientId) {
            const response = await clientService.fetchClientById(
                clientId
            );
            if (response.data.header.responseMessage === "SUCCESS") {
                this.ClientModel = response.data.data;
                sharedService.setData("client.model", this.ClientModel);
                this.$route.params.clientId;

            } else {
                showToast(
                    "Failed to fetch Client Details",
                    "error",
                    "validationerror"
                );
            }
        },

        cancelClick() {
            this.$router.push("/clientList");
        },

    }

}