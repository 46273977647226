import apiEndpoint from '@/app/services/uri-constants.js';
import axios from '../shared/interceptor.js';

export default {

    async fetchArtifactListByAssessmentId(assessmentId) {
        return  axios.get(`${apiEndpoint.artifactListByAssessmentId}` + assessmentId)
        .catch(function (error) {
            throw error;
       });
    },
    async saveArtifact(formdata) {
        return axios.post(`${apiEndpoint.saveArtifact}`, formdata)
        .catch(function (error) {
            throw error;
       });
    },
    async updateArtifact(artifact) {
        return axios.post(`${apiEndpoint.updateArtifact}`, artifact)
        .catch(function (error) {
            throw error;
       });

    },

    async updateOverAllArtifactStatus(artifact) {
        return axios.post(`${apiEndpoint.updateOverAllArtifactStatus}`, artifact)
        .catch(function (error) {
            throw error;
       });
        
    },
    async updateArtifactStatus(artifactObj) {
        return  axios.post(`${apiEndpoint.updateArtifactStatus}`,artifactObj)
        .catch(function (error) {
            throw error;
       });
    },
    async fetchArtifactById(artifactId) {
        return axios.get(`${apiEndpoint.fetchArtifact}` + artifactId)
         .catch(function (error) {
            throw error;
       });
    },
    async fetchFrameworkList() {
    
        return  axios.get(`${apiEndpoint.fetchFrameworkList}`)
        .catch(function (error) {
            throw error;
       });
    },
    async deleteArtifact(artifactId) {
        
        return axios.delete(`${apiEndpoint.deleteArtifact}` + artifactId)
        .catch(function (error) {
            throw error;
       });
    },
    async findArtifactListByAssessmentId(assessmentId) {
        return axios.get(`${apiEndpoint.findAllArtifactByAssessmentId}` + assessmentId)
        .catch(function (error) {
            throw error;
       });
    }
}