<template>
    <div v-if="visible" :class="['toast',type]" class="d-flex justify-content-between align-items-center p-3">
        <div class="d-flex justify-content-between align-items-center">
            <div :class="[imageType]">

            </div>
            <div class="validationMessage">
                {{ message }}
            </div>
        </div>
    </div>
</template>
<script src="./toast.controller.js">
</script>
<style scoped src="./toast.styles.css">
</style>
