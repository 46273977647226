<template>
  <HeaderComponent />
  <FooterComponent />
  <div class="container-fluid p-0">
    <div class="d-flex flex-column align-items-center outer-component">
      <div class="row p-0 d-flex justify-content-start align-items-center mb-3 breadcrumbsResponsive" :style="breadcrumbStyles">
        <BreadcrumbComponent />
      </div>
      <div class="row p-0 m-0 mt-5 mb-5 ms-4 me-4 d-flex justify-content-center align-items-center headerResponsive" :style="headerResponsiveStyles">
        <div class="landing-header">
          <span class="landing-header-content">
            Hi {{ userName }}, as <span v-if="checkRole"> {{ userBasedRole }}</span> what do you want to do today?
          </span>
        </div>
      </div>
      <!-- <div > -->
      <div v-if="role === 'frameworkAdmin'" class="row p-0  d-flex justify-content-center outer-boxs">
        <div class="col-3 col-md-2 p-0 m-1">
          <div class="left-body d-flex flex-column justify-content-center align-items-center p-5">
            <div class="text-center d-flex flex-column align-items-center displayBoxResponsive" :style="displayBoxResponsive">
              <div>
                <img alt="" src="../../assets/img/CreateClient.svg" class="img-fluid img-framework"/>
              </div>
              <div v-if="role === 'frameworkAdmin'">
                <div class="landingpage-body-content p-2 mt-4">Create/Manage Client and Project</div>
                <div class="text-content mt-2 " >
                  As <span v-if="checkRole"> {{ userBasedRole }}</span> you can either create or manage client and project
                </div>
              </div>      
              <button class="assessment-button assessment-btn-project p-2 px-4" v-on:click="startNowClientClick()">
                Start Now
              </button>
            </div>
          </div>
        </div>
        <div class="col-3 col-md-2 p-0 m-1">
          <div class="left-body d-flex flex-column justify-content-center align-items-center p-5">
            <div class="text-center d-flex flex-column align-items-center displayBoxResponsive" :style="displayBoxResponsive">
              <div>
                <img alt="" src="../../assets/img/CreateFramework.svg" class="img-fluid img-framework"/>
              </div>
              <div v-if="role === 'frameworkAdmin'">
                <div class="landingpage-body-content p-2 mt-3" id="landingPageFramework">Create/Manage Frameworks</div>
                <div class="text-content mt-2 " >
                  As <span v-if="checkRole"> {{ userBasedRole }}</span> you can either create or manage Frameworks
                </div>
              </div>      
              <button class="assessment-button p-2 px-4" v-on:click="startNowFrameworkClick()">
                Start Now
              </button>
            </div>
          </div>
        </div>
        <div class="col-1.5 col-md-2 p-0 m-1">
          <div class="left-body d-flex flex-column justify-content-center align-items-center p-5">
            <div class="text-center d-flex flex-column align-items-center displayBoxResponsive" :style="displayBoxResponsive">
              <div>
                <img alt="" src="../../assets/img/CreateUser.svg" class="img-fluid img-user"/>
              </div>
              <div v-if="role === 'frameworkAdmin'">
                <div class="landingpage-body-content p-2 mt-3" id="landingPageFramework">Create/Manage Users</div>
                <div class="text-content mt-2">
                  As <span v-if="checkRole"> {{ userBasedRole }}</span> you can either create or manage Users
                </div>
              </div>      
              <button class="assessment-button user p-2 px-4" v-on:click="startNowUserClick()">
                Start Now
              </button>
            </div>
          </div>
        </div> 
        <div class="col-1.5 col-md-2 p-0 m-1">
          <div class="left-body d-flex flex-column justify-content-center align-items-center p-5" :style="leftBodyStyles">
            <div class="text-center d-flex flex-column align-items-center displayBoxResponsive" :style="displayBoxResponsive">
              <div>
                <img alt="" src="../../assets/img/createassessment.svg" class="img-fluid img-assessment"/>
              </div>
              <div id="textContentAssessment" v-if="role === 'frameworkAdmin'">
                <div class="landingpage-body-content p-2 mt-3">Create/Manage Assessment</div>
                <div class="text-content mt-2 ">
                  As <span v-if="checkRole"> {{ userBasedRole }}</span> you can either create or manage Assessments
                </div>
              </div>
              <div v-if="role === 'assessor'">
                <div class="landingpage-body-content p-2 mt-3 searchMargin">
                  Search Assessment
                </div>
                <div class="text-content mt-2">
                  As <span v-if="checkRole"> {{ userBasedRole }}</span> you can search Assessments assigned to you
                </div>
              </div>
              <div v-if="role === 'reviewer'">
                <div class="landingpage-body-content p-2 mt-3">
                  Approve/Reject Assessment
                </div>
                <div class="text-content mt-2">
                  As <span v-if="checkRole">{{ roleBasedArticle }} {{ userBasedRole }}</span> you can either approve or reject Artifacts
                </div>
              </div>
              <button class="assessment-button p-2 px-4" v-on:click="startNowClick()">
                Start Now
              </button>
            </div>
          </div>
        </div>
        <div class="col-3 col-md-2 p-0 m-1">
          <div class="left-body d-flex flex-column justify-content-between align-items-center p-4 dashboardBoxResponsive">
            <div class="text-center d-flex flex-column justify-content-between align-items-center dashboardBox">
              <div class="d-flex justify-content-center align-items-end dashboardAlign">
                <img alt="" src="../../assets/img/viewdashboard.svg" class="img-fluid img-dashboard"/>
              </div>
              <div class="mb-4 mt-4" :style="viewDashBoardStyles">
                <div class="landingpage-body-content p-2 mt-3" id="landingPageDashboard">
                  View Dashboard
                </div>
                <div class="text-content mt-4">As <span v-if="checkRole">{{ roleBasedArticle }} {{ userBasedRole }}</span> you can either view or filter the Dashboard
                </div>
              </div>
              <div class="mb-2">
                <button class="p-2 px-2" id="assessment-btn-dashboard" v-on:click="frameworkDashboardClick()" >View Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div > -->
        <div v-if="(role === 'reviewer' )||(role === 'assessor')" class="row p-0 d-flex justify-content-center outer-box">
          <div class="col-12 col-md-6 col-xl-6 col-xxl-6 ">
            <div class="left-body d-flex flex-column justify-content-center align-items-center p-5">
              <div class="text-center d-flex flex-column align-items-center displayBoxResponsive">
                <div>
                  <img alt="" src="../../assets/img/createassessment.svg" class="img-fluid img-assessment"/>
                </div>
                <div v-if="role === 'frameworkAdmin'">
                  <div class="landingpage-body-content p-2 mt-3">Create/Manage Assessment</div>
                  <div class="text-content mt-2 " >
                    As <span v-if="checkRole"> {{ userBasedRole }}</span> you can either create or manage Assessments
                  </div>
                </div>
                <div v-if="role === 'assessor'">
                  <div class="landingpage-body-content p-3 mt-3 searchMargin">
                    Search Assessment
                  </div>
                  <div class="text-content mt-2">
                    As <span v-if="checkRole"> {{ userBasedRole }}</span> you can search Assessments assigned to you
                  </div>
                </div>
                <div v-if="role === 'reviewer'">
                  <div class="landingpage-body-content p-3 mt-3">
                    Approve/Reject Assessment
                  </div>
                  <div class="text-content mt-2">
                    As <span v-if="checkRole">{{ roleBasedArticle }} {{ userBasedRole }}</span> you can either approve or reject Artifacts
                  </div>
                </div>
                <button class="assessment-button p-2 px-4" v-on:click="startNowClick()">
                  Start Now
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-6 col-xxl-6 ">
            <div class="left-body d-flex flex-column justify-content-center align-items-center p-5">
              <div class="text-center d-flex flex-column align-items-center displayBoxResponsive" :style="displayBoxResponsive">
                <div class="d-flex justify-content-center align-items-end">
                  <img alt="" src="../../assets/img/viewdashboard.svg" class="img-fluid img-dashboard"/>
                </div>
                <div class="mb-4 mt-4">
                  <div class="landingpage-body-content p-3 mt-5" id="landingPageDashboard">
                    View Dashboard
                  </div>
                  <div class="text-content mt-3">As <span v-if="checkRole">{{ roleBasedArticle }} {{ userBasedRole }}</span> you can either view or filter the Dashboard
                  </div>
                </div>
                <button class="assessment-button p-2 px-4" v-on:click="frameworkDashboardClick()" >View Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<script src="./landingPage.controller.js"></script>
<style scoped src="./landingPage.style.css"></style>