import axios from '../shared/interceptor.js';
import apiEndpoint from '@/app/services/uri-constants.js';


export default {
    async fetchclientList() {
        const response = await axios.get(`${apiEndpoint.clientList}`)
        return response;
    },


    async createClientAPI(client) {
        const response = await axios.post(`${apiEndpoint.createClient}`, client)
        return response;
    },
    async fetchClientById(clientId) {
        const response = await axios.get(`${apiEndpoint.fetchClient}` +
            clientId)
        return response;
    },
    // const deleteClient = 'api/client/deleteClient?clientId'
    async deleteClient(clientId) {
        const response = await axios.delete(`${apiEndpoint.deleteClient}` +
            clientId)
        return response;
    },

}