<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="deleteModuleStyles">
        <div class="bg-right"></div>
        <div class="row mb-0 d-flex flex-column justify-content-start align-items-center artifactListAssessmentBox">
          <div class="row p-0 d-flex justify-content-start align-items-center mb-3 breadcrumbsResponsive" :style="breadcrumbStyles">
                <BreadcrumbComponent />
          </div>
          <div class="d-flex flex-column justify-content-between align-items-center z-3 position-relative">
              <div class="navigationImageBox d-flex justify-content-between">
                <div class="d-flex flex-column justify-content-end align-items-center imagesForNavigation" v-on:click="assessmentList()">
                  <div class="assessImage">
                    
                  </div>
                  <div class="navigationImageText">Assessment</div>
                </div>
                <span class="horizontalLine mt-5 mb-5" style="border-top: 3px solid #2e4ca0;width: 72%;"> </span>
                <div class="d-flex flex-column justify-content-end align-items-center imagesForNavigation">
                  <div class="artifactImage" >
                    <span class="dottedCircle"></span>
                  </div>
                  <div class="navigationImageText">Artifacts</div>
                </div>
                <span class="horizontalLine mt-5 mb-5" style="width:72%"></span>
                <div class="d-flex flex-column justify-content-end align-items-center imagesForNavigation" v-on:click="assessmentUserList()">
                  <div class="userImage">
                  </div>
                  <div class="navigationImageText">Assign User</div>
                </div>
              </div>
          </div>
          <div class="d-flex justify-content-between align-items-center mt-5 p-0 artifactResponsiveDiv">
              <div class="headingTextBox d-flex align-items-center">
                Artifact List - {{ AssessmentModel?.assessmentName }}
              </div>
              <div class= "d-flex justify-content-between align-items-center">
              <div v-if="assessmentArtifactList.length > 0"  class="searchApplication"  style="z-index: 1;"  >
              <input  v-if="isSearchVisible" v-model="searchText" type="text" name="searchText"  placeholder="Search by ArtifactName"  class="form-control customSearch" @click="filterArtifacts()">
              </div>
              <span   v-if="assessmentArtifactList.length > 0"   @click="toggleSearch" class="customSearchIcon me-3" ></span>
              <select v-if="assessmentArtifactList.length > 0" class="page-selector ms-4" v-model="selectedView" @change="viewTo">
                <option selected disabled>view</option>
                <option v-for="view in viewDetails" :key="view" :value="view">{{view}}</option>
              </select>   
              <button type="search" class="btn btn-primary addNewArtifactButton" v-on:click="addArtifact()">
                Add New Artifacts
              </button> 
            </div>
          </div>
          <div class="row mx-auto d-flex justify-content-center align-items-center" style="width: 95%;">
            <div class="assessmentSearchTable mt-3 p-0">
              <table>
                <thead>
                  <tr class="d-flex justify-content-between align-items-center">
                  <th style="border-radius: 10px 0px 0px 0px;" >Artifact Name
                    <span  :class="sortOrder.artifactName==='ascending'? 'icon-ascending': sortOrder.artifactName==='descending'? 'icon-descending':'icon-common'"  @click="sortArtifact('artifactName')"></span>
                  </th>
                  <th >Artifact Type
                    <span :class="sortOrder.artifactType==='ascending'? 'icon-ascending': sortOrder.artifactType==='descending'? 'icon-descending':'icon-common'"   @click="sortArtifact('artifactType')"></span>
                  </th>
                  <th style="width:45%">Document
                    <span :class="sortOrder.attachmentExtn==='ascending'? 'icon-ascending': sortOrder.attachmentExtn==='descending'? 'icon-descending':'icon-common'"   @click="sortArtifact('attachmentExtn')"></span>
                  </th>
                  <th>Version
                    <span :class="sortOrder.version==='ascending'? 'icon-ascending': sortOrder.version==='descending'? 'icon-descending':'icon-common'"   @click="sortArtifact('version')"></span>
                  </th>
                  <th>OverAll Status
                    <span :class="sortOrder.artifactStatus==='ascending'? 'icon-ascending': sortOrder.artifactStatus==='descending'? 'icon-descending':'icon-common'"   @click="sortArtifact('artifactStatus')"></span>
                  </th>
                  <th style="border-radius: 0px 10px 0px 0px;">Action</th>   
                </tr>               
                </thead>
                <tbody :style="tableBodyStyles">
                <tr v-for="(artifactDetails,index) in filteredassessmentArtifactList" :key="index">                
                  <td>{{ artifactDetails.artifactName }}</td>
                  <td>{{ artifactDetails.artifactType }}</td>
                  <td style="width:45%">
                    <div class="d-flex align-items-center">
                      <div v-if="artifactDetails.attachmentExtn === 'docx'">
                        <div class="wordAttachmentIcon"></div>
                      </div>
                      <div v-else-if="(artifactDetails.attachmentExtn === 'xls') || (artifactDetails.attachmentExtn === 'xlsx') || (artifactDetails.attachmentExtn === 'xlsm') || (artifactDetails.attachmentExtn === 'xlsb')">
                        <div class="excelAttachmentIcon"></div> 
                      </div>
                      <div v-else-if="artifactDetails.attachmentExtn === 'ppt'">
                        <div class="powerpointAttachmentIcon"></div>
                      </div>
                      <div v-else-if="artifactDetails.attachmentExtn === 'txt'">
                        <div class="textAttachmentIcon"></div>
                      </div>
                      <div v-else-if="artifactDetails.attachmentExtn === 'pdf'">
                        <div class="pdfAttachmentIcon"></div>
                      </div>
                      <div style="margin-left: -0.85em;"><a style="cursor:pointer;color: #2E4CA0;text-decoration: none;" v-bind:href="artifactDetails.artifactUrl">
                      {{ artifactDetails.attachment }}</a>
                      </div>
                    </div>
                  </td>

                  <td>{{ artifactDetails.version }}</td>
                    <td :class="getStatus(artifactDetails.artifactStatus)">
                      {{ status }}
                    </td>

                  <td style="padding: 0.5em 0.5em 0.5em 0em">
                    <div class="d-flex justify-content-start align-items-center">
                      <!-- <span class="editIcon"></span> -->
                      <button type="search" class="btn editIcon" v-on:click="editArtifact(artifactDetails)" @mouseover="showToolTip(index,'edit')" @mouseleave="hideToolTip(index,'edit')">
                      <div v-if="showToolTipIndex === index && activeTooltipType==='edit'" class="toolTip">
                        <div class="tooltiptext">
                          Edit
                        </div>
                        </div>
                      </button>
                      <button type="search" class="btn deleteIcon" v-on:click="deleteButton(artifactDetails.id)" @mouseover="showToolTip(index,'delete')" @mouseleave="hideToolTip(index,'delete')">
                      <div v-if="showToolTipIndex === index && activeTooltipType==='delete'" class="toolTip">
                        <div class="tooltiptext">
                            Delete
                        </div>
                        </div>
                      </button>
                      <!-- <span class="deleteIcon"></span> -->
                    </div>
                  </td>
                </tr>
                    <span v-if="showDisplayMessage" class="displayContent">Add Artifacts to be displayed</span>
                </tbody>
              </table>
              <!-- <div class="d-flex justify-content-center align-items-center mt-5 mb-5 displayContent" v-if="showEmptyTableContent"></div> -->
            </div>
          </div>
          <div  v-if="assessmentArtifactList.length > 0"  class="d-flex justify-content-end align-items-center mt-3 me-5 paginationBox">
              <span class="float-right pagination-detail">Jump to</span>
              <span class="me-2 ms-2 seperator"></span>
                <label class="page">Page</label>
              <div class="dropdown-wrapper">
                <select  class="page-selector-pagination" v-model="selectedPage" @change="updateDisplayedRecords">
                  <option v-for="page in paginationData.totalPages" :key="page" :value="page">{{page}}</option>
                </select>
              </div>
              <span class="ms-3 me-2 seperator"></span>
              <span class="pagination-detail me-1">
              Showing {{ paginationData.startRecordIndex }} to {{ paginationData.endRecordIndex }} of {{ paginationData.totalRecords }}
            </span>
          </div>
          <div class="d-flex justify-content-end mt-4 p-0 nextResponsiveButtonDiv">
        <!--           <button type="reset" class="btn btn-primary resetButton">
            Reset
          </button>
          <button type="submit" class="btn btn-primary saveLaterButton float-end ms-2" v-on:click="saveLaterClick()">
            Save for Later
          </button> -->
        <button type="submit" class="btn btn-primary nextButton float-end ms-2" v-on:click="nextClick()" style="width: 7% !important;">
          Next
        </button>
        <!-- <button type="submit" class="btn btn-secondary resetButton float-end ms-2">
            Reset
          </button> -->
          </div>
        </div>
      </div>
      <delete-module v-if="showDeleteModule" headerMessage="Delete Action" message="This action will delete the selected Artifact. Are you sure you want to delete?" @confirm="deleteSelectedArtifact" @cancel="closePopUp"> </delete-module>

    </div>
  </div>
<!--    /* MODALS */ -->
 <sweet-modal ref="modal" width="72%" id="sweetModalParent">
                <div class="d-flex flex-column" id="sweetModal">
                  <div class="popupHeader">
                    <span>Upload Artifacts - {{ AssessmentModel?.assessmentName }}</span>
                  </div>
                  <div class="popupContent">
                    <div class="d-flex justify-content-end align-items-right mandatoryField"><span class="mandatoryFieldforForm">* &nbsp;</span> - Mandatory Fields</div>
                    <form class="row g-3 d-flex justify-content-center align-items-center p-0">
                      <div class="row d-flex justify-content-between align-items-center mt-5 p-0 popUpRowResponsive">
                        <div class="col-4 d-flex flex-column">
                          <span class="mandatoryFieldInput"><label class="form-label createAssessmentLabel">Artifact Name</label> * </span>
                          <input v-model="ArtifactModel.artifactName" type="text" autocomplete="off" id="artifactName" name="artifactName" class="uploadArtifactFields"  placeholder="Enter Artifact Name" @change="artifactNameErrorCheck">
                          <div v-if="errors.artifactName" class="validationMessage mt-5">{{ errors.artifactName }}</div>
                        </div>
                          <div class="col-4 d-flex flex-column p-0">
                          <span class="mandatoryFieldInput"><label for="clientNameInput" class="form-label createAssessmentLabel">Artifact Type</label> * </span>
                          <div class="dropdownSelect" v-if="frameworkEditFlag===true">
                            <select v-model="ArtifactModel.artifactType" id="artifactType" class="dynamicDropdown"  @change="artifactTypeErrorCheck">
                               <option disabled selected :value="undefined" class="dropdownOption">Artifact Type</option>
                               <option v-for="framework in frameworkTypes" :key="framework.id" :value="framework" selected>
                                  {{ framework }}
                              </option>
                            </select>                   
                          </div>
                          <input v-if="frameworkEditFlag===false" v-model="ArtifactModel.artifactType" type="text" autocomplete="off" id="artifactType" name="artifactType" class="uploadArtifactFields" :style="readOnlyText" readonly>
                          <div v-if="errors.artifactType" class="validationMessage mt-5">{{ errors.artifactType }}</div>
                        </div>
                        <div class="col-3 d-flex flex-column">
                          <!-- <div class="col-8 d-flex flex-column"> -->
                            <span class="mandatoryFieldInput"><label for="clientNameInput" class="form-label createAssessmentLabel">Select Document</label> * </span>
                            <div class="dropdown">
                              <input id="artifactFileType" type="file" @change="selectFile" style="display:none;" ref="file">  
                              <div class="d-flex justify-content-between">
                                <div class="uploadArtifactFields">
                                  <div class="uploadFileforArtifact" v-if="ArtifactModel.attachment" :title="textarea ? '': ArtifactModel.attachment"> {{ArtifactModel.attachment}}.{{ArtifactModel.attachmentExtn}}</div>
                                </div>
                                  <label v-if="isBrowseEnabled" class="browseButton p-1" for="artifactFileType">Browse</label>                                                       
                                  <span v-else class="browseButton p-1 disabled" style="opacity:0.5;cursor:not-allowed;background-color:#767676;">Browse</span>
                              </div>             
                            </div>
                            <div v-if="errors.attachment" class="validationMessage mt-5">{{ errors.attachment }}</div>
                        </div>
                      </div>
                      <div class="row d-flex justify-content-between align-items-center mt-5 p-0 popUpRowResponsive">
                        <div class="col-4 d-flex align-items-center">
                            <div class="col-12 d-flex flex-column">
                              <span class="mandatoryFieldInput"><label for="clientNameInput" class="form-label createAssessmentLabel">Framework</label> * </span>


                              
                              <div class="dropdownSelect" v-if="frameworkEditFlag===true">
                                <select v-model="ArtifactModel.frameworkId" id="frameworkId" class="dynamicDropdown" :disabled="!ArtifactModel.artifactType" @change="frameworkNameErrorCheck" >
                                  <option disabled selected :value="undefined" class="dropdownOption">Framework Name</option>
                                  <option v-for="framework in frameworkListByType" :key="framework._id" :value="framework._id" selected>
                                      {{ framework.name }}
                                  </option>
                                </select>                          
                              </div>  
                              <div v-if="errors.artifactFramework" class="validationMessage mt-5">{{ errors.artifactFramework }}</div>                        
                              <input v-if="frameworkEditFlag===false" v-model="editFrameworkName" type="text" autocomplete="off" id="editFramework" name="editFramework" class="uploadArtifactFields" :style="readOnlyText" readonly>           
                            </div>
                            <div class="viewButton d-flex justify-content-center align-items-center" @mouseover="showTooltipView=true" @mouseout="showTooltipView=false" v-on:click="viewFramework(FrameworkModel.id)" :style="viewButtonVisible">
                              <div v-if="showToolTipView" class="toolTipView">
                               View Framework
                              </div>
                            </div>
                        </div>
                        <div class="col-4 d-flex flex-column p-0">
                          <label for="clientNameInput" class="form-label createAssessmentLabel">Status</label>
                          <input :class="getStatus(ArtifactModel.artifactStatus)" v-model="status" type="text" autocomplete="off" id="artifactStatus" name="artifactStatus" class="uploadArtifactFields"  placeholder="Draft" :style="readOnlyText" readonly>
                        </div>
                        <div class="col-3 d-flex flex-column">
                          <span class="mandatoryFieldInput"><label for="artifactVersion" class="form-label createAssessmentLabel">Version</label> * </span>
                          <input v-model="ArtifactModel.version" type="text" autocomplete="off" id="version" name="version" class="uploadArtifactFields"  placeholder="Enter Artifact Version" @change="artifactVersionErrorCheck">
                          <div v-if="errors.version" class="validationMessage mt-5">{{ errors.version }}</div>
                        </div>
                      </div>
                      <div class="row d-flex justify-content-between align-items-center mt-5 p-0 popUpRowResponsive">
                        <div class="col-4 d-flex flex-column">
                          <label class="form-label createAssessmentLabel flex-1">Description</label>                       
                            <textarea v-model="ArtifactModel.artifactDescription" type="text" id="artifactDescription" name="artifactDescription" class="descriptionField flex-2" placeholder="A brief description of the Artifact" maxlength="100" @keypress="checkTextareaLength"></textarea>
                            <div v-if="errors.artifactDescription" class="validationMessage flex-3 mt-5" style="bottom: 2em;">{{ errors.artifactDescription }}</div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-end align-items-center mt-5 modalButtonResponsive"> 
                        <button type="submit" class="btn btn-primary saveButton float-end" v-on:click="validateSaveArtifact()"> 
                          Save
                        </button>
                      </div>

                   
                    </form>
                    <div :class="{'overlay':this.serverRequestOn}">
                      </div>
                      <div class="loading-bar-spinner" :class="{'spinner':this.serverRequestOn}">
                        <div class="spinner-icon"></div>
                      </div>
                  </div>
                </div>
 </sweet-modal>         

 <div :class="{'overlay':this.serverRequestOn}">
      </div>
      <div class="loading-bar-spinner" :class="{'spinner':this.serverRequestOn}">
         <div class="spinner-icon"></div>
  </div>
</template>





<script src="./artifactList.controller.js"></script>
<style scoped src="./artifactList.style.css"></style>
<style>
@media only screen and (min-width:1025px) and (max-width:1280px) {  
  .sweet-modal .sweet-box-actions .sweet-action-close{
        line-height: 30px !important;
    }
    #sweetModalParent .sweet-modal{
      width: 72.5% !important;
    }
    .viewButton{
      background-size: 35% !important;
      left: 29% !important;
      top: 49.25% !important;
      background-position: left !important;
    }
    .sweet-modal .modalButtonResponsive{
      margin-top: 0rem !important;
    }

}
@media only screen and (min-width:1441px) and (max-width:1600px) {
  #sweetModalParent .sweet-modal{
      width: 82% !important;
    }
}
  .sweet-modal {
    border-radius: 10px;
  }

  .sweet-modal.is-alert .sweet-content {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sweet-modal .sweet-content {
    padding: 0px 0px;
  }

  .sweet-modal .sweet-box-actions {
    top: 6px !important;
    right: 0px !important;
  }

  .sweet-modal .sweet-box-actions .sweet-action-close {
    display: block !important;
    color: #ffffff !important;
  }

  .sweet-modal .sweet-box-actions .sweet-action-close:hover {
    background: transparent !important;
    color: #fff !important;
  }
  .sweet-modal .sweet-box-actions .sweet-action-close {
    height: 35px !important;
  }

  .sweet-modal .dropdownSelect .dynamicDropdown{
    width: 89%;
    border: none;
    border-bottom: 1px solid #000000;
    cursor: pointer;
    margin-left: -3em;
  }

  .sweet-modal .dropdownSelect{
    -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  }

  .sweet-modal .dropdownSelect::after {
  content: "▼";
  transform: translateY(-50%);
  font-size: 16px;
  color: #000000;
  margin-left: -1em;
  pointer-events: none;
  cursor: pointer;
}
  .sweet-modal .uploadArtifactFields{
    outline:none;
    border:none;
    text-align: left;
    font: normal normal normal 16px/28px Ubuntu;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    border-bottom: 1px solid #000000;
    width:90%;    
  }
  .sweet-modal .uploadArtifactFields .uploadFileforArtifact{
   max-width: 150px !important;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap; 
  }
  .sweet-modal .descriptionField {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #BCBBBC;
  opacity: 1;
  width: 90%;
  height: 6em;
  padding: 0px 5px 0px 5px;
  resize: none;
  font: normal normal normal 16px/28px Ubuntu;
}

.sweet-modal .descriptionField:focus,
.sweet-modal .descriptionField:focus-visible,
.sweet-modal .descriptionField:focus-within{
  outline: none;
}

  .sweet-modal .artifactDescription{
    width:110%;
    color:gray
  }
  .sweet-modal .toolTipView {
    position: absolute;
    top: 75%;
    color:#2E4CA0;
    z-index: 30;
    font: normal normal bold 12px/12px Ubuntu;
  }
  .sweet-modal .dropdownSelect #frameworkId,
  .sweet-modal .dropdownSelect #artifactType {
    margin-left: -2em !important;
    width: 90% !important;
}

.sweet-modal.is-alert .sweet-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.sweet-modal .sweet-content {
    padding: 0px !important;
}

</style>
