<template>
  <link rel="stylesheet" type="text/css" href="src/style.css" />
  <link rel="stylesheet" type="text/css" href="../src/assets/fonts/ubuntu/ubuntu.css">
  <div id="app">
<!--     <HeaderComponent />
    <SideNav />
    <FooterComponent /> -->

    <router-view :key="$route.fullPath" />
  </div>
</template>
<style>

</style>
<script src="./app.js"></script> 
